import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';

class PageMenu extends React.PureComponent {
	render() {
		const {items, location} = this.props;
		const activePageId = location.pathname.split('/')[2];

		return items.map(item => {
			return (
				<NavLink
					key={item.id}
					id={item.id}
					activeClassName={item.id === activePageId ? 'selected' : ''}
					to={item.url}
				>
					{item.title}
				</NavLink>
			);
		});
	}
}

export default withRouter(PageMenu);
