import {useEffect, useState} from 'react';
import {Button, SelectBox} from 'devextreme-react';
import {notifyApp} from 'utils/notifyWrapper';
import {getBanxeData, getBanxeTariff} from 'services/async';

const GetBanxeData = ({closePopup, rowData}) => {
	const [response, setResponse] = useState(null);
	
	useEffect(() => {
		getBanxeData({
			merchantId: rowData.MerchantID,
		}).then((response) => {
			setResponse(response);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	return response ? (
		<div className={'create-legal-client-wrapper'}>
			<div className={'create-legal-client-select'}>
				<div className="dx-field">
					<div className="dx-field-label">Banxe ID</div>
					<div className="dx-field-value">{response.ClientExternalID}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Статус</div>
					<div className="dx-field-value">{response.ExternalStatus}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Причина смены статуса</div>
					<div className="dx-field-value">{response.ExternalStatusName}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Экспорт документов</div>
					<div className="dx-field-value">{response.IsExportDocumentComplete === 1 ? 'Yes' : 'No'}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Ошибка по клиенту</div>
					<div className="dx-field-value">{response.ErrorText}</div>
				</div>
			</div>
		</div>
	) : null;
}

export default GetBanxeData;