import React from 'react';
import {TextBox, Button} from "devextreme-react";
import Validator, {RequiredRule, CompareRule} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import {sha512} from "../../../utils/functions";
import {notifyApp} from "../../../utils/notifyWrapper";
import {apiRequest} from "../../../services/async";
import {vars} from '../../../utils/variables';
import './recover-login-fields.scss';

const {LOGIN} = vars;

export default class RecoverLoginFields extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newPassword: null,
		}
	}

	onSetPassword = async (args) => {
		if (!args.validationGroup.validate().isValid) {
			return;
		}

		const {login, password, successHandler} = this.props;
		const {newPassword} = this.state;
		const hashedNewPassword = await sha512(newPassword);

		if (hashedNewPassword) {
			const changeRequest = await apiRequest({
				operation: 'Login/ChangePassword',
				data: {
					Login: login,
					OldPassword: password,
					NewPassword: hashedNewPassword,
				}
			});

			if (changeRequest) {
				if (changeRequest.data.ResponseCode === '000') {
					successHandler();
				} else {
					notifyApp(changeRequest.data.ResponseText);
				}
			}
		} else {
			notifyApp('Client WebAPI error');
		}
	};

	passwordComparison = () => {
		return this.state.newPassword;
	};

	newPasswordChanged = ({value}) => {
		this.setState({
			newPassword: value,
		});
	};

	render() {
		const {newPassword} = this.state;
		return (
			<ValidationGroup>
				<div className={'dx-field'}>
					<TextBox
						mode={'password'}
						value={newPassword}
						onValueChanged={this.newPasswordChanged}
						placeholder={LOGIN.TEXT.NEW_PASSWORD_PLACEHOLDER}
						width={'100%'}
					>
						<Validator>
							<RequiredRule message={LOGIN.TEXT.NEW_PASSWORD_REQUIRED_ERROR}/>
						</Validator>
					</TextBox>
				</div>
				<div className={'dx-field'}>
					<span className={'dx-field-label full-width'}>
						{LOGIN.TEXT.AFTER_NEW_PASSWORD}
					</span>
				</div>
				<div className={'dx-field'}>
					<TextBox
						mode={'password'}
						placeholder={'Confirm the password'}
						width={'100%'}
					>
						<Validator>
							<RequiredRule message={'Confirm the password is required'}/>
							<CompareRule message="Password and Confirm Password do not match"
													 comparisonTarget={this.passwordComparison}/>
						</Validator>
					</TextBox>
				</div>
				<div className={'dx-field'}>
					<Button
						type={'default'}
						text={'Set password'}
						onClick={this.onSetPassword}
						width={'100%'}
					/>
				</div>
			</ValidationGroup>
		);
	}
}