import React, {useRef, useState} from 'react';
import {getDataSource, getDictionaryDataSource} from 'services/dataSource';
import {getPaymentLimits} from 'services/requestConsts';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import {vars} from 'utils/variables';

const {TRANSACTION, SPREAD_DICTIONARY} = vars;

const PaymentLimits = ({rights}) => {
	const actionRight = rights.includes('57');
	const [paymentLimitsDs] = useState(getDataSource(getPaymentLimits()));
	const limitsGridRef = useRef(null);
	const [isFilterShow, setIsFilterShow] = useState(false);
	const [paymentTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentType',
		}));
	const [paymentLimitTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentLimitType',
		}));
	const [currencyDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Currency',
		}),
	);
	const [paymentSystemTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentSystemType',
		}),
	);
	const [accountTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'AccountType',
		}),
	);
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (limitsGridRef.current) {
			limitsGridRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (limitsGridRef.current) {
			limitsGridRef.current.instance.addRow();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={limitsGridRef}
					dataSource={paymentLimitsDs}
					height={'100%'}
				>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={isFilterShow}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar={'onHover'}
					/>
					{actionRight ? (
						<Editing
							allowUpdating
							allowAdding
							allowDeleting
							mode={'row'}
						/>
					) : null}
					<Column
						allowEditing={false}
						dataField={'ID'}
						caption={'ID'}
					/>
					<Column
						dataField={'PaymentTypeID'}
						caption={'Тип операции'}
					>
						<Lookup
							dataSource={paymentTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'PaymentLimitTypeID'}
						caption={'Тип лимита'}
					>
						<Lookup
							dataSource={paymentLimitTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'PeriodTypeID'}
						caption={'Тип периода'}
					>
						<Lookup
							dataSource={[
								{ID: 1, Name: 'День'},
								{ID: 2, Name: 'Месяц'},
							]}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'PeriodLength'}
						caption={'Кол-во периода для анализа'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'CurrencyID'}
						caption={'Валюта'}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'Code'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'PaymentSystemTypeID'}
						caption={'Платежная система'}
					>
						<Lookup
							dataSource={paymentSystemTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'AccountTypeID'}
						caption={'Тип счета'}
					>
						<Lookup
							dataSource={accountTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'LimitValue'}
						caption={'Значение лимита'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'IsModerate'}
						caption={'Модерация'}
					>
						<Lookup
							dataSource={SPREAD_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
					</Toolbar>
				</DataGrid>
			</div>
		</div>
	);
}

export default PaymentLimits;