import React, {useState} from 'react';
import DataGrid, {Button, Column} from 'devextreme-react/data-grid';
import ShowComment from '../clientsPopupFields/show-comment';
import {Template} from 'devextreme-react/core/template';
import {Button as ToolbarButton} from 'devextreme-react/button';
import AdminPopup from 'components/popup/admin-popup';

const onToolbarPreparing = ({toolbarOptions: {items}}) => {
	items.unshift(
		{
			location: 'before',
			template: 'customToolbar'
		},
	);
};

const initPopupState = {
	title: 'Комментарий',
	visible: false,
	actionType: {
		subtype: null,
		remark: '',
	},
	rowData: null,
};

const CommentGrid = ({
											 merchantId,
											 merchantHistory = [],
											 updateComments = () => {
											 }
										 }) => {
	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<ToolbarButton
					disabled={!merchantId}
					text={'Добавить комментарий'}
					icon={'plus'}
					onClick={() => {
						setPopupState({
							title: 'Комментарий',
							visible: true,
							actionType: {
								subtype: 'add-comment',
								remark: '',
								updateComments: updateComments,
							},
							rowData: {MerchantID: merchantId},
						});
					}}
				/>
			</div>
		);
	};

	const [popupState, setPopupState] = useState(initPopupState);

	const closePopup = () => {
		setPopupState(initPopupState);
	}

	return (
		<div>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closePopup}
				visible={popupState.visible}
				title={popupState.title}
			>
				<ShowComment
					key={popupState.rowData}
					actionType={popupState.actionType}
					rowData={popupState.rowData}
					closePopup={closePopup}
				/>
			</AdminPopup>
			<DataGrid
				showBorders
				showColumnLines
				dataSource={merchantHistory}
				onToolbarPreparing={onToolbarPreparing}
			>
				<Column dataField={'InsDate'} caption={'Дата'} format={'dd.MM.yy, HH:mm:ss'} dataType={'datetime'}/>
				<Column dataField={'BoMerchantHistoryTypeName'} caption={'Параметр'}/>
				<Column dataField={'Remark'} caption={'Комментарий'}/>
				<Column type={'buttons'}>
					<Button
						text={'Подробнее'}
						onClick={({row}) => {
							setPopupState({
								title: 'Комментарий',
								visible: true,
								actionType: {
									subtype: 'show-comment',
									remark: row.data.Remark,
								},
								rowData: row.data,
							});
						}}
					/>
				</Column>
				<Template name={'customToolbar'} render={toolbarItemRender}/>
			</DataGrid>
		</div>
	);
}

export default CommentGrid;