import {useEffect, useState} from 'react';
import {Button, SelectBox} from 'devextreme-react';
import {notifyApp} from 'utils/notifyWrapper';
import {createLegalClient, getBanxeTariff} from 'services/async';

const CreateLegalClient = ({closePopup, rowData}) => {
	const [dictionary, setDictionary] = useState(null);
	const [selected, setSelected] = useState(null);
	
	const getElements = async () => {
		return await getBanxeTariff();
	}
	
	useEffect(() => {
		getElements().then((result) => {
			setDictionary(result);
		}).catch((error) => {
			notifyApp(error)
		});
	}, []);
	
	const setNewValue = ({value}) => {
		setSelected(value);
	}
	
	const handleRequest = async () => {
		try {
			await createLegalClient({
				merchantId: rowData.MerchantID,
				banxeTariffId: selected,
			});
		} catch (error) {
			notifyApp(error);
		} finally {
			closePopup();
		}
	}
	
	return (
		<div className={'create-legal-client-wrapper'}>
			<div className={'create-legal-client-select'}>
				<SelectBox
					dataSource={dictionary}
					displayExpr={'Tariff'}
					valueExpr={'ID'}
					value={selected}
					onValueChanged={setNewValue}
				/>
			</div>
			<div className={'action-buttons'}>
				<Button
					disabled={!selected}
					className={'action-button'}
					onClick={handleRequest}
					text={'Подтвердить'}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={'Отмена'}
				/>
			</div>
		</div>
	);
}

export default CreateLegalClient;