import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {getDataSource, getDictionaryDataSource, getLookupDataSource} from 'services/dataSource';
import {getServices} from 'services/requestConsts';
import {RequiredRule} from 'devextreme-react/validator';
import {vars} from 'utils/variables';

const {IS_ACTIVE_DICTIONARY} = vars;
const deletedCellValue = 'Deleted';

const Services = ({rights}) => {
	const serviceRef = useRef(null);
	const gridActionRight = rights.includes('62');
	const [serviceDs, setServiceDs] = useState([]);
	const [isShowFilterRow, setIsShowFilterRow] = useState(false);
	const [serviceCategoryNameDictionary] = useState(
		getDictionaryDataSource({objectName: 'ServiceCategory'}),
	);
	const [currencyDictionary] = useState(
		getLookupDataSource('Currency', 'LimitCurrencyCode', 'Code', 'ID'),
	);
	
	const [paymentSystemTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentSystemType',
		}),
	);
	
	useEffect(() => {
		const ds = getDataSource(getServices());
		setServiceDs(ds);
	}, []);
	
	const handleEditorPreparing = (e) => {
		if (e.dataField === 'IsActive' && e.parentType === 'dataRow') {
			if (e.row.data && e.row.data.IsDeleted === 1) {
				e.editorOptions.value = deletedCellValue;
				e.editorOptions.disabled = true;
			}
		}
	};
	
	const customRender = (e) => {
		let cellValue = e.displayValue;
		
		if (e.data.IsDeleted === 1) {
			cellValue = deletedCellValue;
		}
		
		return <span>{cellValue}</span>
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={serviceRef}
					id={'grid-acc-statement'}
					dataSource={serviceDs}
					onEditorPreparing={handleEditorPreparing}
					repaintChangesOnly={true}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
								<span className={'recent-operation-text'}>Фильтры</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button
								icon={'filter'}
								onClick={() => {
									setIsShowFilterRow(!isShowFilterRow);
								}}/>
						</Item>
						<Item location={'before'}>
							<Button
								icon={'clearsquare'}
								onClick={() => {
									if (serviceRef.current) {
										serviceRef.current.instance.clearFilter();
									}
								}}/>
						</Item>
					</Toolbar>
					<FilterRow visible={isShowFilterRow}/>
					{gridActionRight ? (
						<Editing
							allowUpdating={true}
							mode={'row'}
							refreshMode={'repaint'}
						/>
					) : null}
					<Scrolling
						mode={'infinite'}
						showScrollbar={'onHover'}
					/>
					<Paging enabled defaultPageSize={50}/>
					<RemoteOperations
						paging
					/>
					<Column dataField={'ID'} allowEditing={false} width={75}/>
					<Column dataField={'Name'} caption={'Наименование'} allowEditing={false} width={200}/>
					
					<Column dataField={'ServiceCategoryID'} caption={'Тип'} width={250}>
						<Lookup
							dataSource={serviceCategoryNameDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					
					<Column dataField={'PaymentSystemTypeID'} caption={'Платежная система'} allowEditing={false} width={130}>
						<Lookup
							dataSource={paymentSystemTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
					
					<Column dataField={'ExternalServiceID'} caption={'ID сервиса'} allowEditing={false} width={85}/>
					
					<Column
						dataField={'LimitCurrencyID'}
						caption={'Валюта'}
						allowEditing={false}
						width={65}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'LookupName'}
							valueExpr={'LookupId'}
						/>
					</Column>
					
					{/*<Column dataField={'CurrencyCode'} caption={'Валюта'} allowEditing={false}/>*/}
					
					<Column dataField={'LimitMinAmount'} caption={'Мин. лимит'} allowEditing={false} width={100}/>
					<Column dataField={'LimitMaxAmount'} caption={'Макс. лимит'} allowEditing={false} width={100}/>
					<Column dataField={'ExampleAccount'} caption={'Пример счета'} allowEditing={false}/>
					
					<Column dataField={'IsActive'} caption={'Статус'} cellRender={customRender}>
						<Lookup
							dataSource={IS_ACTIVE_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					
					<Column
						dataField={'InsDate'}
						caption={'Дата добавления'}
						allowEditing={false}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column
						dataField={'LastUpdate'}
						caption={'Дата обновления'}
						allowEditing={false}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column dataField={'Description'} caption={'Описание'}/>
				</DataGrid>
			</div>
		</div>
	);
}

export default Services;