import React from 'react';
import {apiRequest} from 'services/async';
import {Button, SelectBox, TextArea, TextBox} from 'devextreme-react';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import Validator, {CustomRule, RequiredRule} from 'devextreme-react/validator';
import Loader from 'components/loader/loader';
import {tryParseDoubleValue} from 'utils/functions';
import './income-payout.scss';

const I = {
	PAYMENT_DESCRIPTION: 'Назначение платежа',
	PAYMENT_SELECT_BOX: 'Счет/остаток/валюта',
	PAYMENT_TEXT_BOX: 'Сумма операции',
	ACCEPT_BUTTON: 'Подтвердить',
	DECLINE_BUTTON: 'Отменить платеж',
	REPLAY_BUTTON: 'Поставить платеж в очередь на проверку статуса',
	CANCEL_BUTTON: 'Отмена',
};

const operations = {
	income: {
		operation: 'Payment/BoIncome',
		success: 'Income success',
	},
	payout: {
		operation: 'Payment/BoPayout',
		success: 'Payout success',
	},
};

export class IncomePayout extends React.Component {
	constructor(props) {
		super(props);
		const accountId = props.rowData.AccountID ? props.rowData.AccountID : null;
		this.state = {
			description: '',
			amount: null,
			accounts: [],
			accountId: accountId,
			isShowLoader: false,
		};
	}

	componentDidMount() {
		this.getAccounts().catch((e) => {
			console.warn(e);
		});
	}

	getAccounts = async () => {
		const {APP_CODES} = vars;
		const {rowData: {MerchantID}} = this.props;

		const request = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: MerchantID
				},
				Page: -1,
			}
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			this.setState({
				accounts: request.data.Response.Account
			});
		} else {
			notifyApp(request.data.ResponseText);

		}
	};

	requestButton = async (args) => {
		if (!args.validationGroup.validate().isValid) {
			return;
		}

		this.setState({
			isShowLoader: true,
		});

		const {APP_CODES, NOTIFY} = vars;
		const {accountId, amount, description} = this.state;
		const {actionType, closePopup, gridRef} = this.props;
		const request = await apiRequest({
			operation: operations[actionType].operation,
			data: {
				Params: {
					AccountID: accountId,
					Amount: amount,
					Description: description,
				}
			},
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			if (gridRef) {
				gridRef.current.instance.refresh();
			}
			closePopup();
			notifyApp(operations[actionType].success, NOTIFY.SUCCESS);
		} else {
			notifyApp(request.data.ResponseText);
		}

		this.setState({
			isShowLoader: false,
		});
	};

	changeDescriptionValue = ({value}) => {
		this.setState({
			description: value,
		});
	};

	changeAmountValue = (value) => {
		this.setState({
			amount: value,
		});
	};

	validateSum({value}) {
		return parseFloat(tryParseDoubleValue(value)) > 0;
	}

	render() {
		const {accounts, accountId, isShowLoader, amount} = this.state;
		return (
			<div className={'action-wrapper'}>
				<Loader isShowLoader={isShowLoader}/>
				<div className={'clients-popup-field'}>
					<SelectBox
						items={accounts}
						valueExpr="ID"
						placeholder={I.PAYMENT_SELECT_BOX}
						displayExpr={(item) => {
							return item && `${item.Code} ${item.Balance === null ? '' : item.Balance} ${item.CurrencyCode}`;
						}}
						value={accountId}
						onValueChanged={({value}) => {
							this.setState({
								accountId: value,
							})
						}}
					>
						<Validator>
							<RequiredRule/>
						</Validator>
					</SelectBox>
				</div>
				<div className={'clients-popup-field'}>
					<TextBox
						placeholder={I.PAYMENT_TEXT_BOX}
						onInput={({event}) => {
							this.changeAmountValue(tryParseDoubleValue(event.target.value));
						}}
						value={amount}
					>
						<Validator>
							<CustomRule
								validationCallback={this.validateSum}
								message={'Sum must be greater then zero'}
							/>
						</Validator>
					</TextBox>
				</div>
				<div className={'clients-popup-field'}>
					<TextArea
						placeholder={I.PAYMENT_DESCRIPTION}
						onValueChanged={this.changeDescriptionValue}
					/>
				</div>
				<div className={'action-buttons'}>
					<Button
						className={'action-button'}
						onClick={this.requestButton}
						text={I.ACCEPT_BUTTON}
					/>
					<Button
						className={'action-button'}
						onClick={this.props.closePopup}
						text={I.CANCEL_BUTTON}
					/>
				</div>
			</div>
		);
	}
}
