import React, {useState, useEffect} from 'react';
import Form, {SimpleItem, GroupItem} from 'devextreme-react/form';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {APP_CODES} = vars;

const IbanTransactionDetails = ({ID}) => {
	const [rowData, setRowData] = useState({
		ID: null,
		Name: null,
		UserName: null,
	});

	useEffect(() => {
		const getTransactionDetails = async () => {
			const request = await apiRequest({
				operation: 'WallettoPayment/GetDetail',
				data: {
					Params: {
						ID: ID
					}
				}
			});

			const {Response, ResponseCode, ResponseText} = request.data;

			if (ResponseCode === APP_CODES.SUCCESS) {
				setRowData(Response);
			} else {
				notifyApp(ResponseText);
			}
		};

		if (ID) {
			getTransactionDetails().catch((e) => {
				console.error(e);
			});
		}
	}, [ID]);

	return (
		<div className={'merchant-details-wrapper'}>
			<div className={'merchant-details'}>
				<Form
					id="form"
					formData={rowData}
					readOnly={true}
					showColonAfterLabel={true}
					labelLocation={'left'}
				>
					<SimpleItem
						dataField={'InsDate'}
						label={{text: 'Дата'}}
						editorType="dxDateBox"
						editorOptions={{type: 'datetime'}}
					/>
					<SimpleItem
						dataField={'LastUpdate'}
						label={{text: 'Обновлено'}}
						editorType="dxDateBox"
						editorOptions={{type: 'datetime'}}
					/>
					<SimpleItem dataField={'AccountCode'} label={{text: 'IBAN/Счёт'}}/>
					<GroupItem caption="Клиент">
						<SimpleItem dataField={'MerchantName'} label={{text: 'Клиент'}}/>
						<SimpleItem dataField={'AccountID'} label={{text: 'Счет'}}/>
						<SimpleItem dataField={'ExternalClientID'} label={{text: 'Внешний ID Клиента'}}/>
						<SimpleItem dataField={'ExternalAccountID'} label={{text: 'Внешний ID счета'}}/>
					</GroupItem>
					<GroupItem caption="Операция">
						<SimpleItem dataField={'TransactionType'} label={{text: 'Тип транзакции'}}/>
						<SimpleItem dataField={'TransactionExternalID'} label={{text: 'Транзакция'}}/>
						<SimpleItem dataField={'DocumentExternalID'} label={{text: 'Документ'}}/>
						<SimpleItem dataField={'DocumentStatus'} label={{text: 'Статус'}}/>
						<SimpleItem dataField={'TransactionDescription'} label={{text: 'Описание транзакции'}}/>
					</GroupItem>
				</Form>
			</div>
		</div>
	);
};

export default IbanTransactionDetails;
