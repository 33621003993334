import React from 'react';
import {baseUrl, getDocuments} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {APP_CODES, NOTIFY} = vars;

const DownloadDocument = ({merchantId, documentType, setDocuments}) => {
	return (
		<div className={'download-input-wrapper'}>
			<div>
				<input multiple type={'file'} id={'fileInput'}/>
			</div>
			<button
				className={'download-button'}
				onClick={() => {
					const fileElement = document.getElementById('fileInput');
					const files = fileElement.files;
					const formData = new FormData();
					const merchantDocumentFiles = [];

					if (files.length === 0) {
						notifyApp('Файл не выбран');
					} else {
						formData.append("json", '');

						Array.from(files).forEach((file, index) => {
							merchantDocumentFiles.push({
								ID: index,
								FileName: file.name,
							});
							formData.append(index.toString(), file);
						});

						formData.set('json', JSON.stringify({
							Params: {
								MerchantDocumentFiles: merchantDocumentFiles,
								MerchantID: merchantId,
								DocumentTypeID: documentType.selectedType,
							}
						}));

						const request = new XMLHttpRequest();
						request.open('POST', `${baseUrl}/MerchantDocument/UploadFile`, true);
						request.onload = () => {
							if (request.status === 200) {
								const {ResponseCode, ResponseText} = JSON.parse(request.response);

								if (ResponseCode === APP_CODES.SUCCESS) {
									getDocuments(merchantId).then((response) => {
										setDocuments(response);
									}).catch((errorText) => {
										notifyApp(errorText);
									}).finally(() => {
										notifyApp('Файл загружен', NOTIFY.SUCCESS);
									});
								} else {
									notifyApp(ResponseText);
								}
							} else {
								const {ResponseText} = JSON.parse(request.response);
								notifyApp(ResponseText);
							}
						};
						request.send(formData);
					}
				}}
			>
				Загрузить
			</button>
		</div>
	);
}

export default DownloadDocument;