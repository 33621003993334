import React, {useEffect, useState} from 'react';
import {vars} from 'utils/variables';
import {menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';
import ClientDetailButtons from './client-detail/client-detail-buttons';
import ClientDetailAccordion from './client-detail/client-detail-accordion';
import AdminPopup from 'components/popup/admin-popup';
import {useHistory} from 'react-router-dom';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';

import './client-detail/client-details.scss';

const {APP_CODES: {SUCCESS}, CLIENT_PATH, PATH_KEY: {CLIENTS}} = vars;

const initialInnerFields = {component: null, type: '', params: {}};

const getPopupTitle = (actionType, {ID = null, Name = null, UserName = null}) => {
	if (typeof actionType !== 'string') {
		return 'Комментарий';
	}
	const titles = {
		restriction: `Параметры мерчанта ${ID} ${Name ? Name : ''} ${UserName ? UserName : ''}`,
		income: 'Техническое пополнение',
		payout: 'Техническое списание',
		banxe: 'Создать',
		getBanxeData: '',
	};
	return titles[actionType] ? titles[actionType] : 'Результат';
};

const ClientDetail = (props) => {
	const {rights} = props;
	const history = useHistory();
	const [innerPopupFields, setInnerPopupFields] = useState(initialInnerFields);
	const [formState, setFormState] = useState({
		clientId: -1,
		rowData: null,
	});
	
	const maxPopupWidth = innerPopupFields.type === 'change-parameters' ? 1200 : 700;
	
	const getDetailInfo = async (clientId) => {
		const request = await apiRequest({
				operation: 'Merchant/GetDetail',
				data: {
					Params: {
						MerchantID: clientId,
					},
				},
			},
		);
		
		if (request.data.ResponseCode === SUCCESS) {
			return Promise.resolve(request.data.Response);
		} else {
			return Promise.reject(request.data.ResponseText);
		}
	}
	
	useEffect(() => {
		const {location: {state}} = props;
		
		if (state && !formState.rowData) {
			getDetailInfo(state.clientId).then((info) => {
				setFormState({
					...state,
					rowData: info,
				});
			}).catch((error) => {
				notifyApp(error);
			});
		} else {
			history.replace(CLIENT_PATH);
		}
	}, []);
	
	const closeInnerPopup = () => {
		setInnerPopupFields(initialInnerFields);
	}
	
	const InnerComponent = innerPopupFields.component;
	let offset = 0;
	if (innerPopupFields.component) {
		if (['SetClient', 'SetClient', 'GetClient'].includes(innerPopupFields.type)) {
			offset = -150;
		}
	}
	
	return (
		<div className={'clients-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CLIENTS]}
				/>
			</div>
			{formState.rowData && (
				<div className={'client-detail-top'}>
					<div className={'profile-detail-title'}>
						<h1>Детальная информация о клиенте ID {formState.rowData.MerchantID}, {formState.rowData.Name}</h1>
					</div>
					<ClientDetailButtons
						rights={rights}
						rowData={formState.rowData}
						setInnerPopupFields={setInnerPopupFields}
					/>
				</div>
			)}
			<div
				className={'active-component-wrapper'}
			>
				<div className={'client-detail'}>
					{formState.rowData && (
						<div className={'client-detail-wrapper'}>
							<div className={'client-detail-inner'}>
								<AdminPopup
									container={'.app'}
									logo={false}
									minHeight={450}
									handleClose={() => {
										closeInnerPopup();
										getDetailInfo(formState.clientId).then((info) => {
											setFormState({
												...formState,
												rowData: info,
											});
										}).catch((error) => {
											notifyApp(error);
										})
									}}
									visible={!!innerPopupFields.component}
									title={getPopupTitle(innerPopupFields.type, formState.rowData)}
									maxWidth={maxPopupWidth}
									offsetY={offset}
								>
									{innerPopupFields.component && (
										<InnerComponent
											rowData={formState.rowData}
											actionType={innerPopupFields.type}
											callback={innerPopupFields.callback}
											closePopup={closeInnerPopup}
											updateMerchantsList={formState.updateMerchantsList}
											updateSelectedMerchant={formState.updateSelectedMerchant}
											params={innerPopupFields.params}
											rights={props.rights}
										/>
									)}
								</AdminPopup>
								<ClientDetailAccordion rights={rights} setInnerPopupFields={setInnerPopupFields} {...formState}/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ClientDetail;