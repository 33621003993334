import React, {useState} from 'react';
import Form, {CustomRule, SimpleItem} from 'devextreme-react/form';
import {emailRequiredCallback, requiredCallback} from 'utils/functions';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {getDictionaryDataSource} from 'services/dataSource';
import {vars} from 'utils/variables';

const {COMPACT_BOX_WIDTH, VALIDATION: {REQUIRED_TEXT, ONLY_LATIN_TEXT, ISO_ALPHA3_TEXT}} = vars;

const ClientCreateForm = ({
														mainFormRef,
														selectedVerificationType,
														setSelectedVerificationType,
														selectedUserType,
														setSelectedUserType,
														isFormSaved,
													}) => {
	const [verificationSystems] = useState(
		getDictionaryDataSource({
			objectName: 'VmVerificationSystem',
		}));
	const [userTypes] = useState(
		getDictionaryDataSource({
			objectName: 'UserType',
		}));
	const [hosts] = useState(
		getDictionaryDataSource({
			objectName: 'Host',
		}));

	const isRequiredField = selectedVerificationType === 2 && selectedUserType === 1;
	const isReadOnly = isFormSaved ? isFormSaved : selectedVerificationType === 1;

	const modifyLabel = (label) => {
		return isRequiredField ? label + '*' : label;
	}

	const onlyLatinCallback = ({value}) => {
		if (!isRequiredField && value === '') {
			return true;
		}

		return /^[^а-яё]+$/.test(value.toLowerCase().trim());
	}

	const ISOAlpha3Callback = ({value}) => {
		if (!isRequiredField && value === '') {
			return true;
		}

		return /^[A-Z]{3}$/.test(value);
	}

	const translitFormValue = (value, fieldName) => {
		const formInstance = mainFormRef.current.instance;
		let conf = {preset: 'uk'};

		if (value.indexOf('ы') > -1 || value.indexOf('э') > -1) {
			conf = {preset: 'ru'};
		}

		formInstance.updateData(fieldName, CyrillicToTranslit(conf).transform(value));
	};

	return (
		<Form
			id={'client-create-form'}
			showColonAfterLabel={true}
			labelLocation={'left'}
			ref={mainFormRef}
			validationGroup={'common'}
		>
			<SimpleItem
				dataField={'VmVerificationSystemID'}
				label={{text: 'Тип верификации*'}}
				editorType={'dxSelectBox'}
				editorOptions={{
					dataSource: verificationSystems,
					displayExpr: 'Name',
					valueExpr: 'ID',
					value: selectedVerificationType,
					onValueChanged: ({value}) => {
						setSelectedVerificationType(value);
					},
					readOnly: isFormSaved,
					width: COMPACT_BOX_WIDTH,
				}}
			>
				<CustomRule
					message={REQUIRED_TEXT}
					validationCallback={requiredCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'UserTypeID'}
				label={{text: 'Тип клиента*'}}
				editorType={'dxSelectBox'}
				editorOptions={{
					dataSource: userTypes,
					displayExpr: 'Name',
					valueExpr: 'ID',
					value: selectedUserType,
					onValueChanged: ({value}) => {
						setSelectedUserType(value);
					},
					readOnly: isFormSaved,
					width: COMPACT_BOX_WIDTH,
				}}
			>
				<CustomRule
					message={REQUIRED_TEXT}
					validationCallback={requiredCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'HostID'}
				label={{text: 'Источник*'}}
				editorType={'dxSelectBox'}
				editorOptions={{
					dataSource: hosts,
					displayExpr: 'Name',
					valueExpr: 'ID',
					readOnly: isFormSaved,
					width: COMPACT_BOX_WIDTH,
				}}
			>
				<CustomRule
					message={REQUIRED_TEXT}
					validationCallback={requiredCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'Email'}
				label={{text: 'Email*'}}
				editorOptions={{
					readOnly: isFormSaved,
				}}
			>
				<CustomRule
					message={REQUIRED_TEXT}
					validationCallback={emailRequiredCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'Phone'}
				label={{text: 'Телефон*'}}
				editorOptions={{
					readOnly: isFormSaved,
				}}
			>
				<CustomRule
					message={REQUIRED_TEXT}
					validationCallback={requiredCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'ReferrerCode'}
				label={{text: 'Код пригласившего пользователя'}}
				editorOptions={{
					readOnly: isFormSaved,
				}}
			/>

			<SimpleItem
				dataField={'FirstName'}
				label={{text: modifyLabel('Имя')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ONLY_LATIN_TEXT}
					validationCallback={onlyLatinCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'FirstNameOrig'}
				label={{text: 'Имя (оригинал)'}}
				editorOptions={{
					readOnly: isReadOnly,
					onValueChanged: ({value}) => {
						translitFormValue(value, 'FirstName');
					}
				}}
			/>

			<SimpleItem
				dataField={'LastName'}
				label={{text: modifyLabel('Фамилия')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ONLY_LATIN_TEXT}
					validationCallback={onlyLatinCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'LastNameOrig'}
				label={{text: 'Фамилия (оригинал)'}}
				editorOptions={{
					readOnly: isReadOnly,
					onValueChanged: ({value}) => {
						translitFormValue(value, 'LastName');
					}
				}}
			/>

			<SimpleItem
				dataField={'DateOfBirth'}
				label={{text: modifyLabel('Дата рождения клиента')}}
				editorType={'dxDateBox'}
				editorOptions={{
					readOnly: isReadOnly,
					type: 'date',
					displayFormat: 'yyyy-MM-dd',
					useMaskBehavior: true,
					width: COMPACT_BOX_WIDTH,
				}}
			>
				{
					isRequiredField ? (
						<CustomRule
							message={REQUIRED_TEXT}
							validationCallback={requiredCallback}
						/>
					) : null
				}
			</SimpleItem>

			<SimpleItem
				dataField={'CountryOfBirth'}
				label={{text: modifyLabel('Страна рождения')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ISO_ALPHA3_TEXT}
					validationCallback={ISOAlpha3Callback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'PersonalCode'}
				label={{text: modifyLabel('Персональный код (TIN)')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				{
					isRequiredField ? (
						<CustomRule
							message={REQUIRED_TEXT}
							validationCallback={requiredCallback}
						/>
					) : null
				}
			</SimpleItem>

			<SimpleItem
				dataField={'Country'}
				label={{text: modifyLabel('Страна проживания')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ISO_ALPHA3_TEXT}
					validationCallback={ISOAlpha3Callback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'Town'}
				label={{text: modifyLabel('Город')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ONLY_LATIN_TEXT}
					validationCallback={onlyLatinCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'TownOrig'}
				label={{text: 'Город (введенный)'}}
				editorOptions={{
					readOnly: isReadOnly,
					onValueChanged: ({value}) => {
						translitFormValue(value, 'Town');
					}
				}}
			/>

			<SimpleItem
				dataField={'Street'}
				label={{text: modifyLabel('Адрес')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				<CustomRule
					message={ONLY_LATIN_TEXT}
					validationCallback={onlyLatinCallback}
				/>
			</SimpleItem>

			<SimpleItem
				dataField={'StreetOrig'}
				label={{text: 'Адрес (введенный)'}}
				editorOptions={{
					readOnly: isReadOnly,
					onValueChanged: ({value}) => {
						translitFormValue(value, 'Street');
					}
				}}
			/>

			<SimpleItem
				dataField={'PostCode'}
				label={{text: modifyLabel('Почтовый индекс')}}
				editorOptions={{
					readOnly: isReadOnly,
				}}
			>
				{isRequiredField ? (
					<CustomRule
						message={REQUIRED_TEXT}
						validationCallback={requiredCallback}
					/>
				) : null}
			</SimpleItem>
		</Form>
	);
}

export default ClientCreateForm;