import React from 'react';
import {getMenuComponent, menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';
import {useHistory, useLocation} from 'react-router-dom';
import {getFromLocalStorage} from 'utils/functions';
import {vars} from 'utils/variables';

const {PATH_KEY: {FINANCE}} = vars;

const Finance = (props) => {
	const {rights} = props;
	
	const history = useHistory();
	const location = useLocation();
	const activePageId = location.pathname.split('/')[2];
	let ActiveComponent = null;
	
	if (activePageId) {
		ActiveComponent = getMenuComponent(FINANCE, activePageId);
	} else {
		history.push(menuItems[FINANCE][0].url);
	}
	
	return (
		<div className={'checkout-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[FINANCE]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{ActiveComponent ? (
					<ActiveComponent rights={rights}/>
				) : null}
			</div>
		</div>
	);
}

export default Finance;