import React, {useState} from 'react';
import {Button, TextArea, Validator} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}} = vars;

const I = {
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
	FIELD_REQUIRED: 'Поле обязательное',
};

const WallettoCardRemark = ({rowData, closePopup}) => {
	const {ruleName, ruleValue, cardId} = rowData;
	const [remark, setRemark] = useState('');
	const [operationDone, setOperationDone] = useState(true);
	
	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<TextArea
					disabled={!operationDone}
					value={remark}
					placeholder={'Примечание'}
					onValueChanged={({value}) => {
						setRemark(value);
					}}
				>
					<Validator>
						<RequiredRule message={I.FIELD_REQUIRED}/>
					</Validator>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					disabled={!operationDone}
					className={'action-button'}
					onClick={async (args) => {
						if (!args.validationGroup.validate().isValid) {
							return;
						}
						setOperationDone(false);
						const setRulesRequest = await apiRequest({
							operation: 'WallettoCard/SetCardRule',
							data: {
								Params: {
									WallettoCardID: cardId,
									Remark: remark,
									[ruleName]: +ruleValue,
								}
							}
						});
						
						if (setRulesRequest.data.ResponseCode === SUCCESS) {
							closePopup(true);
						} else {
							notifyApp(setRulesRequest.data.ResponseText);
						}
						setOperationDone(true);
					}}
					text={I.ACCEPT_BUTTON}
				/>
			</div>
		</div>
	);
};

export default WallettoCardRemark;
