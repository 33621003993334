import DataSource from 'devextreme/data/data_source';
import axios from 'axios';
import {getFilter} from "./dataSourceFilterUtils";
import {prepareRequestOptions} from "./async";

async function load(loadOptions, objectName, keyField, displayFld, additionalCols = [], additionalFilters = {}) {
	const {filter, take, skip, searchValue} = loadOptions;
	let fltr = getFilter(filter);

	if (fltr && additionalFilters) {
		fltr = {...fltr, ...additionalFilters}
	} else {
		fltr = {...additionalFilters}
	}

	// if (searchValue != null && searchValue !== '') {
	//в селектбоксе если убрать значение поиска не отображаються все доступные элементы для выбора
	if (searchValue != null && typeof displayFld === 'string') {
		fltr[displayFld] = {'like': searchValue};
	}

	let requestObj = {
		Filters: fltr || {},
		Page: (skip + take) / take || -1,
		RowsPerPage: take,
		Columns: [keyField, ...additionalCols],
	};

	if (typeof displayFld === 'string') {
		requestObj.Columns.push(displayFld);
	}

	if (!Object.keys(requestObj.Filters).length) {
		delete requestObj.Filters;
	}

	const response = await axios(prepareRequestOptions({operation: `${objectName}/List`, data: requestObj}));

	if (response.data.ResponseCode === '000')
		return response.data.Response[objectName];

	return [];
}


export function getDicDataSource({dictObj, keyField, dictDisplayFld, additionalCols, additionalFilters}) {
	if (!(dictObj && keyField && dictDisplayFld)) {
		throw new Error('Dictionary field initialization error');
	}

	return new DataSource({
		pageSize: 20,
		paginate: true,
		load: loadOptions => load(
			loadOptions, dictObj, keyField, dictDisplayFld, additionalCols, additionalFilters
		),
		insert: (values) => {
			return Promise.resolve(values);
		},
		byKey: function (key) {
			if (Array.isArray(key) && !key.length) {
				return Promise.resolve();
			}

			return load({
				take: 1,
				skip: 0,
				filter: [keyField, "=", key]
			},  dictObj, keyField, dictDisplayFld, additionalCols, additionalFilters)
		},
	});
}