import React, {useEffect, useState} from 'react';
import {Button, TextBox} from 'devextreme-react';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import dayjs from 'dayjs';
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import {vars} from 'utils/variables';
import {useHistory} from 'react-router-dom';
import WallettoCardSettings from './walletto-card-settings';
import 'pages/clients/clients-page/clientsPopupFields/merchant-details.scss';

const {
	MERCHANT,
	CLIENT_PATH,
	APP_CODES,
} = vars;

const I = {
	INCOME_BUTTON: 'Пополнение',
	PAYOUT_BUTTON: 'Списание',
	RESTRICTION_BUTTON: 'Блокировки',
	TRANSACTIONS: 'Транзакции',
	CLIENT: 'Клиент',
	BALANCE: 'Показать остаток',
	PARAMETERS: 'Параметры',
};
const WallettoCardDetails = ({rights, gridButtonHandler, rowData}) => {
	const history = useHistory();
	const [merchantDetailData, setMerchantDetailData] = useState(null);
	
	useEffect(() => {
		getMerchantDetail().then((response) => {
			setMerchantDetailData(response);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	const getMerchantDetail = async () => {
		
		const request = await apiRequest({
			operation: 'Merchant/GetDetail',
			data: {
				Params: {
					MerchantID: rowData.MerchantID,
				},
			},
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}
	
	const getCreationDate = (props) => {
		const {editorOptions: {value}} = props;
		const val = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
		
		return (
			<TextBox
				value={val}
				readOnly={true}
			/>
		);
	};
	
	const checkButtonRight = (right) => {
		return rights.includes(right);
	};
	
	/*
	const {rowData, gridButtonHandler, history} = this.props;
		const {merchantDetailData, balance, userRights} = this.state;
	* */
	
	return (
		<div className={'merchant-details-wrapper'}>
			<div className={'merchant-details-buttons'}>
				<Button
					type={'normal'}
					text={I.RESTRICTION_BUTTON}
					onClick={() => {
						gridButtonHandler({
							...merchantDetailData, WidgetType: 'restriction',
						});
					}}
				/>
				<Button
					type={'default'}
					text={I.CLIENT}
					onClick={() => {
						const value = {
							accountFilterID: rowData.MerchantID,
						};
						
						window.sessionStorage.setItem('filter', JSON.stringify(value));
						
						history.push(CLIENT_PATH);
					}}
				/>
				<Button
					type={'normal'}
					text={I.TRANSACTIONS}
					onClick={() => {
						window.sessionStorage.setItem('filter', JSON.stringify({
							accountFilterID: rowData.AccountID,
						}));
						history.push(MERCHANT.TRANSACTIONS_PATH);
					}}
				/>
			</div>
			<WallettoCardSettings
				rowData={rowData}
				gridButtonHandler={gridButtonHandler}
			/>
			<div>
				<Form
					id='account-details-form'
					formData={rowData}
					readOnly={true}
					showColonAfterLabel={true}
					labelLocation={'left'}
				>
					<GroupItem caption='Счёт'>
						<SimpleItem render={getCreationDate} dataField={'InsDate'} label={{text: 'Дата создания'}}/>
						<SimpleItem dataField={'AccountID'} label={{text: 'ID счета'}}/>
						<SimpleItem dataField={'Iban'} label={{text: 'IBAN'}}/>
						<SimpleItem dataField={'ExternalID'} label={{text: 'Внешний ID карты'}}/>
						<SimpleItem dataField={'CardNumber'} label={{text: 'Карта'}}/>
						<SimpleItem dataField={'WallettoCardStatusName'} label={{text: 'Статус'}}/>
					</GroupItem>
					<GroupItem caption='Клиент'>
						<SimpleItem dataField={'MerchantID'} label={{text: 'ID'}}/>
						<SimpleItem dataField={'MerchantName'} label={{text: 'Клиент'}}/>
						<SimpleItem dataField={'ClientExternalID'} label={{text: 'Внешний ID Клиента'}}/>
					</GroupItem>
				</Form>
			</div>
		</div>
	);
}

export default WallettoCardDetails;