import React from 'react';
import DataGrid, {Column} from 'devextreme-react/data-grid';
import {baseUrl} from 'services/async';

const DocumentsGrid = ({documents}) => {
	console.group('docs');
	console.log(documents);
	console.groupEnd();
	
	return (
		<DataGrid
			columnMinWidth={500}
			showColumnHeaders={false}
			dataSource={documents}
			showBorders
			showColumnLines
		>
			<Column dataField={'DocumentType'}/>
			<Column cellRender={({data}) => {
				if (data.MerchantDocumentFile && Array.isArray(data.MerchantDocumentFile)) {
					return (
						<div className={'document-wrapper'}>
							{data.MerchantDocumentFile.map((file, fileKey) => {
								return (
									<div key={fileKey} className={'file-wrapper'}>
										<div>File:</div>
										<div>
											<a
												target={'_blank'}
												rel={'noopener noreferrer'}
												href={`${baseUrl}/MerchantDocument/DownloadFile?Params.MerchantDocumentFileID=${file.ID}`}
											>
												{file.ImageID}
											</a>
										</div>
									</div>
								);
							})}
						</div>
					);
				} else {
					return (<div></div>);
				}
			}}/>
		</DataGrid>
	);
}

export default DocumentsGrid;