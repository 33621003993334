import React, {useEffect, useState, useCallback} from 'react';
import {Button, SelectBox, TextArea, TextBox} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

import './income-payout.scss';

const I = {
	CARD_TYPE: 'Тип карты',
	ACCOUNT_TYPE: 'Тип счета',
	ACCOUNT: 'Счет',
	VERIFICATION_COMMENT: 'Комментарий',
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
};

const {
	APP_CODES: {SUCCESS},
	SHARPAY_IBAN_ID,
	ACTIVE_ACCOUNT_STATUS_ID,
	WALLETTO_CARD_TYPE_VIRTUAL,
} = vars;

const CreateNewCard = ({merchantID, accounts, matrix, closePopup, updateCardList}) => {
	const [remark, setRemark] = useState('');
	const [staticPassword, setStaticPassword] = useState('');
	const [wallettoCardTypes] = useState(() => {
		return matrix.filter((item) => item.WallettoCardTypes);
	});
	const [merchantAccounts, setMerchantAccounts] = useState(() => {
		return accounts.filter((account) => {
			return account.PaymentSystemTypeID === SHARPAY_IBAN_ID &&
				account.AccountStatusID === ACTIVE_ACCOUNT_STATUS_ID &&
				account.WallettoParams;
		});
	});
	
	const generatePassword = useCallback(() => {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < 8; i++) {
			result += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		setStaticPassword(result);
	}, []);
	
	useEffect(() => {
		if(!staticPassword) {
			setStaticPassword(generatePassword);
		}
	}, [staticPassword]);
	
	const [selectedWallettoCardType, setSelectedWallettoCardType] = useState(null);
	const [cardTypes, setCardTypes] = useState();
	const [selectedCardType, setSelectedCardType] = useState(null);
	const [selectedAccountId, setSelectedAccountId] = useState(null);
	
	useEffect(() => {
		if (selectedWallettoCardType) {
			setCardTypes(selectedWallettoCardType.WallettoCardTypes);
			setMerchantAccounts(merchantAccounts.filter((account) => {
				return account.WallettoParams.WallettoAccountTypeID === selectedWallettoCardType.PaymentSystemSubTypeID;
			}));
		}
	}, [selectedWallettoCardType]);
	
	const createNewCard = async ({component}) => {
		const result = validationEngine.validateGroup('create-new-group');
		
		if (result.isValid) {
			component.option('disabled', true);
			
			try {
				const params = {
					MerchantID: merchantID,
					AccountID: selectedAccountId,
					WallettoCardTypeID: selectedCardType.ID,
					PayGateTokenID: selectedWallettoCardType.PayGateTokenID,
					Remark: remark,
				};
				
				if (staticPassword) {
					params.StaticPassword = staticPassword;
				}
				
				const request = await apiRequest({
					operation: 'WallettoCard/Create',
					data: {
						Params: params,
					},
				});
				
				const {data: {ResponseCode, ResponseText}} = request;
				
				if (ResponseCode === SUCCESS) {
					updateCardList();
					closePopup();
				} else {
					notifyApp(ResponseText);
				}
				
			} finally {
				component.option('disabled', false);
			}
		}
	};
	
	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={wallettoCardTypes}
					valueExpr={'ID'}
					placeholder={I.ACCOUNT_TYPE}
					displayExpr={(item) => {
						if (!item) return '';
						return `${item.PaymentSystemSubTypeName} / ${item.CurrencyCode} / ${item.PayGateTokenDisplayName}`;
					}}
					value={selectedWallettoCardType && selectedWallettoCardType.ID}
					onValueChanged={({value}) => {
						setSelectedWallettoCardType(wallettoCardTypes.find((item) => item.ID === value));
					}}
				>
					<Validator validationGroup={'create-new-group'}>
						<RequiredRule message={'Account type required'}/>
					</Validator>
				</SelectBox>
			</div>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={cardTypes}
					valueExpr={'ID'}
					placeholder={I.CARD_TYPE}
					displayExpr={'Name'}
					value={selectedCardType && selectedCardType.ID}
					onValueChanged={({value}) => {
						setSelectedCardType(cardTypes.find((item) => item.ID === value));
					}}
				>
					<Validator validationGroup={'create-new-group'}>
						<RequiredRule message={'Card type required'}/>
					</Validator>
				</SelectBox>
			</div>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={merchantAccounts}
					valueExpr={'ID'}
					placeholder={I.ACCOUNT}
					displayExpr={'BoAccountNumber'}
					value={selectedAccountId}
					onValueChanged={({value}) => {
						setSelectedAccountId(value);
					}}
				/>
			</div>
			<div className={'clients-popup-field'}>
				{selectedCardType && selectedCardType.CardKindID === WALLETTO_CARD_TYPE_VIRTUAL ? (
					<TextBox
						placeholder={'StaticPassword (3D-Secure)'}
						text={staticPassword}
						onValueChanged={({value}) => {
							setStaticPassword(value);
						}}
					/>
				) : null}
			</div>
			<div className={'clients-popup-field'}>
				<TextArea
					value={remark}
					onValueChanged={({value}) => {
						setRemark(value);
					}}
				>
					<Validator validationGroup={'create-new-group'}>
						<RequiredRule message={'Remark required'}/>
					</Validator>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={createNewCard}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
};

export default CreateNewCard;
