import React from 'react';
import {getMenuComponent, menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';
import {useLocation, useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';

const {PATH_KEY: {SETTINGS}} = vars;

const filterMenuItems = (items, rights) => {
	return items.filter((item) => {
		return item.accessRight ? rights.includes(item.accessRight) : true;
	});
}

const Settings = (props) => {
	const {rights} = props;

	const history = useHistory();
	const location = useLocation();
	const activePageId = location.pathname.split('/')[2];
	let ActiveComponent = null;

	const visibleItems = filterMenuItems(menuItems[SETTINGS], rights);

	if (activePageId) {
		ActiveComponent = getMenuComponent(SETTINGS, activePageId);
	} else {
		if (visibleItems.length > 0) {
			history.push(visibleItems[0].url);
		}
	}

	return (
		<div className={'checkout-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={visibleItems}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{ActiveComponent ? (
					<ActiveComponent rights={rights}/>
				) : null}
			</div>
		</div>
	);
}

export default Settings;