import React, {useRef, useState} from 'react';
import {vars} from 'utils/variables';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource, getDictionaryDataSource, getLookupDataSource} from 'services/dataSource';
import {getPaymentScheme} from 'services/requestConsts';
import {saveEditDataToApi} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import axios from "axios";
import Button from 'devextreme-react/button';
import {FileUploader} from 'devextreme-react';

const {APP_CODES, TRANSACTION, PAYMENT_TYPE_NAME_DICTIONARY, NOTIFY} = vars;

const paramsDataKeys = [
	'PaymentSystemTypeID', 'PayGateTokenID', 'ForeignPaymentSystemTypeID', 'ForeignPayGateTokenID', 'PaymentTypeID',
	'CurrencyID', 'ForeignCurrencyID', 'MinAmount', 'MaxAmount', 'VmVerificationLevels', 'IsActive', 'DeviceTypeID'
];

const PaymentScheme = ({rights}) => {
	const exportRight = rights.includes('37');
	const importRight = rights.includes('38');
	const schemeGridRef = useRef(null);
	const [paymentSchemeDs] = useState(getDataSource(getPaymentScheme()));
	
	const [payGateTokenDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PayGateToken',
			withEmptyValue: true,
			names: ['ID', 'DisplayName'],
		}));
	
	const [currencyDictionary] = useState(
		getLookupDataSource('Currency', 'CurrencyCode', 'Code', 'ID'),
	);
	
	const [somePaymentSystemTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentSystemType',
			filter: {
				ID: [0, 11, 12, 21],
			},
		}));
	
	const [deviceTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'DeviceType',
			withEmptyValue: true,
		}));
	
	const [paymentSystemTypeDictionary] = useState(
		getDictionaryDataSource({
				objectName: 'PaymentSystemType',
			},
		));
	
	const paymentSystemCell = ({data: {PaymentSystemTypeID, PaymentSystemTypeName}}) => {
		return `${PaymentSystemTypeID} | ${PaymentSystemTypeName}`;
	}
	const counterpartyCell = ({data: {ForeignPaymentSystemTypeID, ForeignPaymentSystemTypeName}}) => {
		return `${ForeignPaymentSystemTypeID} | ${ForeignPaymentSystemTypeName}`;
	}
	
	const [isFilterShow, setIsFilterShow] = useState(false);
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const onRowUpdating = async (event) => {
		const paramsData = {};
		const {newData, oldData} = event;
		const data = {...oldData, ...newData};
		
		paramsDataKeys.forEach((key) => {
			paramsData[key] = data[key];
		});
		
		paramsData.ID = data.ID;
		
		event.cancel = new Promise((resolve, reject) => {
			saveEditDataToApi('PaymentSystemTypeMatrix/Mod', paramsData).then(() => {
				resolve(false);
			}).catch((error) => {
				reject(error);
			});
		});
	}
	
	const onRowInserting = (event) => {
		const paramsData = {};
		const {data} = event;
		
		if (Object.keys(data).length > 0) {
			paramsDataKeys.forEach((key) => {
				paramsData[key] = data[key];
			});
			
			event.cancel = new Promise((resolve, reject) => {
				saveEditDataToApi('PaymentSystemTypeMatrix/Ins', paramsData).then(() => {
					resolve(false);
				}).catch((error) => {
					reject(error);
				});
			});
		} else {
			event.cancel = true;
		}
	}
	
	const clearFilters = () => {
		if (schemeGridRef.current) {
			schemeGridRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (schemeGridRef.current) {
			schemeGridRef.current.instance.addRow();
		}
	}
	
	const exportButton = () => {
		const link = document.createElement('a');
		link.href = '/pfs3bo/wapi/PaymentSystemTypeMatrix/Export/PaymentSystemTypeMatrix.csv';
		link.setAttribute(
			'download',
			'PaymentSystemTypeMatrix.csv',
		);
		
		document.body.appendChild(link);
		
		link.click();
		link.parentNode.removeChild(link);
	}
	
	const uploadFile = async (file) => {
		const data = new FormData();
		data.append('file', file.value);
		
		const url = '/pfs3bo/wapi/PaymentSystemTypeMatrix/Import';
		
		const request = await axios.post(url, data);
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			if (schemeGridRef.current) {
				schemeGridRef.current.instance.refresh();
			}
		} else {
			notifyApp(request.data.ResponseText);
		}
	}
	
	const onFileUploaded = ({component}) => {
		component.reset();
		notifyApp('Import successful', NOTIFY.SUCCESS);
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={schemeGridRef}
					dataSource={paymentSchemeDs}
					onRowUpdating={onRowUpdating}
					onRowInserting={onRowInserting}
					height={'100%'}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<FileUploader
								visible={importRight}
								accept={'.csv'}
								allowedFileExtensions={['.csv']}
								labelText={''}
								readyToUploadMessage={''}
								selectButtonText={'Импорт'}
								uploadFile={uploadFile}
								onUploaded={onFileUploaded}
								elementAttr={{class: 'round-toolbar-button'}}
							/>
						</Item>
						<Item location={'after'}>
							<Button
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								visible={exportRight}
								text={'Экспорт'}
								onClick={exportButton}
							/>
						</Item>
						<Item>
							<Button
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={isFilterShow}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar={'onHover'}
					/>
					{rights.includes('49') ? (
						<Editing
							allowUpdating
							allowAdding
							allowDeleting
							mode={'row'}
						/>
					) : null}
					<Column
						dataField={'PaymentSystemTypeID'}
						caption={'Счет.Тип'}
						cellRender={paymentSystemCell}
					>
						<Lookup
							dataSource={somePaymentSystemTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'PayGateTokenID'}
						caption={'Счет.Партнер'}
					>
						<Lookup
							dataSource={payGateTokenDictionary}
							displayExpr={'DisplayName'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'CurrencyID'}
						caption={'Счет.Валюта'}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'LookupName'}
							valueExpr={'LookupId'}
						/>
					</Column>
					<Column
						dataField={'PaymentTypeID'}
						caption={'Тип операции'}
					>
						<Lookup
							dataSource={PAYMENT_TYPE_NAME_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'ForeignCurrencyID'}
						caption={'Валюта'}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'LookupName'}
							valueExpr={'LookupId'}
						/>
					</Column>
					<Column
						dataField={'ForeignPaymentSystemTypeID'}
						caption={'Контрагент'}
						cellRender={counterpartyCell}
					>
						<Lookup
							dataSource={paymentSystemTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'ForeignPayGateTokenID'}
						caption={'Контрагент.Партнер'}
					>
						<Lookup
							dataSource={payGateTokenDictionary}
							displayExpr={'DisplayName'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'MinAmount'}
						caption={'Мин.Сумма'}
					/>
					<Column
						dataField={'MaxAmount'}
						caption={'Макс.Сумма'}
					/>
					<Column
						dataField={'VmVerificationLevels'}
						caption={'Ур.Верификации'}
					/>
					<Column
						dataField={'IsActive'}
						caption={'Статус'}
					>
						<Lookup
							dataSource={[
								{ID: 0, Name: 'Disabled'},
								{ID: 1, Name: 'Active'},
							]}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column
						dataField={'DeviceTypeID'}
						caption={'Тип устройства'}
					>
						<Lookup
							dataSource={deviceTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default PaymentScheme;