import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Toolbar,
} from 'devextreme-react/data-grid';
import {vars} from 'utils/variables';
import {getDataSource, getDictionaryDataSource} from 'services/dataSource';
import {getCurrencyBlockchain} from 'services/requestConsts';
import {valueToArray} from 'utils/functions';
import {RequiredRule} from 'devextreme-react/validator';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import Button from 'devextreme-react/button';

const {TRANSACTION, SPREAD_DICTIONARY} = vars;

const CurrencyBlockchain = (props) => {
	const {rights} = props;
	const gridActionRight = rights.includes('56');
	const [isFilterShow, setIsFilterShow] = useState(false);
	const currencyGridRef = useRef(null);
	const [currencyBlockchainDs] = useState(getDataSource(getCurrencyBlockchain()));
	
	const [currencyDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Currency',
		}),
	);
	
	const [blockchainDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Blockchain',
		}),
	);
	
	const filterOptions = {
		CurrencyCode: {
			type: 'dictionary',
			filterOperations: [],
			calculateFilterExpression: makeCalculateFilterExpression('CurrencyID'),
			options: {
				object: 'Currency',
				displayName: 'Code',
				keyName: 'ID',
				value: undefined,
				onValueChanged: () => {
				},
			},
		},
		BlockchainCode: {
			type: 'dictionary',
			filterOperations: [],
			calculateFilterExpression: makeCalculateFilterExpression('BlockchainID'),
			options: {
				object: 'Blockchain',
				displayName: 'Code',
				keyName: 'ID',
				value: undefined,
				onValueChanged: () => {
				},
			},
		},
	}
	
	const onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = filterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	};
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (currencyGridRef.current) {
			currencyGridRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (currencyGridRef.current) {
			currencyGridRef.current.instance.addRow();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={currencyGridRef}
					dataSource={currencyBlockchainDs}
					height={'100%'}
					onEditorPreparing={onEditorPreparing}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
					</Toolbar>
					<Paging
						enabled={false}
					/>
					<RemoteOperations
						filtering
					/>
					{gridActionRight ? (
						<Editing
							allowUpdating
							allowAdding
							allowDeleting
						/>
					) : null}
					<FilterRow visible={isFilterShow}/>
					<Column
						dataField={'CurrencyID'}
						caption={'Валюта'}
						filterOperations={filterOptions['CurrencyCode'].filterOperations}
						calculateFilterExpression={filterOptions['CurrencyCode'].calculateFilterExpression}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'Code'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'BlockchainID'}
						caption={'Блокчейн'}
						filterOperations={filterOptions['BlockchainCode'].filterOperations}
						calculateFilterExpression={filterOptions['BlockchainCode'].calculateFilterExpression}
					>
						<Lookup
							dataSource={blockchainDictionary}
							displayExpr={'Code'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'AddressValidationRegexp'}
						caption={'Формат адреса'}
					/>
					<Column
						dataField={'MinDeposit'}
						caption={'Мин.Депозит'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'MinWithdraw'}
						caption={'Мин.Вывод'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'WithdrawFeeAmountConst'}
						caption={'Комиссия за вывод'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'IsHasMemo'}
						caption={'Атрибуты (TAG, MEMO …)'}
					>
						<Lookup
							dataSource={SPREAD_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default CurrencyBlockchain;