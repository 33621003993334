import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
	Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getSettlementList} from 'services/requestConsts';
import {
	formatDate,
	getAppliedFilters,
	getFromSessionStorage,
	isVisibleSettlementBtn,
	toggleContextMenu,
} from 'utils/functions';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';
import {menuItems} from 'app-routes';
import {Button, TextArea} from 'devextreme-react';
import AdminPopup from 'components/popup/admin-popup';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {ButtonGroup} from 'devextreme-react/button-group';

const {
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {SETTLEMENT_PAGE}},
	PRECISION,
	APP_CODES: {SUCCESS},
	NOTIFY
} = vars;

const initialConfirm = {remark: '', operation: null, title: '', row: null};

const contextInnerButtons = [
	{hint: 'Показать платежи', action: 'show', moveTo: true},
	{hint: 'Выплатить', action: 'payout', right: '31'},
	{hint: 'Закрыть без выплаты', action: 'close', right: '32'},
];

const CustomButtonGroupCell = ({row, isVisibleButton, handleGridColumnButton, history, rights}) => {
	
	const handleClick = async (args) => {
		const {element, event, itemData} = args;
		
		if (itemData.isContextMenuBtn) {
			const ctxMenu = document.getElementById('ctx-menu');
			const rect = element.getBoundingClientRect();
			
			contextInnerButtons.forEach((btn) => {
				const btnElement = document.getElementById(btn.action);
				
				if (isVisibleButton(rights, btn.right, row)) {
					btnElement.classList.remove('invisible');
				} else {
					btnElement.classList.add('invisible');
				}
				
				btnElement.onclick = () => {
					if (btn.moveTo) {
						window.sessionStorage.setItem('filter', JSON.stringify({
							settlementID: row.data.ID,
						}));
						toggleContextMenu();
						history.push(menuItems.checkout[1].url);
					} else {
						let title;
						let operation;
						
						if (btn.action === 'payout') {
							operation = 'Settlement/Pay';
							title = `Выплатить сетлмент: ID ${row.data.ID}, сумма ${row.data.Amount}, валюта ${row.data.CurrencyCode}`;
						} else if (btn.action === 'close') {
							title = `Отметить как выплаченный: ID ${row.data.ID}, сумма ${row.data.Amount}, валюта ${row.data.CurrencyCode}`;
							operation = 'Settlement/CloseWithoutPay';
						}
						
						handleGridColumnButton({remark: '', operation: operation, title: title, row: row});
						toggleContextMenu();
					}
				}
			});
			
			ctxMenu.style.top = (rect.bottom - 86) + 'px';
			ctxMenu.style.left = (rect.right - ctxMenu.offsetWidth) + 'px';
			
			toggleContextMenu();
		}
	};
	
	const actionButtons = [
		{
			hint: 'Action',
			icon: 'mdi mdi-dots-vertical',
			visible: true,
			isContextMenuBtn: true,
		},
	];
	
	return (
		<div className={'button-group-wrapper'}>
			<ButtonGroup
				items={actionButtons}
				keyExpr='action'
				stylingMode='outlined'
				onItemClick={handleClick}
			/>
		</div>
	);
};

const Settlement = (props) => {
	const {rights} = props;
	const history = useHistory();
	const gridRef = useRef(null);
	const [settlementSource, setSettlementSource] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(() => {
		return !!getFromSessionStorage('filter', 'merchantFilterID');
	});
	
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	useEffect(() => {
		const dataSource = getDataSource(getSettlementList());
		setSettlementSource(dataSource);
		window.sessionStorage.removeItem('filter');
	}, []);
	
	const [confirmData, setConfirmData] = useState(initialConfirm);
	const handleClose = () => {
		const dataSource = getDataSource(getSettlementList());
		
		setConfirmData(initialConfirm);
		setSettlementSource(dataSource);
	};
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter()
		}
	}
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}
	
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<AdminPopup
				key={confirmData.title}
				title={confirmData.title}
				logo={false}
				handleClose={handleClose}
				visible={!!confirmData.operation}
				maxWidth={800}
			>
				<div className={'add-comment-wrapper'}>
					{confirmData.row ? (
						<div className='field-info'>
							<div>{`Клиент: ${confirmData.row.data.MerchantName}`}</div>
							<div>{`Счёт: ${confirmData.row.data.AccountCode}`}</div>
							<div>{`Профиль: ${confirmData.row.data.MerchantApiProfileName}`}</div>
							<div>{`Период: ${formatDate(confirmData.row.data.BeginPeriodDate, true)} - ${formatDate(confirmData.row.data.EndPeriodDate, true)}`}</div>
						</div>
					) : null}
					
					<div className='field-label'>{'Примечание'}</div>
					<TextArea
						height={120}
						text={confirmData.remark}
						onValueChanged={({value}) => {
							setConfirmData({
								...confirmData,
								remark: value,
							});
						}}
					/>
					<Button id={'add-comment-btn'} text={'Ok'} onClick={async () => {
						const request = await apiRequest({
							operation: confirmData.operation,
							data: {
								Params: {
									SettlementID: confirmData.row.data.ID,
									Remark: confirmData.remark,
								},
							},
						});
						
						if (request.data.ResponseCode === SUCCESS) {
							notifyApp('Success', NOTIFY.SUCCESS);
							handleClose();
						} else {
							notifyApp(request.data.ResponseText);
						}
					}}/>
				</div>
			</AdminPopup>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					id={'grid-acc-statement'}
					height={'100%'}
					dataSource={settlementSource}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button icon={'xlsxfile'} onClick={exportGrid}/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type='localStorage'
						storageKey={SETTLEMENT_PAGE}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column dataField={'ID'} caption={'ID'}/>
					<Column dataField={'MerchantID'} caption={'Мерчант'}/>
					<Column dataField={'MerchantApiProfileID'} caption={'ID профиля'}/>
					<Column
						dataField={'BeginPeriodDate'}
						caption={'Начало периода'}
						dataType={'datetime'}
						format={'dd.MM.yy'}
					/>
					<Column
						dataField={'EndPeriodDate'}
						caption={'Конец периода'}
						dataType={'datetime'}
						format={'dd.MM.yy'}
					/>
					<Column
						dataField={'SettlementDate'}
						caption={'Дата выплаты'}
						dataType={'datetime'}
						format={'dd.MM.yy'}
					/>
					<Column dataField={'StatusName'} caption={'Статус'}/>
					<Column dataField={'ErrorText'} caption={'Ошибка'}/>
					<Column dataField={'Amount'} caption={'Сумма'}>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column dataField={'CurrencySymbol'} caption={'Валюта (символ)'}/>
					<Column dataField={'PaymentID'} caption={'Payment ID'}/>
					<Column
						type={'buttons'}
						allowReordering={false}
						alignment={'center'}
						cellRender={(row) => {
							return <CustomButtonGroupCell
								row={row}
								isVisibleButton={isVisibleSettlementBtn}
								handleGridColumnButton={setConfirmData}
								history={history}
								rights={rights}
							/>
						}}
					/>
				</DataGrid>
			</div>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'SettlementExport'}
				getGridParams={getSettlementList}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
			/>
			<div id={'ctx-menu'}>
				<div className={'ctx-menu'}>
					{contextInnerButtons.map((ctxMenuItem) => {
						return (
							<div key={ctxMenuItem.action} id={ctxMenuItem.action} className={'column-operation-button'}>
								{ctxMenuItem.icon ? (<i className={ctxMenuItem.icon}/>) : null}
								<span>
										{ctxMenuItem.hint}
									</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Settlement;


