import {
	Accounts,
	WallettoCards,
	Blockchain,
	Cards,
	Checkout,
	ClientCreate,
	ClientDetails,
	Clients,
	ClientsPage,
	Commissions,
	CryptoAddress,
	Currency,
	CurrencyBlockchain,
	Details,
	ExchangeRates,
	Finance,
	FXCurrencyExchange,
	Iban,
	Market,
	Marketing,
	Registration,
	MerchantTransactions,
	NotFound,
	Orders,
	PaymentLimits,
	PaymentScheme,
	PaymentSystems,
	Profiles,
	ReferrerDetail,
	Referrers,
	Rolling,
	Services,
	Settings,
	Settlement,
	SystemSettings,
	Transactions,
} from './pages';

export const getAllowedRoutes = (rights) => {
	const allowedRoutes = [
		{id: 'clients', path: '/clients/:operation?', exact: false, component: Clients, title: 'Клиенты', url: '/clients'},
		{
			id: 'client-detail',
			path: '/clients/clients/detail',
			exact: true,
			component: ClientDetails,
			title: 'Детально о клиенте',
			hidden: true,
		},
		{
			id: 'profile-details',
			path: '/checkout/profiles/detail',
			exact: true,
			component: Details,
			title: 'Профили',
			hidden: true,
		},
		{
			id: 'referrer-details',
			path: '/clients/referrers/detail',
			exact: true,
			component: ReferrerDetail,
			title: 'Реферралы ',
			hidden: true,
		},
		{
			id: 'clients-create',
			path: '/clients/client/create',
			exact: true,
			component: ClientCreate,
			title: 'Создать ',
			hidden: true,
		},
		{
			id: 'checkout',
			path: '/checkout/:operation?',
			exact: false,
			component: Checkout,
			title: 'Мерчанты',
			url: '/checkout',
		},
	];
	
	const financeRoutes = getAllowedSecondLevelRoute('finance', rights);
	const marketingRoutes = getAllowedSecondLevelRoute('marketing', rights);
	const settingsRoutes = getAllowedSecondLevelRoute('settings', rights);
	
	if (financeRoutes.length > 0) {
		allowedRoutes.push(
			{
				id: 'finance',
				path: '/finance/:operation?',
				exact: false,
				component: Finance,
				title: 'Финансы',
				url: '/finance',
			},
		);
		
		if (financeRoutes.find((item) => item.id === 'exchange-rates')) {
			allowedRoutes.push(
				{
					id: 'currency',
					path: '/finance/exchange-rates/currency',
					exact: true,
					component: Currency,
					title: 'Валюты',
					hidden: true,
				},
				{
					id: 'currency-blockchain',
					path: '/finance/exchange-rates/currency-blockchain',
					exact: true,
					component: CurrencyBlockchain,
					title: 'Валюты/Блокчейны',
					hidden: true,
				},
				{
					id: 'blockchain',
					path: '/finance/exchange-rates/blockchain',
					exact: true,
					component: Blockchain,
					title: 'Блокчейны',
					hidden: true,
				},
			);
		}
	}
	
	if (marketingRoutes.length > 0) {
		allowedRoutes.push(
			{
				id: 'marketing',
				path: '/marketing/:operation?',
				exact: false,
				component: Marketing,
				title: 'Маркетинг',
				url: '/marketing',
			},
		);
	}
	
	if (settingsRoutes.length > 0) {
		allowedRoutes.push(
			{
				id: 'settings',
				path: '/settings/:operation?',
				exact: false,
				component: Settings,
				title: 'Настройки',
				url: '/settings',
			},
		);
	}
	
	return allowedRoutes;
}

const getAllowedSecondLevelRoute = (routeId, rights) => {
	const allowed = [];
	
	menuItems[routeId].forEach((secondLevelRoute) => {
		if (rights.includes(secondLevelRoute.accessRight)) {
			allowed.push(secondLevelRoute);
		}
	});
	
	return allowed;
};

export const menuItems = {
	'clients': [
		{id: 'clients', url: '/clients/clients', component: ClientsPage, title: 'Клиенты'},
		{id: 'accounts', url: '/clients/accounts', component: Accounts, title: 'Счета'},
		{id: 'walletto-cards', url: '/clients/walletto-cards', component: WallettoCards, title: 'Карты'},
		{id: 'transactions', url: '/clients/transactions', component: Transactions, title: 'Транзакции'},
		{id: 'fx', url: '/clients/fx', component: FXCurrencyExchange, title: 'FX (Валютообмен)'},
		{id: 'market', url: '/clients/market', component: Market, title: 'Крипто.Маркет'},
		{id: 'crypto-address', url: '/clients/crypto-address', component: CryptoAddress, title: 'Крипто.Адреса'},
		{id: 'cards', url: '/clients/cards', component: Cards, title: 'Карты.Операции'},
		{id: 'iban', url: '/clients/iban', component: Iban, title: 'IBAN.Операции'},
		{id: 'referrers', url: '/clients/referrers', component: Referrers, title: 'Реферреры'},
	],
	'checkout': [
		{id: 'orders', url: '/checkout/orders', component: Orders, title: 'Заказы'},
		{id: 'transactions', url: '/checkout/transactions', component: MerchantTransactions, title: 'Транзакции'},
		{id: 'settlement', url: '/checkout/settlement', component: Settlement, title: 'Settlement'},
		{id: 'rolling', url: '/checkout/rolling', component: Rolling, title: 'Rolling'},
		{id: 'profiles', url: '/checkout/profiles', component: Profiles, title: 'Профили'},
	],
	'finance': [
		{
			id: 'exchange-rates',
			url: '/finance/exchange-rates',
			component: ExchangeRates,
			title: 'Курсы валют',
			accessRight: '52',
		},
		{
			id: 'commissions',
			url: '/finance/commissions',
			component: Commissions,
			title: 'Комиссии',
			accessRight: '65',
		},
	],
	'marketing': [
		{
			id: 'registration',
			url: '/marketing/registration',
			component: Registration,
			title: 'Регистрация',
			accessRight: '59',
		},
	],
	'settings': [
		{
			id: 'payment-systems',
			url: '/settings/payment-systems',
			component: PaymentSystems,
			title: 'Платежные системы',
			accessRight: '50',
		},
		{
			id: 'payment-limits',
			url: '/settings/payment-limits',
			component: PaymentLimits,
			title: 'Лимиты платежей',
			accessRight: '53',
		},
		{
			id: 'payment-scheme',
			url: '/settings/payment-scheme',
			component: PaymentScheme,
			title: 'Платежные схемы',
			accessRight: '48',
		},
		{
			id: 'system-settings',
			url: '/settings/system-settings',
			component: SystemSettings,
			title: 'Системные настройки',
			accessRight: '43',
		},
		{
			id: 'services',
			url: '/settings/services',
			component: Services,
			title: 'Сервисы',
			accessRight: '61',
		},
	],
};

export const getMenuComponent = (key, itemId) => {
	const activeRoute = menuItems[key].find(item => item.id === itemId);
	return activeRoute ? activeRoute.component : NotFound;
};

export const getDefaultPageRoute = (key) => {
	return menuItems[key][0]['url'];
};
