import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Toolbar,
} from 'devextreme-react/data-grid';
import {vars} from 'utils/variables';
import {getDataSource} from 'services/dataSource';
import {getCurrency} from 'services/requestConsts';
import {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';

const {TRANSACTION, CURRENCY_TYPE_DICTIONARY, RESTRICTION_DICTIONARY} = vars;

const Currency = (props) => {
	const {rights} = props;
	const gridActionRight = rights.includes('54');
	const [isFilterShow, setIsFilterShow] = useState(false);
	const currencyGridRef = useRef(null);
	const [currencyDs] = useState(getDataSource(getCurrency()));
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (currencyGridRef.current) {
			currencyGridRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (currencyGridRef.current) {
			currencyGridRef.current.instance.addRow();
		}
	}
	
	/*const onInitNewRow = () => {
		if (currencyGridRef.current) {
			console.log(currencyGridRef.current.instance.columnOption('ID', 'allowEditing'));
			currencyGridRef.current.instance.columnOption('ID', 'allowEditing', true);
			console.log(currencyGridRef.current.instance.columnOption('ID', 'allowEditing'));
			
		}
	}*/
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={currencyGridRef}
					dataSource={currencyDs}
					height={'100%'}
					//onInitNewRow={onInitNewRow}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								visible={gridActionRight}
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
					</Toolbar>
					<Paging
						enabled={false}
					/>
					<RemoteOperations
						filtering
					/>
					{gridActionRight ? (
						<Editing
							allowUpdating
						/>
					) : null}
					<FilterRow visible={isFilterShow}/>
					<Column
						allowEditing={true}
						dataField={'ID'}
						caption={'ID'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'Name'}
						caption={'Название'}
					/>
					<Column
						dataField={'Code'}
						caption={'Код'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'Symbol'}
						caption={'Символ'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'Precision'}
						caption={'Точность, симв.'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'ViewPrecision'}
						caption={'Отображение, симв.'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'CurrencyTypeID'}
						caption={'Тип валюты'}
					>
						<Lookup
							dataSource={CURRENCY_TYPE_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'ImageUrl'}
						caption={'Иконка'}
					/>
					<Column
						dataField={'IsAllowCreateAccount'}
						caption={'Создание счета'}
					>
						<Lookup
							dataSource={RESTRICTION_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'IsAllowSystemPayment'}
						caption={'Системные платежи'}
					>
						<Lookup
							dataSource={RESTRICTION_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default Currency;