import React, {useState} from 'react';
import {Button, SelectBox, TextArea} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {getDictionaryDataSource} from 'services/dataSource';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

import './income-payout.scss';

const I = {
	CATEGORY: 'Категория',
	VERIFICATION_COMMENT: 'Комментарий',
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
};

const {APP_CODES: {SUCCESS}} = vars;

const ChangeCategory = ({closePopup, rowData, updateMerchant}) => {
	const [categoryList] = useState(getDictionaryDataSource({
		objectName: 'MerchantCategory',
	}));
	
	const [selectedCategoryID, setSelectedCategoryID] = useState(null);
	
	const changeCategoryClick = async ({component}) => {
		const result = validationEngine.validateGroup('category-group');
		
		if (result.isValid) {
			component.option('disabled', true);
			
			try {
				const request = await apiRequest({
					operation: 'Merchant/ChangeMerchantCategory',
					data: {
						Params: {
							MerchantID: rowData.MerchantID,
							MerchantCategoryID: selectedCategoryID,
						}
					},
				});
				
				const {data: {ResponseCode, ResponseText}} = request;
				
				if (ResponseCode === SUCCESS) {
					updateMerchant();
					closePopup();
				} else {
					notifyApp(ResponseText);
				}
			} finally {
				component.option('disabled', false);
			}
		}
	};
	
	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					dataSource={categoryList}
					placeholder={I.CATEGORY}
					valueExpr={'ID'}
					displayExpr={'Name'}
					value={selectedCategoryID}
					onValueChanged={({value}) => {
						setSelectedCategoryID(value);
					}}
				>
					<Validator validationGroup={'category-group'}>
						<RequiredRule message={'Status required'}/>
					</Validator>
				</SelectBox>
			</div>
			
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={changeCategoryClick}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
};

export default ChangeCategory;
