import React, {useRef, useState} from 'react';
import DataGrid, {Column, Editing, FilterRow, Item, Scrolling, Toolbar} from 'devextreme-react/data-grid';
import {RequiredRule, StringLengthRule} from 'devextreme-react/validator';
import {getDataSource} from 'services/dataSource';
import {getRegistrations, registrationCaptions} from 'services/requestConsts';
import Button from 'devextreme-react/button';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {vars} from 'utils/variables';

const {TRANSACTION} = vars;

const Registration = ({rights}) => {
	const gridActionRight = rights.includes('60');
	const [registrationsDs] = useState(getDataSource(getRegistrations()));
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [isFilterShow, setIsFilterShow] = useState(false);
	const gridRef = useRef(null);
	
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	const onEditorPreparing = (e) => {
		if (e.parentType === 'dataRow' && e.dataField !== 'Urlid') {
			return;
		}
		
		if (e.parentType !== 'filterRow') {
			if (!e.row.isNewRow)
				e.editorOptions.disabled = true;
		}
	}
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (gridRef.current) {
			gridRef.current.instance.addRow();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					height={'100%'}
					ref={gridRef}
					dataSource={registrationsDs}
					onEditorPreparing={onEditorPreparing}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
						<Item location={'after'}>
							<Button
								icon={'xlsxfile'}
								onClick={() => {
									setIsShowExportDatePopup(!isShowExportDatePopup);
								}}/>
						</Item>
					</Toolbar>
					{gridActionRight ? (
						<Editing
							allowUpdating
							allowAdding
							allowDeleting
						/>
					) : null}
					
					<Scrolling
						mode={'infinite'}
						showScrollbar={'onHover'}
					/>
					<FilterRow visible={isFilterShow}/>
					
					<Column
						dataField={'Name'}
						caption={registrationCaptions.Name}
						minWidth={175}
					>
						<RequiredRule/>
					</Column>
					<Column dataField={'Urlid'} caption={registrationCaptions.Urlid} minWidth={350}>
						<StringLengthRule max={50} message={'Max value length is 50'}/>
					</Column>
					<Column
						dataField={'InsDate'}
						caption={registrationCaptions.InsDate}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						allowEditing={false}
						width={120}
					/>
					<Column
						allowEditing={false}
						dataField={'RegistrationCount'}
						caption={registrationCaptions.RegistrationCount}
						width={140}
					/>
					<Column
						allowEditing={false}
						dataField={'RegistrationFirstDate'}
						caption={registrationCaptions.RegistrationFirstDate}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={170}
					/>
					<Column
						allowEditing={false}
						dataField={'RegistrationLastDate'}
						caption={registrationCaptions.RegistrationLastDate}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={190}
					/>
				</DataGrid>
			</div>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'RegistrationExport'}
				getGridParams={getRegistrations}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
				exportCaptions={registrationCaptions}
			/>
		</div>
	);
}

export default Registration;