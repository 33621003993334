import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {useHistory} from 'react-router-dom';
import {getDataSource} from 'services/dataSource';
import {getMerchantApiProfile} from 'services/requestConsts';
import {getAppliedFilters} from 'utils/functions';
import {vars} from 'utils/variables';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {Button} from 'devextreme-react';

const {TRANSACTION, STATE_STORING_KEYS: {MERCHANTS: {PROFILES_PAGE}}, MERCHANT: {PROFILE_DETAIL_PATH}} = vars;

const Profiles = (props) => {
	const gridRef = useRef(null);
	const [merchantApiProfiles, setMerchantApiProfiles] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const history = useHistory();
	
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	useEffect(() => {
		const dataSource = getDataSource(getMerchantApiProfile());
		setMerchantApiProfiles(dataSource);
	}, []);
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter()
		}
	}
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}
	
	return <div className={'page-component-wrapper-custom'}>
		<div className={'grid-wrapper'}>
			<DataGrid
				ref={gridRef}
				id={'grid-acc-statement'}
				height={'100%'}
				dataSource={merchantApiProfiles}
				onContentReady={({component, element}) => {
					getAppliedFilters(component, element);
				}}
			>
				<Toolbar>
					<Item location={'before'}>
						<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
						</div>
					</Item>
					<Item location={'before'}>
						<Button icon={'filter'} onClick={toggleFilter}/>
					</Item>
					<Item location={'before'}>
						<Button icon={'clearsquare'} onClick={clearFilters}/>
					</Item>
					<Item location={'after'}>
						<Button icon={'xlsxfile'} onClick={exportGrid}/>
					</Item>
				</Toolbar>
				<RemoteOperations
					paging
					filtering
				/>
				<Paging enabled defaultPageSize={50}/>
				<FilterRow visible={showFilter}/>
				<Scrolling
					mode={'infinite'}
					showScrollbar="onHover"
				/>
				<Column
					defaultFilterValue={history.location.state && history.location.state.merchantFilterID}
					dataType={'number'}
					dataField={'MerchantID'}
					caption={'ID Клиента'}
				/>
				<Column dataType={'string'} dataField={'MerchantName'} caption={'Имя Клиента'}/>
				<Column dataType={'number'} dataField={'ID'} caption={'ID Профиля'}/>
				<Column dataType={'string'} dataField={'Name'} caption={'Имя Профиля'}/>
				<Column
					dataField={'InsDate'}
					caption={'Дата создания'}
					dataType={'datetime'}
					format={'dd.MM.yy, HH:mm:ss'}
				/>
				<Column dataType={'string'} dataField={'MerchantApiProfileStatusName'} caption={'Статус'}/>
				<Column dataType={'string'} visible={false} dataField={'NotifySignKey'}/>
				<Column
					type={'buttons'}
					caption={'Действие'}
					buttons={[
						{
							hint: 'Открыть',
							text: 'Открыть',
							onClick: ({row}) => {
								history.push({
									pathname: PROFILE_DETAIL_PATH,
									state: {merchant: row.data, merchantFilterID: row.data.MerchantID},
								});
							},
						},
					]}
				/>
			</DataGrid>
		</div>
		<ExportDataGrid
			ref={gridRef}
			exportFileName={'ProfilesExport'}
			getGridParams={getMerchantApiProfile}
			isShowExportDatePopup={isShowExportDatePopup}
			closeExportDatePopup={closeExportDatePopup}
		/>
	</div>;
};

export default Profiles;


