import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {Column, Item, Paging, RemoteOperations, Scrolling, Toolbar} from 'devextreme-react/data-grid';
import {exportReferrerDetail, getReferralList} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {
	fileDateTextFormat,
	firstDateOfMonth,
	lastDateOfMonth,
	prepareDatesForGridExport,
} from 'services/dataSourceFilterUtils';
import {vars} from 'utils/variables';
import {useHistory} from 'react-router-dom';
import {menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import Button from 'devextreme-react/button';

const {CLIENT_PATH, REFERRER_PATH, PATH_KEY: {CLIENTS}} = vars;

const ReferrerDetail = (props) => {
	const history = useHistory();
	const referrerDetailRef = useRef(null);
	const [isShowExportPopup, setIsShowExportPopup] = useState(false);
	const closeExportDatePopup = () => {
		setIsShowExportPopup(false);
	}
	const [detailDs, setDetailDs] = useState(null);
	const [exportDetailDs, setExportDetailDs] = useState(null);
	const [referrerUserID] = useState(() => {
		return props.location.state ? props.location.state.referrerUserID : null;
	})
	
	const [startDate, setStartDate] = useState({
		value: firstDateOfMonth(),
		text: fileDateTextFormat(firstDateOfMonth()),
	});
	const [endDate, setEndDate] = useState({
		value: lastDateOfMonth(),
		text: fileDateTextFormat(lastDateOfMonth()),
	});
	
	useEffect(() => {
		if (referrerUserID === null) {
			history.replace(REFERRER_PATH);
		}
	}, [referrerUserID]);
	
	useEffect(() => {
		if (!detailDs) {
			const params = getReferralList({
				ReferrerUserID: referrerUserID,
			});
			const ds = getDataSource(params);
			setDetailDs(ds);
		}
	}, [detailDs]);
	
	const getExportData = () => {
		return getReferralList({
			ReferrerUserID: referrerUserID,
			InsDate: {
				between: prepareDatesForGridExport(startDate.value, endDate.value),
			},
		});
	}
	
	const getExportFileName = (starDate, endDate) => {
		let dateStr = fileDateTextFormat(starDate);
		const endDateStr = fileDateTextFormat(endDate);
		
		if (dateStr !== endDateStr) {
			dateStr = dateStr + '-' + endDateStr;
		}
		
		return `Referrals(${dateStr})`;
	}
	
	const exportGrid = () => {
		setIsShowExportPopup(!isShowExportPopup);
	}
	
	return (
		<div className={'clients-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CLIENTS]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				<div className={'page-component-wrapper-custom'}>
					<div className={'grid-wrapper'}>
						<DataGrid
							ref={referrerDetailRef}
							id={'grid-acc-statement'}
							alignment={'center'}
							dataSource={detailDs}
						>
							<Toolbar>
								<Item location={'after'}>
									<Button
										icon={'xlsxfile'}
										onClick={exportGrid}
									/>
								</Item>
							</Toolbar>
							<RemoteOperations
								paging
								filtering
							/>
							<Paging enabled defaultPageSize={50}/>
							<Scrolling
								mode={'infinite'}
								showScrollbar="onHover"
							/>
							<Column
								dataField={'ID'}
								caption={'ID клиента'}
								width={100}
							/>
							<Column
								dataField={'InsDate'}
								dataType={'datetime'}
								caption={'Дата регистрации'}
								format={'dd.MM.yy, HH:mm:ss'}
								width={150}
							/>
							<Column
								dataField={'Name'}
								caption={'Клиент'}
							/>
							<Column
								dataField={'UserTypeName'}
								caption={'Тип клиента'}
								width={100}
							/>
							<Column
								dataField={'Country'}
								caption={'Страна клиента'}
								width={120}
							/>
							<Column
								dataField={'UserStatusName'}
								caption={'Статус регистрации'}
								width={150}
							/>
							<Column
								dataField={'VmVerificationStatusName'}
								caption={'Статус верификации'}
								width={150}
							/>
							<Column
								dataField={'UserIsBan'}
								caption={'Заблокирован'}
								dataType={'boolean'}
								trueText={'Да'}
								falseText={'Нет'}
								width={150}
							/>
							<Column
								dataField={'AllIncome'}
								caption={'Оборот'}
							/>
							<Column
								dataField={'PeriodIncome'}
								caption={'Оборот за период'}
							/>
							<Column
								dataField={'LastActivityDate'}
								caption={'Последняя активность'}
								dataType={'datetime'}
								format={'dd.MM.yy, HH:mm:ss'}
								width={150}
							/>
							<Column
								dataField={'ReferrerName'}
								caption={'Пригласивший'}
							/>
							<Column
								caption={'Быстрый просмотр'}
								type={'buttons'}
								buttons={[
									{
										hint: 'Просмотреть',
										text: 'Просмотреть',
										onClick: ({row}) => {
											const value = {
												accountFilterID: row.data.ID,
											};
											
											window.sessionStorage.setItem('filter', JSON.stringify(value));
											
											history.push(CLIENT_PATH);
										},
									},
								]}
							/>
						</DataGrid>
					</div>
					<ExportDataGrid
						ref={referrerDetailRef}
						exportFileName={getExportFileName}
						getGridParams={getExportData}
						isShowExportDatePopup={isShowExportPopup}
						closeExportDatePopup={closeExportDatePopup}
						exportCaptions={exportReferrerDetail}
					/>
				</div>
			</div>
		</div>
	);
}

export default ReferrerDetail;