import React, {useEffect, useState} from 'react';
import {Button, TextBox} from 'devextreme-react';
import {useHistory, withRouter} from 'react-router-dom';
import RestrictionForm from 'pages/clients/clients-page/clientsPopupFields/restriction-form';
import {IncomePayout} from 'pages/clients/clients-page/clientsPopupFields';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import dayjs from 'dayjs';
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import {vars} from 'utils/variables';

import 'pages/clients/clients-page/clientsPopupFields/merchant-details.scss';

const {
	MERCHANT,
	CLIENT_PATH,
	APP_CODES,
	WALLETTO_ACCOUNT_ID,
	SHARPAY_IBAN_ID,
	BANXE_ID,
} = vars;

const balanceEnabledIds = [WALLETTO_ACCOUNT_ID, SHARPAY_IBAN_ID, BANXE_ID];

const I = {
	INCOME_BUTTON: 'Пополнение',
	PAYOUT_BUTTON: 'Списание',
	RESTRICTION_BUTTON: 'Блокировки',
	TRANSACTIONS: 'Транзакции',
	CLIENT: 'Клиент',
	BALANCE: 'Показать остаток',
	PARAMETERS: 'Параметры',
};

const AccountDetails = (props) => {
	const history = useHistory();
	const {rights, gridButtonHandler, rowData} = props;
	const [balance, setBalance] = useState(null);
	const [merchantDetailData, setMerchantDetailData] = useState(null);
	
	const getMerchantDetail = async () => {
		const request = await apiRequest({
			operation: 'Merchant/GetDetail',
			data: {
				Params: {
					MerchantID: rowData.MerchantID,
				},
			},
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}
	
	useEffect(() => {
		getMerchantDetail().then((response) => {
			setMerchantDetailData(response);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	const checkButtonRight = (right) => {
		return rights.includes(right);
	};
	
	const getCreationDate = (props) => {
		const {editorOptions: {value}} = props;
		const val = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
		
		return (
			<TextBox
				value={val}
				readOnly={true}
			/>
		);
	};
	
	const fetchBalance = async () => {
		const request = await apiRequest({
			operation: rowData.PaymentSystemTypeID === BANXE_ID ?
				'Banxe/GetAccountBalance' :
				'WallettoAccount/GetBalance',
			data: {
				Params: {
					AccountID: rowData.ID,
				},
			},
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			setBalance(request.data.Response);
		} else {
			notifyApp(request.data.ResponseText);
		}
	}
	
	const getExternalValue = (key) => {
		let obj;
		
		if (rowData.WallettoParams) {
			obj = rowData.WallettoParams;
		} else if(rowData.BanxeParams) {
			obj = rowData.BanxeParams;
		}
		
		return obj ? obj[key] : '';
	}
	
	return (
		(
			<div className={'merchant-details-wrapper'}>
				<div className={'merchant-details-buttons'}>
					<Button
						type={'normal'}
						text={I.RESTRICTION_BUTTON}
						onClick={() => {
							merchantDetailData.UserRights = rights;
							gridButtonHandler('restriction', RestrictionForm, merchantDetailData);
						}}
					/>
					<Button
						type={'danger'}
						visible={rights.includes(MERCHANT.RIGHTS.PAYOUT)}
						onClick={() => {
							gridButtonHandler('payout', IncomePayout, {MerchantID: rowData.MerchantID, AccountID: rowData.ID});
						}}
						text={I.PAYOUT_BUTTON}
					/>
					<Button
						type={'success'}
						visible={rights.includes(MERCHANT.RIGHTS.INCOME)}
						onClick={() => {
							gridButtonHandler('income', IncomePayout, {MerchantID: rowData.MerchantID, AccountID: rowData.ID});
						}}
						text={I.INCOME_BUTTON}
					/>
					<Button
						type={'normal'}
						text={I.CLIENT}
						onClick={() => {
							const value = {
								accountFilterID: rowData.MerchantID,
							};
							
							window.sessionStorage.setItem('filter', JSON.stringify(value));
							
							history.push(CLIENT_PATH);
						}}
					/>
					<Button
						type={'default'}
						text={I.TRANSACTIONS}
						onClick={() => {
							window.sessionStorage.setItem('filter', JSON.stringify({
								accountFilterID: rowData.ID,
							}));
							history.push(MERCHANT.TRANSACTIONS_PATH);
						}}
					/>
					{balanceEnabledIds.includes(rowData.PaymentSystemTypeID) && (
						<Button
							type={'success'}
							text={I.BALANCE}
							onClick={fetchBalance}
						/>
					)}
				</div>
				{balance && (
					<div className={'merchant-details'}>
						<p>{`Баланс: ${balance.Balance}`}</p>
						<p>{`Заблокировано: ${balance.Hold}`}</p>
						<p>{`Итого: ${balance.Total}`}</p>
					</div>
				)}
				<div>
					<Form
						id='account-details-form'
						formData={rowData}
						readOnly={true}
						showColonAfterLabel={true}
						labelLocation={'left'}
					>
						<GroupItem caption='Счёт'>
							<SimpleItem render={getCreationDate} dataField={'InsDate'} label={{text: 'Дата создания'}}/>
							<SimpleItem dataField={'AccountStatusName'} label={{text: 'Статус'}}/>
							<SimpleItem dataField={'ID'} label={{text: 'ID счета'}}/>
							<SimpleItem dataField={'Code'} label={{text: 'Номер счета'}}/>
							<SimpleItem dataField={'ExternalAccountID'} label={{text: 'Внешний ID счёта'}} editorOptions={{
								value: getExternalValue('ExternalAccountID'),
							}}/>
							<SimpleItem dataField={'BoAccountNumber'} label={{text: 'IBAN'}}/>
						</GroupItem>
						<GroupItem caption='Клиент'>
							<SimpleItem dataField={'MerchantID'} label={{text: 'ID'}}/>
							<SimpleItem dataField={'MerchantName'} label={{text: 'Клиент'}}/>
							<SimpleItem dataField={'ExternalClientID'} label={{text: 'Внешний ID Клиента'}} editorOptions={{
								value: getExternalValue('ExternalClientID'),
							}}/>
						</GroupItem>
					</Form>
				</div>
			</div>
		)
	);
}

export default AccountDetails;
