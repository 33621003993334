import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {getWallettoCardList} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {vars} from 'utils/variables';
import AdminPopup from 'components/popup/admin-popup';
import WallettoCardDetails from './walletto-cards/walletto-card-detail';
import RestrictionForm from '../clients-page/clientsPopupFields/restriction-form';

const {TRANSACTION, APP_CODES, NOTIFY} = vars;

const getTitle = (rowData) => {
	if (!rowData) return '';
	
	const {ID, CardNumber, WidgetType, MerchantID, Name} = rowData;
	
	const titles = {
		open: `ID: ${ID}, Карта: ${CardNumber}`,
		restriction: `Ограничения возможностей мерчантов ${MerchantID} ${Name}`,
	};
	
	return titles[WidgetType] ? titles[WidgetType] : '';
};

const WallettoCards = ({rights}) => {
	const [cardsDs, setCardsDs] = useState(getDataSource(getWallettoCardList()));
	const gridRef = useRef(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [rowData, setRowData] = useState(null);
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const closePopup = () => {
		setRowData(null);
	};
	
	/*const filterOptions = {
		UserTypeName: {
			type: 'dictionary',
			filterOperations: [],
			calculateFilterExpression: makeCalculateFilterExpression('UserTypeID'),
			options: {
				object: 'UserType',
				displayName: 'Name',
				keyName: 'ID',
				value: undefined,
				onValueChanged: () => {
				},
			},
		},
	}*/
	
	const getInnerPopupFields = () => {
		if (!rowData) return null;
		
		const {WidgetType} = rowData;
		
		switch (WidgetType) {
			case 'open':
				return (
					<WallettoCardDetails
						rowData={rowData}
						actionType={WidgetType}
						gridButtonHandler={setRowData}
						closePopup={closePopup}
						gridRef={gridRef}
						rights={rights}
					/>
				);
			case 'restriction':
				return (
					<RestrictionForm
						rowData={{...rowData, UserRights: rights}}
						actionType={WidgetType}
						gridButtonHandler={setRowData}
						closePopup={closePopup}
						gridRef={gridRef}
						rights={rights}
					/>
				);
			default:
				return null;
		}
	}
	
	const getMaxWidth = () => {
		return rowData && rowData.WidgetType === 'restriction' ? 1200 : 800;
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<AdminPopup
				logo={false}
				handleClose={closePopup}
				visible={rowData}
				title={getTitle(rowData)}
				maxWidth={getMaxWidth()}
			>
				{getInnerPopupFields()}
			</AdminPopup>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					id={'grid-acc-statement'}
					dataSource={cardsDs}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								icon={'xlsxfile'}
								onClick={exportGrid}
							/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Column
						dataField={'MerchantID'}
						caption={'ID клиента'}
						width={100}
					/>
					<Column
						dataField={'MerchantName'}
						caption={'Клиент'}
					/>
					<Column
						dataField={'UserTypeName'}
						//filterOperations={filterOptions['UserTypeName'].filterOperations}
						//calculateFilterExpression={filterOptions['UserTypeName'].calculateFilterExpression}
						caption={'Тип клиента'}
						width={130}
					/>
					<Column
						dataField={'PaymentSystemTypeName'}
						caption={'Тип счета'}
					/>
					<Column
						dataField={'PaymentSystemTypeInternalName'}
						caption={'Внутренний тип счёта'}
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата создания'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={150}
					/>
					<Column
						dataField={'CardNumber'}
						caption={'Номер карты'}
					/>
					<Column
						dataField={'Iban'}
						caption={'Счет'}
					/>
					<Column
						dataField={'CurrencyCode'}
						caption={'Валюта'}
						width={65}
					/>
					<Column
						dataField={'WallettoCardStatusName'}
						caption={'Статус'}
						width={100}
					/>
					
					<Column
						caption={'Быстрый просмотр'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Open',
								text: 'Открыть',
								onClick: ({row}) => {
									const data = {...row.data, WidgetType: 'open'};
									setRowData(data);
								},
							},
						]}
					/>
				</DataGrid>
			</div>
		</div>
	);
}

export default WallettoCards;