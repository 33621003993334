import React from 'react';
import {LoadPanel} from 'devextreme-react';

const position = {my: 'center', at: 'center', of: '#root'};

const Loader = (props) => {
	const {isShowLoader} = props;
	return (
		<LoadPanel
			position={position}
			visible={isShowLoader}
			shading={true}
			shadingColor={'rgb(0 0 0 / 10%)'}
			showPane={false}
		/>
	);
};

export default Loader;
