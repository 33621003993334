import React from 'react';
import {IncomePayout} from '../clientsPopupFields';
import {getFromLocalStorage} from 'utils/functions';
import {useHistory} from 'react-router-dom';
import ChangeMerchantParameters from '../clientsPopupFields/change-merchant-parameters';
import {Button} from 'devextreme-react';
import {vars} from 'utils/variables';

const {MERCHANT} = vars;

const I = {
	INCOME_BUTTON: 'Пополнение',
	PAYOUT_BUTTON: 'Списание',
	CHANGE_PARAMETERS_BUTTON: 'Параметры',
	TRANSACTIONS: 'Транзакции',
	ACCOUNTS: 'Счета',
	RESULT: 'Результат',
	PROFILES: 'Профили',
	CREATE_PROFILE: 'Создать профиль',
};

const checkButtonRight = (right, rightList) => {
	return rightList ? rightList.includes(right) : !!rightList;
};

const ClientDetailButtons = (props) => {
	const {rowData, setInnerPopupFields, rights} = props;
	const history = useHistory();

	const isIncomeVisible = checkButtonRight(MERCHANT.RIGHTS.INCOME, rights);
	const isPayoutVisible = checkButtonRight(MERCHANT.RIGHTS.PAYOUT, rights);

	return (
		<div className={'merchant-details-wrapper'}>
			<div className={'merchant-details-buttons'}>
				<Button
					type={'success'}
					visible={isIncomeVisible}
					onClick={() => {
						setInnerPopupFields({
							component: IncomePayout,
							type: 'income',
						});
					}}
					text={I.INCOME_BUTTON}
				/>
				<Button
					type={'danger'}
					visible={isPayoutVisible}
					onClick={() => {
						setInnerPopupFields({
							component: IncomePayout,
							type: 'payout',
						});
					}}
					text={I.PAYOUT_BUTTON}
				/>
				<Button
					type={'normal'}
					text={I.CHANGE_PARAMETERS_BUTTON}
					onClick={() => {
						setInnerPopupFields({
							component: ChangeMerchantParameters,
							type: 'change-parameters',
						});
					}}
				/>
				<Button
					type={'success'}
					text={I.ACCOUNTS}
					onClick={() => {
						const value = {
							merchantFilterID: rowData.MerchantID
						};

						window.sessionStorage.setItem('filter', JSON.stringify(value));

						history.push(MERCHANT.ACCOUNTS_PATH);
					}}
				/>
				<Button
					type={'default'}
					text={I.TRANSACTIONS}
					onClick={() => {
						window.sessionStorage.setItem('filter', JSON.stringify({
							merchantFilterID: rowData.MerchantID
						}));
						history.push(MERCHANT.TRANSACTIONS_PATH);
					}}
				/>
				<Button
					type={'success'}
					text={I.PROFILES}
					onClick={() => {
						history.push({
							pathname: MERCHANT.PROFILES_PATH,
							state: {merchantFilterID: rowData.MerchantID}
						});
					}}
				/>
				<Button
					type={'danger'}
					text={I.CREATE_PROFILE}
					onClick={() => {
						history.push({
							pathname: MERCHANT.PROFILE_DETAIL_PATH,
							state: {merchantFilterID: rowData.MerchantID}
						});
					}}
				/>
			</div>
		</div>
	);
}

export default ClientDetailButtons;