import React, {useEffect, useState, useRef} from 'react';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import {Switch} from 'devextreme-react/switch';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import WallettoCardRemark from './walletto-card-remark';
import AdminPopup from 'components/popup/admin-popup';

const {APP_CODES: {SUCCESS}} = vars;

const types = {
	AtmDisabled: 'Снятие в АТМ',
	ContactlessDisabled: 'Бесконтактная оплата',
	MagnetStripeDisabled: 'Магнитная полоса',
	EcommerceDisabled: 'Интернет операции',
};

const WallettoCardSettings = ({rowData}) => {
	const [rules, setRules] = useState(null);
	const [loader, setLoader] = useState(true);
	const [remarkData, setRemarkData] = useState(null);
	const switchRefs = useRef({});
	
	const loadCardRules = async () => {
		const getRulesRequest = await apiRequest({
			operation: 'WallettoCard/GetCardRule',
			data: {
				Params: {
					WallettoCardID: rowData.ID,
				},
			},
		});
		
		if (getRulesRequest.data.ResponseCode === SUCCESS) {
			setRules({...getRulesRequest.data.Response});
		} else {
			notifyApp(getRulesRequest.data.ResponseText);
			setLoader(false);
		}
	};
	
	useEffect(() => {
		loadCardRules().catch((e) => {
			console.error(e);
		});
	}, []);
	
	useEffect(() => {
		if (rules) {
			if (switchRefs.current) {
				Object.entries(rules).forEach(([ruleName, value]) => {
					switchRefs.current[ruleName].instance.option('value', !value);
				});
			}
		}
		console.log(rules)
		console.log(switchRefs)
	}, [rules]);
	
	const closeRemarkPopup = (isConfirmed) => {
		if (remarkData) {
			const { ruleName } = remarkData;
			const switchInstance = switchRefs.current[ruleName];
			
			if (switchInstance) {
				if (isConfirmed) {
					switchInstance.instance.option('value', !rules[ruleName]);
					setRules({ ...rules, [ruleName]: !rules[ruleName] });
				} else {
					switchInstance.instance.option('value', !rules[ruleName]);
				}
			}
		}
		
		setRemarkData(null);
	};
	
	const openRemarkPopup = (ruleName, value) => {
		setRemarkData({
			ruleName,
			ruleValue: value,
			cardId: rowData.ID,
		});
	};
	
	const getRemarkTitle = () => {
		return remarkData ? types[remarkData.ruleName] : '';
	};
	
	return (
		<div>
			<AdminPopup
				logo={false}
				handleClose={() => {
					closeRemarkPopup();
				}}
				visible={remarkData !== null}
				title={getRemarkTitle()}
				maxWidth={800}
			>
				{remarkData && (
					<WallettoCardRemark
						rowData={remarkData}
						closePopup={closeRemarkPopup}
					/>
				)}
			</AdminPopup>
			{rules ? (
				<div className={'dx-fieldset'}>
					{Object.entries(rules).map(([ruleName, value]) => (
						<div className='dx-field' key={ruleName}>
							<div className='dx-field-label'>{types[ruleName]}</div>
							<div className='dx-field-value' onClick={() => {
								const apiValue = value === 1 ? 0 : 1;
								openRemarkPopup(ruleName, apiValue);
							}}>
								<Switch
									ref={el => switchRefs.current[ruleName] = el}
								/>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className={'fields-loader'}>
					<LoadIndicator visible={loader} height={40} width={40}/>
				</div>
			)}
		</div>
	);
};

export default WallettoCardSettings;