import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	HeaderFilter,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Search,
	Toolbar,
} from 'devextreme-react/data-grid';
import {exchangeCaptions, getExchangeRates} from 'services/requestConsts';
import {getDataSource, getLookupDataSource} from 'services/dataSource';
import {RequiredRule} from 'devextreme-react/validator';
import {useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';
import Button from 'devextreme-react/button';

const {TRANSACTION, IS_ACTIVE_DICTIONARY, SPREAD_DICTIONARY} = vars;

const ExchangeRates = ({rights}) => {
	const gridActionRight = rights.includes('54');
	const isToolbarBtnAllowed = rights.includes('55');
	const exchangeRatesRef = useRef(null);
	const [isFilterShow, setIsFilterShow] = useState(false);
	const [exchangeDs] = useState(getDataSource(getExchangeRates()));
	const history = useHistory();
	
	const [currencyDictionary] = useState(
		getLookupDataSource('Currency', 'CurrencyCode', 'Code', 'ID'),
	);
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const goToCurrency = () => {
		history.push('/finance/exchange-rates/currency');
	}
	
	const goToCurrencyBlockchain = () => {
		history.push('/finance/exchange-rates/currency-blockchain');
	}
	
	const goToBlockchain = () => {
		history.push('/finance/exchange-rates/blockchain');
	}
	
	const clearFilters = () => {
		if (exchangeRatesRef.current) {
			exchangeRatesRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (exchangeRatesRef.current) {
			exchangeRatesRef.current.instance.addRow();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={exchangeRatesRef}
					dataSource={exchangeDs}
					height={'100%'}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
						<Item location={'after'}>
							<Button
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Валюты'}
								onClick={goToCurrency}/>
						</Item>
						<Item location={'after'}>
							<Button
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								visble={isToolbarBtnAllowed}
								text={'Валюты/Блокчейны'}
								onClick={goToCurrencyBlockchain}/>
						</Item>
						<Item location={'after'}>
							<Button
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								visble={isToolbarBtnAllowed}
								text={'Блокчейны'}
								onClick={goToBlockchain}/>
						</Item>
					</Toolbar>
					<HeaderFilter visible={true}/>
					<Paging
						enabled={false}
					/>
					<RemoteOperations
						filtering
					/>
					{gridActionRight ? (
						<Editing
							allowUpdating
							allowDeleting
							allowAdding
						/>
					) : null}
					<FilterRow visible={isFilterShow}/>
					
					<Column
						allowHeaderFiltering={true}
						dataField={'FirstCurrencyID'}
						caption={exchangeCaptions.FirstCurrencyCode}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'LookupName'}
							valueExpr={'LookupId'}
						/>
						<HeaderFilter>
							<Search enabled={true}/>
						</HeaderFilter>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'SecondCurrencyID'}
						caption={exchangeCaptions.SecondCurrencyCode}
					>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'LookupName'}
							valueExpr={'LookupId'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'OriginalRate'}
						caption={exchangeCaptions.OriginalRate}
						allowEditing={false}
					/>
					<Column
						dataField={'ReverseOriginalRate'}
						caption={exchangeCaptions.ReverseOriginalRate}
						allowEditing={false}
					>
						<HeaderFilter>
							<Search enabled={true}/>
						</HeaderFilter>
					</Column>
					<Column
						dataField={'Ask'}
						caption={exchangeCaptions.Ask}
						allowEditing={false}
					/>
					<Column
						dataField={'ReverseAsk'}
						caption={exchangeCaptions.ReverseAsk}
						allowEditing={false}
					/>
					<Column
						allowHeaderFiltering={true}
						dataField={'Bid'}
						caption={exchangeCaptions.Bid}
						allowEditing={false}
					>
						<HeaderFilter visible={true} search={true}/>
					</Column>
					<Column
						dataField={'ReverseBid'}
						caption={exchangeCaptions.ReverseBid}
						allowEditing={false}
					/>
					<Column
						dataField={'isActive'}
						caption={exchangeCaptions.isActive}
					>
						<Lookup
							dataSource={IS_ACTIVE_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'Rate'}
						caption={exchangeCaptions.Rate}
					/>
					<Column
						dataField={'ChainCodes'}
						caption={exchangeCaptions.ChainCodes}
					/>
					<Column
						dataField={'AskSpread'}
						caption={exchangeCaptions.AskSpread}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'BidSpread'}
						caption={exchangeCaptions.BidSpread}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'IsActiveSpreadPerc'}
						caption={exchangeCaptions.IsActiveSpreadPerc}
					>
						<Lookup
							dataSource={SPREAD_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'AskSpreadPerc'}
						caption={exchangeCaptions.AskSpreadPerc}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'BidSpreadPerc'}
						caption={exchangeCaptions.BidSpreadPerc}
					>
						<RequiredRule/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default ExchangeRates;