import React from 'react';
import {apiRequest} from 'services/async';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import 'pages/clients/transcactions/transactionsPopupFields/info.scss';

export class FXDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: null,
		};
	}

	async componentDidMount() {
		const {rowData: {ID}} = this.props;
		const {APP_CODES} = vars;
		const info = await apiRequest({
			operation: 'Payment/GetDetail',
			data: {
				Params: {
					PaymentID: ID,
				}
			},
		});

		if (info.data.ResponseCode === APP_CODES.SUCCESS) {
			this.setState({
				details: info.data.Response
			});
		} else {
			notifyApp(info.data.ResponseText);
		}
	}

	render() {
		const {details} = this.state;
		const {rowData: {ID}} = this.props;

		return details ? (
			<div className={'info-wrapper'}>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Дата'}</div>
					<div className='dx-field-value'>{details.InsDate}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'ID Транзакции'}</div>
					<div className='dx-field-value'>{ID}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'ID Документа'}</div>
					<div className='dx-field-value'>{details.PaymentDocumentID}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Сервис'}</div>
					<div className='dx-field-value'>{details.ServiceName}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Статус'}</div>
					<div className='dx-field-value'>{details.PaymentStatus}</div>
				</div>


				<div className='dx-field'>
					<div className='dx-caption'>{'Клиент'}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'ID Мерчанта'}</div>
					<div className='dx-field-value'>{details.AccountMerchantID}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Имя / Название'}</div>
					<div className='dx-field-value'>{details.MerchantAccountName}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Счет / IBAN / Карта'}</div>
					<div className='dx-field-value'>{details.MerchantAccountCode}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Тип счета'}</div>
					<div className='dx-field-value'>{details.MerchantAccountTypeName}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'ID Профиля'}</div>
					<div className='dx-field-value'>{details.MerchantApiProfileID}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'ID Заказа'}</div>
					<div className='dx-field-value'>{details.MerchantOrderID}</div>
				</div>


				<div className='dx-field'>
					<div className='dx-caption'>{'Транзакция'}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Пара'}</div>
					<div className='dx-field-value'>{details.WithdrawCurrencyCode + ' / ' + details.ReceiveCurrencyCode}</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Курс'}</div>
					<div className='dx-field-value'>
						{`1 ${details.WithdrawCurrencyCode} = ${details.CurrencyRate} ${details.ReceiveCurrencyCode}`}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'К списанию'}</div>
					<div className='dx-field-value'>
						{details.WithdrawAmount + ' | ' + details.WithdrawCurrencyCode + ' | ' + details.PaymentSystemTypeName}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Комиссия'}</div>
					<div className='dx-field-value'>
						{details.WithdrawFeeAmount + ' | ' + details.WithdrawCurrencyCode}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'К обмену'}</div>
					<div className='dx-field-value'>
						{details.WithdrawForeignAmount + ' | ' + details.WithdrawCurrencyCode}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'К получению'}</div>
					<div className='dx-field-value'>
						{details.ReceiveForeignAmount + ' | ' + details.ReceiveCurrencyCode + ' | ' + details.PaymentSystemTypeName}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'Комиссия'}</div>
					<div className='dx-field-value'>
						{details.ReceiveFeeAmount + ' | ' + details.ReceiveCurrencyCode}
					</div>
				</div>
				<div className='dx-field'>
					<div className='dx-field-label'>{'К зачислению'}</div>
					<div className='dx-field-value'>
						{details.ReceiveAmount + ' | ' + details.ReceiveCurrencyCode}
					</div>
				</div>
			</div>
		) : (
			<LoadIndicator/>
		);
	}
}

export default FXDetail;