import {vars} from 'utils/variables';

const {
	MERCHANT_COLUMNS, ROLLING_COLUMNS, CRYPTO_ADDR_COLUMNS, PAYMENT_SYSTEM_TYPE_COLUMNS, PAYMENT_SCHEME_COLUMNS,
	EXCHANGE_RATES_COLUMNS, CURRENCY_COLUMNS, CURRENCY_BLOCKCHAIN_COLUMNS, BLOCKCHAIN_COLUMNS, SERVICES_COLUMNS,
} = vars;

export const getPaymentList = () => {
	return {
		key: 'ID',
		object: 'Payment',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			AccountMerchantID: {type: 'number'},
			BoPaymentTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentStatusName: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			TotalAmount: {type: 'number'},
			ForeignAmount: {type: 'number'},
		},
		defFilter: {
			AccountMerchantID: {'>': 0},
		},
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				newArr.push(Object.assign({}, exportClientTransactionPayments, item));
			});
			
			return newArr;
		},
	};
};

export const getFXPaymentList = () => {
	return {
		key: 'ID',
		object: 'Payment',
		columns: [
			'ID',
			'InsDate',
			'PaymentDocumentID',
			'AccountMerchantID',
			'MerchantOrderID',
			'AccountCurrencyCode',
			'ForeignCurrencyCode',
			'OriginalCurrencyRate',
			'CurrencyRate',
			'BoPaymentTypeID',
			'BoPaymentTypeKindID',
			'Amount',
			'ClearAmount',
			'ForeignAmount',
			'ForeignClearAmount',
			'PaymentStatusName',
			'PaymentStatusID',
			'ServiceName',
			'AccountCurrencyViewPrecision',
			'ForeignCurrencyViewPrecision',
		],
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			AccountMerchantID: {type: 'number'},
			BoPaymentTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentStatusName: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			TotalAmount: {type: 'number'},
			ForeignAmount: {type: 'number'},
		},
		defFilter: {
			AccountMerchantID: {'>': 0},
			IsCurrencyDifferent: {'=': 1},
		},
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				let profitPrecision = item.ForeignCurrencyViewPrecision;
				item.Actives = item.AccountCurrencyCode + ' / ' + item.ForeignCurrencyCode;
				item.AmountPayTo = item.ClearAmount;
				item.AmountReceivable = item.ForeignClearAmount;
				item.Currency = item.ForeignCurrencyCode;
				
				if (item.BoPaymentTypeKindID === 1) {
					item.Actives = item.ForeignCurrencyCode + ' / ' + item.AccountCurrencyCode;
					item.AmountPayTo = item.ForeignClearAmount;
					item.AmountReceivable = item.ClearAmount;
					item.Currency = item.AccountCurrencyCode;
					profitPrecision = item.AccountCurrencyViewPrecision;
				}
				
				if (item.OriginalCurrencyRate === item.CurrencyRate) {
					item.Profit = 0;
				} else {
					const profit = item.AmountPayTo * item.OriginalCurrencyRate - item.AmountPayTo * item.CurrencyRate;
					item.Profit = Math.abs(profit).toFixed(profitPrecision);
				}
				
				newArr.push(Object.assign({}, exportFXCurrencyExchange, item));
			});
			
			return newArr;
		},
	};
};

export const exportClientTransactionPayments = {
	InsDate: 'Дата',
	ApproveDate: 'Дата проведения платежа',
	ID: 'ID транзакции',
	AccountMerchantID: 'ID мерчанта',
	BoPaymentTypeName: 'Тип операции',
	AccountPaymentSystemTypeName: 'ПС счета',
	AccountMerchantName: 'Имя клиента',
	AccountCode: 'Счет клиента',
	ForeignClientName: 'Имя контрагента',
	ForeignAccountCode: 'Счет контрагента',
	AccountCurrencyCode: 'Валюта счета',
	ClearAmount: 'Сумма в валюте счета',
	FeeAmount: 'Комиссия в валюте счета',
	Amount: 'Итого в валюте счета',
	ForeignCurrencyCode: 'Валюта платежа',
	ForeignClearAmount: 'Сумма платежа',
	ForeignFeeAmount: 'Комиссия платежа',
	ForeignAmount: 'Итого платежа',
	CurrencyRate: 'Курс',
	PaymentSystemTypeName: 'ПС оплаты',
	PaymentStatusName: 'Статус',
	Description: 'Описания платежа',
};

export const exportFXCurrencyExchange = {
	InsDate: 'Дата',
	ID: 'ID транзакции',
	PaymentDocumentID: 'ID документа',
	AccountMerchantID: 'ID мерчанта',
	MerchantOrderID: 'ID заказа',
	Actives: 'Активы',
	OriginalCurrencyRate: 'Цена.Рынок',
	CurrencyRate: 'Цена.Сделки',
	AmountPayTo: 'Сумма.Отдаю',
	AmountReceivable: 'Сумма.Получаю',
	Profit: 'Прибыль',
	Currency: 'Валюта',
	PaymentStatusName: 'Статус',
	ServiceName: 'Сервис',
};

export const getCheckoutPaymentList = (BoPaymentTypeID, SettlementID) => {
	let obj = {
		key: 'ID',
		object: 'Payment',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			AccountMerchantID: {type: 'number'},
			BoPaymentTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentStatusName: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			TotalAmount: {type: 'number'},
			ForeignAmount: {type: 'number'},
		},
	};
	if (BoPaymentTypeID) {
		obj = Object.assign(obj, {defFilter: {BoPaymentTypeID: BoPaymentTypeID}});
	}
	if (SettlementID) {
		obj = Object.assign(obj, {defFilter: {SettlementID: SettlementID}});
	}
	
	return obj;
};

export const getMerchantOrderPaymentList = (SettlementID) => {
	let obj = {
		key: 'ID',
		object: 'MerchantOrderPayment',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			BoPaymentTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentStatusName: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			TotalAmount: {type: 'number'},
			ForeignAmount: {type: 'number'},
			SettlementID: {type: 'number'},
		},
	};
	
	if (SettlementID) {
		obj = Object.assign(obj, {defFilter: {SettlementID: SettlementID}});
	}
	
	return obj;
};

export const getExportMerchantOrderPaymentList = (SettlementID) => {
	let obj = {
		key: 'ID',
		object: 'MerchantOrderPayment',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			BoPaymentTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentStatusName: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			TotalAmount: {type: 'number'},
			ForeignAmount: {type: 'number'},
		},
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				newArr.push(Object.assign({}, exportMerchantOrderPayment, item));
			});
			
			return newArr;
		},
	};
	
	if (SettlementID) {
		obj = Object.assign(obj, {defFilter: {SettlementID: SettlementID}});
	}
	
	return obj;
};

export const exportMerchantOrderPayment = {
	InsDate: 'Дата',
	ApproveDate: 'Дата проведения платежа',
	ID: 'ID платежа',
	MerchantOrderID: 'ID заказа',
	MerchantID: 'ID мерчанта',
	MerchantApiProfileID: 'ID профиля',
	BoPaymentTypeName: 'Тип операции',
	PaymentSystemTypeName: 'Способ оплаты',
	MerchantOrderCurrencyCode: 'Валюта заказа',
	MerchantOrderAmount: 'Сумма заказа',
	MerchantOrderFeeAmount: 'Комиссия по заказу',
	MerchantOrderTotalAmount: 'Итого по заказу',
	ForeignCurrencyCode: 'Валюта платежа',
	ForeignAmount: 'Сумма платежа',
	ForeignFeeAmount: 'Комиссия платежа',
	ForeignTotalAmount: 'Итого платежа',
	ForeignCurrencyRate: 'Курс по платежу',
	AccountCurrencyCode: 'Валюта счета',
	Amount: 'Сумма в валюте счета',
	FeeAmount: 'Комиссия в валюте счета',
	ClearAmount: 'Итого в валюте счета',
	CurrencyRate: 'Курс',
	RollingAmount: 'Холд',
	PaymentStatusName: 'Статус',
	ForeignAccountCode: 'Card/Account',
	ForeignClientName: 'Holder/Payeer',
	ErrorText: 'Ошибка',
	PayGatePaymentID: 'PayGateTransactionID',
};

export const getMerchantsList = () => ({
	key: 'ID',
	object: 'Merchant',
	columns: MERCHANT_COLUMNS,
	fieldsSettings: {
		InsDate: {type: 'dateTime'},
		ID: {type: 'number'},
		Name: {type: 'string'},
		UserTypeName: {type: 'string'},
		UserStatusName: {type: 'string'},
		TotalBalance: {type: 'number'},
		LastActivityDate: {type: 'dateTime'},
		UserIsBan: {type: 'number'},
	},
	sorts: '-ID',
	modifier: (result) => {
		// This is for technical reason
		// Ids come from server as 'string' instead of 'int'
		// Details https://teamtrack.macc.com.ua/view.php?id=88250#306523
		let newArr = [];
		result.forEach((item) => {
			newArr.push(Object.assign(item, {ID: +item.ID}));
		});
		
		newArr.sort((a, b) => b.ID - a.ID);
		return newArr;
	},
});

export const getRollingList = () => ({
	key: 'ID',
	object: 'RollingPayment',
	columns: ROLLING_COLUMNS,
	fieldsSettings: {
		InsDate: {type: 'dateTime'},
		ID: {type: 'number'},
	},
	sorts: '-InsDate',
});

export const getReferrersList = (filter) => {
	let referrerParams = {
		key: 'ID',
		object: 'Referrer',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			Name: {type: 'string'},
			AllReferralCount: {type: 'number'},
			AllReferallIncome: {type: 'number'},
			PeriodReferralCount: {type: 'number'},
			PeriodReferralIncome: {type: 'number'},
		},
	};
	
	if (filter) {
		const params = {
			defFilter: {
				PeriodStartDate: filter.startDate,
				PeriodEndDate: filter.endDate,
			},
		};
		
		if (filter.merchantId) {
			Object.assign(params.defFilter, {
				MerchantID: filter.merchantId,
			});
		}
		
		Object.assign(referrerParams, params);
	}
	
	return referrerParams;
};

export const getReferralList = (filter) => {
	let referralParams = {
		key: 'ID',
		object: 'Referral',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			Name: {type: 'string'},
			FirstNameOrig: {type: 'string'},
			LastNameOrig: {type: 'string'},
			Country: {type: 'string'},
			DateOfBirth: {type: 'string'},
			Town: {type: 'string'},
			TownOrig: {type: 'string'},
			Street: {type: 'string'},
			StreetOrig: {type: 'string'},
			PostCode: {type: 'string'},
			UserTypeID: {type: 'number'},
			UserTypeName: {type: 'string'},
			UserEmail: {type: 'string'},
			UserPhone: {type: 'string'},
			UserStatusName: {type: 'string'},
			UserStatusID: {type: 'number'},
			VmVerificationStatusName: {type: 'string'},
			VmVerificationStatusID: {type: 'number'},
			VmLastUpdate: {type: 'datetime'},
			LastActivityDate: {type: 'datetime'},
			IsRectrictAddAccount: {type: 'boolean'},
			IsRestrictAddExternalAccount: {type: 'boolean'},
			UserID: {type: 'number'},
			UserIsBan: {type: 'number'},
			UserIsEmailConfirmed: {type: 'number'},
			UserIsPhoneConfirmed: {type: 'number'},
			UserName: {type: 'string'},
			WallettoClientExternalID: {type: 'string'},
			IsArrestAccount: {type: 'number'},
			AllIncome: {type: 'number'},
			PeriodIncome: {type: 'number'},
			ReferrerUserID: {type: 'number'},
			ReferrerName: {type: 'string'},
		},
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				newArr.push(Object.assign({}, exportReferrerDetail, item));
			});
			
			return newArr;
		},
	};
	
	if (filter) {
		Object.assign(referralParams, {
			defFilter: filter,
		});
	}
	
	return referralParams;
};

export const getMerchantOrder = (merchantOrderID) => {
	let obj = {
		key: 'ID',
		object: 'MerchantOrder',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			MerchantID: {type: 'string'},
			Amount: {type: 'number'},
			FeeAmount: {type: 'number'},
			CurrencySymbol: {type: 'string'},
			Status: {type: 'number'},
		},
	}
	
	if (merchantOrderID) {
		obj = Object.assign(obj, {defFilter: {ID: merchantOrderID}});
	}
	
	return obj;
};

export const getCryptoExchangeOrder = () => {
	return {
		key: 'ID',
		object: 'CryptoExchangeOrder',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
		},
		sort: '-InsDate',
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				newArr.push(Object.assign({}, exportCryptoExchangeOrder, item));
			});
			
			return newArr;
		},
	}
};

export const exportCryptoExchangeOrder = {
	InsDate: 'Дата',
	ID: 'ID ордера',
	MerchantID: 'ID мерчанта',
	CryptoExchangeTypeName: 'Тип сделки',
	OrderType: 'Тип ордера',
	Actives: 'Активы',
	CurrencyRate: 'Цена.Ордера',
	AvgCurrencyRate: 'Цена.Средняя',
	Amount: 'Количество',
	ExecutedAmount: 'Выполнено',
	TotalAmount: 'Сумма.Общая',
	StatusName: 'Статус',
	Remark: 'Комментарий',
};

export const exportReferrerDetail = {
	ID: 'ID клиента',
	InsDate: 'Дата регистрации',
	Name: 'Клиент',
	UserTypeName: 'Тип клиента',
	Country: 'Страна клиента',
	UserStatusName: 'Статус регистрации',
	VmVerificationStatusName: 'Статус верификации',
	UserIsBan: 'Заблокирован',
	AllIncome: 'Оборот',
	PeriodIncome: 'Оборот за период',
	LastActivityDate: 'Последняя активность',
	ReferrerName: 'Пригласивший',
};

export const getSettlementList = () => ({
	key: 'ID',
	object: 'Settlement',
	fieldsSettings: {
		InsDate: {type: 'dateTime'},
		ID: {type: 'number'},
		MerchantID: {type: 'string'},
		BeginPeriodDate: {type: 'dateTime'},
		EndPeriodDate: {type: 'dateTime'},
		SettlementDate: {type: 'dateTime'},
		Status: {type: 'number'},
		ErrorText: {type: 'string'},
	},
});

export const getWallettoCardsList = (paymentSystemTypeID) => {
	const configObject = {
		key: 'ID',
		object: 'WallettoPayment',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			LastUpdate: {type: 'dateTime'},
			CarNumber: {type: 'string'},
			OriginalAmount: {type: 'number'},
			OriginalCurrencyCode: {type: 'number'},
			ConversionRate: {type: 'number'},
			Amount: {type: 'number'},
			AccountCurrencyCode: {type: 'string'},
			Mcc: {type: 'string'},
			Rrn: {type: 'string'},
			ApprovalCode: {type: 'string'},
			PaymentStatusName: {type: 'string'},
		},
	};
	
	if (paymentSystemTypeID) {
		Object.assign(configObject, {
			defFilter: {
				PaymentSystemTypeID: paymentSystemTypeID,
			},
		})
	}
	
	return configObject;
};

export const getIbanCardsList = () => ({
	key: 'ID',
	object: 'WallettoPayment',
	fieldsSettings: {
		InsDate: {type: 'dateTime'},
		LastUpdate: {type: 'dateTime'},
		CarNumber: {type: 'string'},
		OriginalAmount: {type: 'number'},
		OriginalCurrencyCode: {type: 'number'},
		ConversionRate: {type: 'number'},
		Amount: {type: 'number'},
		AccountCurrencyCode: {type: 'string'},
		Mcc: {type: 'string'},
		Rrn: {type: 'string'},
		ApprovalCode: {type: 'string'},
		PaymentStatusName: {type: 'string'},
	},
});

export const getAccountsList = (accountMerchantID) => {
	let obj = {
		key: 'ID',
		object: 'Account',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
			ID: {type: 'number'},
			Name: {type: 'string'},
			MerchantID: {type: 'number'},
			MerchantName: {type: 'string'},
			UserTypeName: {type: 'string'},
			PaymentSystemTypeName: {type: 'string'},
			PaymentSystemTypeInternalName: {type: 'string'},
			Code: {type: 'string'},
			CurrencyCode: {type: 'string'},
			Balance: {type: 'number'},
			LastPaymentInsDate: {type: 'dateTime'},
			AccountStatusID: {type: 'number'},
		},
	};
	if (accountMerchantID) {
		obj = Object.assign(obj, {defFilter: {ID: accountMerchantID}});
	}
	return obj;
};

export const getWallettoCardList = () => {
	let obj = {
		key: 'ID',
		object: 'WallettoCard',
		fieldsSettings: {},
	};
	return obj;
};

export const getMerchantDocument = (merchantId) => {
	return {
		key: 'ID',
		object: 'MerchantDocument',
		fieldsSettings: {
			DocumentType: {type: 'string'},
			ID: {type: 'number'},
			MerchantID: {type: 'number'},
			OrderNum: {type: 'number'},
			Version: {type: 'number'},
		},
		defFilter: {MerchantID: merchantId},
	};
};

export const getMerchantApiProfile = () => {
	return {
		key: 'ID',
		object: 'MerchantApiProfile',
		fieldsSettings: {
			MerchantID: {type: 'number'},
			MerchantName: {type: 'string'},
			ID: {type: 'number'},
			Name: {type: 'string'},
			InsDate: {type: 'dateTime'},
			MerchantApiProfileStatusName: {type: 'string'},
		},
	};
};

export const getAccountForSelectBox = (merchantID) => {
	return {
		key: 'ID',
		object: 'Account',
		defFilter: {
			MerchantID: merchantID,
			PaymentSystemTypeID: 0,
			AccountTypeID: 1,
		},
	}
};

export const getMerchantApiProfilePaymentSystemType = (merchantApiProfileID) => {
	return {
		key: 'PaymentSystemTypeID',
		object: 'MerchantApiProfilePaymentSystemType',
		defFilter: {
			MerchantApiProfileID: merchantApiProfileID,
			PaymentTypeID: 6, // Пополнение через Checkout
		},
		sorts: 'Name',
		fieldsSettings: {
			PaymentSystemTypeID: {type: 'number'},
			Name: {type: 'string'},
			PaymentTypeID: {type: 'number'},
			GeneralRollingReserveRate: {type: 'number'},
			GeneralRollingReserveDays: {type: 'number'},
			IndividualRollingReserveRate: {type: 'number'},
			IndividualRollingReserveDays: {type: 'number'},
			IsPayRetailersPaywall: {type: 'boolean'},
			IsSelected: {type: 'boolean'},
		},
		modifier: (response) => {
			return Array.isArray(response) ? response.map((item) => {
				return {...item, IsPayRetailersPaywall: !!item.IsPayRetailersPaywall};
			}) : response;
		},
	};
};

export const getConfigurations = () => {
	return {
		key: 'ID',
		object: 'Configurations',
		columns: ['ID', 'Value', 'Description', 'OrderNum', 'IsMask'],
		sorts: 'OrderNum',
		fieldsSettings: {
			ID: {type: 'string'},
			Value: {type: 'string'},
			Description: {type: 'string'},
		},
	};
};

export const exportCryptoAddress = {
	InsDate: 'Дата',
	MerchantID: 'ID Мерчанта',
	AccountCode: 'Счет мерчанта',
	AccountCurrencyCode: 'Валюта',
	MerchantOrderID: 'ID заказа',
	PaymentCount: 'Кол-во транз.',
	CurrencyCode: 'Актив',
	Address: 'Адрес',
	BlockchainCode: 'Сеть',
	Memo: 'Мемо+',
	ExternalID: 'Внеш. ID',
	AddressStatus: 'Статус',
	ExpiryDate: 'Срок до',
}

export const exportCryptoAddressColumnWidth = {
	InsDate: 17,
	MerchantID: exportCryptoAddress.MerchantID.length,
	AccountCode: 30,
	AccountCurrencyCode: 8,
	MerchantOrderID: 10,
	PaymentCount: exportCryptoAddress.PaymentCount.length,
	CurrencyCode: exportCryptoAddress.CurrencyCode.length,
	Address: 50,
	BlockchainCode: 8,
	Memo: 10,
	ExternalID: 15,
	AddressStatus: 8,
	ExpiryDate: 17,
}

export const getCryptoAddress = () => {
	return {
		key: 'Urlid',
		columns: CRYPTO_ADDR_COLUMNS,
		object: 'AccountCryptoAddress',
		fieldsSettings: {
			InsDate: {type: 'dateTime'},
		},
		sorts: '-InsDate',
		modifier: (result) => {
			let newArr = [];
			result.forEach((item) => {
				newArr.push(Object.assign({}, exportCryptoAddress, item));
			});
			
			return newArr;
		},
	}
};

export const getPaymentSystemType = () => {
	return {
		key: 'ID',
		columns: PAYMENT_SYSTEM_TYPE_COLUMNS,
		object: 'PaymentSystemType',
		fieldsSettings: {},
		sorts: 'ID',
	}
};
export const getPaymentScheme = () => {
	return {
		key: 'ID',
		columns: PAYMENT_SCHEME_COLUMNS,
		object: 'PaymentSystemTypeMatrix',
		fieldsSettings: {},
		sorts: 'ID',
	}
};

export const getPaymentLimits = () => {
	return {
		key: 'ID',
		columns: [
			'ID', 'PaymentTypeID', 'PaymentTypeName', 'PaymentLimitTypeID', 'PaymentLimitTypeName', 'PeriodTypeID',
			'PeriodLength', 'CurrencyID', 'CurrencyCode', 'PaymentSystemTypeID', 'PaymentSystemTypeName', 'AccountTypeID',
			'AccountTypeName', 'LimitValue', 'IsModerate',
		],
		object: 'PaymentLimit',
		fieldsSettings: {},
		sorts: 'PaymentTypeID',
		insertKeys: [
			'PaymentTypeID',
			'PaymentLimitTypeID',
			'PeriodTypeID',
			'PeriodLength',
			'PaymentSystemTypeID',
			'CurrencyID',
			'AccountTypeID',
			'LimitValue',
			'IsModerate',
		],
	}
};

export const getCommissions = () => {
	return {
		key: 'ID',
		columns: [
			'ID', 'PaymentSystemTypeID', 'PaymentSystemTypeName', 'PaymentTypeID', 'PaymentTypeName', 'CurrencyID',
			'CurrencyCode', 'AccountOwnerType', 'MerchantID', 'AmountMin', 'AmountMax', 'AmountConst', 'AmountPerc',
			'FeePayerTypeID', 'FeePayerTypeCode', 'PayGateTokenID', 'PayGateTokenName',
		],
		object: 'PaymentFee',
		fieldsSettings: {},
		sorts: 'PaymentSystemTypeID',
		insertKeys: [
			'PaymentSystemTypeID',
			'PaymentTypeID',
			'CurrencyID',
			'AccountOwnerType',
			'MerchantID',
			'AmountMin',
			'AmountMax',
			'AmountConst',
			'AmountPerc',
			'FeePayerTypeID',
			'PayGateTokenID',
		],
	}
};

export const getRegistrations = () => {
	return {
		key: 'ID',
		columns: ['ID', 'Name', 'Urlid', 'InsDate', 'RegistrationCount', 'RegistrationFirstDate', 'RegistrationLastDate'],
		object: 'AdvPixel',
		fieldsSettings: {},
		sorts: 'ID',
		insertKeys: [
			'Name',
			'Urlid',
		],
	}
};

export const registrationCaptions = {
	Name: 'Название',
	Urlid: 'Urlid',
	InsDate: 'Дата создания',
	RegistrationCount: 'Кол-во регистраций',
	RegistrationFirstDate: 'Дата первой регистрации',
	RegistrationLastDate: 'Дата последней регистрации',
}

export const exchangeCaptions = {
	FirstCurrencyCode: 'Валюта 1',
	SecondCurrencyCode: 'Валюта 2',
	OriginalRate: 'Базовый',
	ReverseOriginalRate: 'Обратный (Баз.)',
	Ask: 'Продажа',
	ReverseAsk: 'Обратный (Прод.)',
	Bid: 'Покупка',
	ReverseBid: 'Обратный (Пок.)',
	isActive: 'Статус',
	Rate: 'Фикс.Курс',
	ChainCodes: 'Курс.Цепочка',
	AskSpread: 'Продажа, фикс',
	BidSpread: 'Покупка, фикс',
	IsActiveSpreadPerc: 'Коррекция в %',
	AskSpreadPerc: 'Продажа, %',
	BidSpreadPerc: 'Покупка, %',
}

export const getExchangeRates = () => {
	return {
		key: 'ID',
		columns: EXCHANGE_RATES_COLUMNS,
		object: 'CurrencyPair',
		fieldsSettings: {},
		sorts: 'FirstCurrencyID',
		insertKeys: [
			'FirstCurrencyID', 'SecondCurrencyID', 'AskSpread', 'BidSpread', 'IsActiveSpreadPerc', 'AskSpreadPerc',
			'BidSpreadPerc', 'isActive', 'Rate', 'ChainCodes',
		],
	}
};

export const getCurrency = () => {
	return {
		key: 'ID',
		columns: CURRENCY_COLUMNS,
		object: 'Currency',
		fieldsSettings: {},
		sorts: 'ID',
		insertKeys: CURRENCY_COLUMNS,
	}
};

export const getCurrencyBlockchain = () => {
	return {
		key: 'ID',
		columns: CURRENCY_BLOCKCHAIN_COLUMNS,
		object: 'CurrencyBlockchainMatrix',
		fieldsSettings: {},
		sorts: 'ID',
		insertKeys: [
			'CurrencyID',
			'BlockchainID',
			'AddressValidationRegexp',
			'MinDeposit',
			'MinWithdraw',
			'WithdrawFeeAmountConst',
			'IsHasMemo',
		],
	}
};

export const getBlockchain = () => {
	return {
		key: 'ID',
		columns: BLOCKCHAIN_COLUMNS,
		object: 'Blockchain',
		fieldsSettings: {},
		sorts: 'ID',
		insertKeys: ['Code', 'Name', 'IsAddressCaseInsensitive'],
	}
};

export const getServices = () => {
	return {
		key: 'ID',
		object: 'Service',
		columns: SERVICES_COLUMNS,
		sorts: 'ID',
		fieldsSettings: {},
		modifier2: (result) => {
			const forDelete = [];
			const newArr = [];
			
			result.forEach((item, index) => {
				forDelete.push({...item, IsDeleted: index % 2, LimitCurrencyID: index % 2 ? 980 : 840});
			});
			
			
			forDelete.forEach((item) => {
				newArr.push({...item, localStatusValue: item.IsDeleted === 1 ? -1 : item.IsActive});
			});
			
			console.log(newArr);
			
			return newArr;
		},
		insertKeys: [
			'ServiceCategoryID',
			'IsActive',
			'Description',
		],
	};
};
