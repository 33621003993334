import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Button as GridButton,
	Column,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import {getPaymentSystemType} from 'services/requestConsts';
import PaymentSystemsEditForm from './edit-form/payment-systems-edit-form';
import {getDataSource} from 'services/dataSource';
import AdminPopup from 'components/popup/admin-popup';
import {getFromLocalStorage, valueToArray} from 'utils/functions';
import {vars} from 'utils/variables';
import Button from 'devextreme-react/button';

const {TRANSACTION, PAYMENT_SYSTEM_KIND_DICTIONARY} = vars;

const PaymentSystems = (props) => {
	const {rights} = props;
	const hasEditRight = rights.includes('51');
	const gridRef = useRef(null);
	const [paymentSystemsDs, setPaymentSystemsDs] = useState(null);
	const [showFilter, setShowFilter] = useState(false);
	
	useEffect(() => {
		setPaymentSystemsDs(getDataSource(getPaymentSystemType()));
	}, []);
	
	const paymentFilterOptions = {
		PaymentSystemTypeGroupName: {
			type: 'dictionary',
			filterOperations: [],
			calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeGroupID'),
			options: {
				object: 'PaymentSystemTypeGroup',
				displayName: 'Name',
				keyName: 'ID',
				value: undefined,
				onValueChanged: () => {
				},
			},
		},
	}
	
	const onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (paymentFilterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = paymentFilterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	}
	
	const [actionRowData, setActionRowData] = useState(null);
	
	const closePopup = () => {
		setActionRowData(null);
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter()
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<AdminPopup
				maxWidth={750}
				logo={false}
				visible={!!actionRowData}
				handleClose={closePopup}
				title={`Платежная система: ${actionRowData && actionRowData.Name}`}
			>
				{actionRowData ? (
					<PaymentSystemsEditForm rowData={actionRowData} closePopup={closePopup}/>
				) : null}
			</AdminPopup>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					dataSource={paymentSystemsDs}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={onEditorPreparing}
					height={'100%'}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Column dataField={'ID'} caption={'ID'}/>
					<Column dataField={'InternalName'} caption={'Внутреннее название'}/>
					<Column dataField={'Name'} caption={'Отображаемое название'}/>
					<Column
						dataType={'string'}
						dataField={'PaymentSystemKindID'}
						caption={'Вид системы'}
						customizeText={({value}) => {
							if (PAYMENT_SYSTEM_KIND_DICTIONARY[value]) {
								return PAYMENT_SYSTEM_KIND_DICTIONARY[value].Name
							} else {
								return '';
							}
						}}
					/>
					<Column
						dataField={'PaymentSystemTypeGroupName'}
						caption={'Группа'}
						filterOperations={paymentFilterOptions['PaymentSystemTypeGroupName'].filterOperations}
						calculateFilterExpression={paymentFilterOptions['PaymentSystemTypeGroupName'].calculateFilterExpression}
					/>
					<Column dataField={'PayGateID'} caption={'ID в Шлюзе'}/>
					<Column dataField={'RollingReserveRate'} caption={'Резерв, ставка'}/>
					<Column dataField={'RollingReserveDays'} caption={'Резерв, дни'}/>
					<Column type={'buttons'}>
						<GridButton
							visible={hasEditRight}
							icon={'edit'}
							onClick={({row}) => {
								const preparedData = row.data;
								preparedData.IsMaskForeignAccountCode = !!preparedData.IsMaskForeignAccountCode;
								preparedData.IsRemoveSpaceForeignAccount = !!preparedData.IsRemoveSpaceForeignAccount;
								preparedData.IsAllowConnectExternalAccount = !!preparedData.IsAllowConnectExternalAccount;
								
								setActionRowData(preparedData);
							}}
						/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default PaymentSystems;