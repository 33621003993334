import React, {useEffect, useRef, useState} from 'react';
import PageMenu from 'components/navigation-menu/page-menu';
import {menuItems} from 'app-routes';
import {vars} from 'utils/variables';
import {Button, SelectBox} from 'devextreme-react';
import {useHistory} from 'react-router-dom';
import {apiRequest, getDocuments, getMerchantHistory} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import ClientCreateTabs from './client-create-tabs';
import AdminPopup from 'components/popup/admin-popup';
import validationEngine from 'devextreme/ui/validation_engine';
import {formatDateToApi} from 'services/dataSourceFilterUtils';
import DownloadDocument from '../common/download-document';

const {PATH_KEY: {CLIENTS}, APP_CODES: {SUCCESS}} = vars;

const ClientCreate = (props) => {
	const {rights} = props;
	const history = useHistory();
	
	const goBack = () => {
		history.goBack();
	}
	
	const [merchantData, setMerchantData] = useState({
		MerchantID: null,
	});
	const [documents, setDocuments] = useState([]);
	const [comments, setComments] = useState([]);
	const [isShowAddPopup, setIsShowAddPopup] = useState(false);
	const [documentType, setDocumentType] = useState({
		types: [],
		selectedType: -1,
	});
	
	useEffect(() => {
		if (isShowAddPopup) {
			closeAddPopup();
		}
	}, [documents]);
	
	useEffect(() => {
		if (merchantData.MerchantID) {
			getDocuments(merchantData.MerchantID).then((response) => {
				setDocuments(response);
			}).catch((errorText) => {
				notifyApp(errorText);
			});
			
			updateComments();
		}
	}, [merchantData]);
	
	const updateComments = () => {
		getMerchantHistory(merchantData.MerchantID).then((response) => {
			setComments(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
	}
	
	const mainFormRef = useRef(null);
	const collapsableFormRef = useRef(null);
	
	const closeAddPopup = () => {
		setIsShowAddPopup(false);
	}
	
	useEffect(() => {
		if (isShowAddPopup) {
			fetchDocumentTypes().then((result) => {
				setDocumentType({
					types: result,
					selectedType: result[0]['ID'],
				});
			}).catch((error) => {
				notifyApp(error);
			})
		}
	}, [isShowAddPopup]);
	
	const fetchDocumentTypes = async () => {
		const request = await apiRequest({
			operation: 'DocumentType/List',
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			if (request.data.Response.DocumentType.length > 0) {
				return request.data.Response.DocumentType;
			} else {
				throw new Error('Ответ DocumentType/List не содержит ни одного элемента');
			}
		} else {
			throw new Error(request.data.ResponseText);
		}
	};
	
	const [selectedUserType, setSelectedUserType] = useState(null);
	
	return (
		<div className={'clients-wrapper'}>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeAddPopup}
				visible={isShowAddPopup}
				title={'Загрузка файлов'}
			>
				{documentType.types.length > 0 ? (
					<div>
						<SelectBox
							dataSource={documentType.types}
							displayExpr={'Name'}
							valueExpr={'ID'}
							value={documentType.selectedType}
							onValueChanged={({value}) => {
								setDocumentType({
									...documentType,
									selectedType: value,
								});
							}}
						/>
						<DownloadDocument
							key={isShowAddPopup.toString()}
							merchantId={merchantData.MerchantID}
							documentType={documentType}
							setDocuments={setDocuments}
						/>
					</div>
				) : null}
			</AdminPopup>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CLIENTS]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				<div className={'profile-detail-wrapper'}>
					<div className={'profile-detail-static'}>
						<div className={'profile-detail-title'}>
							<h1>Создание нового клиента</h1>
						</div>
						<div className={'merchant-details-wrapper'}>
							<div className={'merchant-details-buttons'}>
								<Button
									type={'success'}
									disabled={!!(merchantData.MerchantID)}
									onClick={async () => {
										
										const common = validationEngine.validateGroup('common');
										const business = validationEngine.validateGroup('business');
										
										if (selectedUserType === 2 && !business.isValid) {
											notifyApp('Бизнес данные не заполнены');
										} else if (!common.isValid) {
											notifyApp('Основные данные не заполнены');
										}
										
										if (common.isValid && business.isValid) {
											if (mainFormRef.current && collapsableFormRef.current) {
												const mainFromData = mainFormRef.current.instance.option('formData');
												if (mainFromData['DateOfBirth']) {
													mainFromData['DateOfBirth'] = formatDateToApi(mainFromData['DateOfBirth']);
												}
												const collapsableFormData = collapsableFormRef.current.instance.option('formData');
												if (collapsableFormData['BUdateOfReg']) {
													collapsableFormData['BUdateOfReg'] = formatDateToApi(collapsableFormData['BUdateOfReg']);
												}
												if (collapsableFormData['BUHEADdateOfBirth']) {
													collapsableFormData['BUHEADdateOfBirth'] = formatDateToApi(collapsableFormData['BUHEADdateOfBirth']);
												}
												const formData = Object.assign(mainFromData, collapsableFormData);
												
												const createRequest = await apiRequest({
													operation: 'Merchant/CreateUser',
													data: {
														Params: formData,
													},
												});
												
												if (createRequest.data.ResponseCode === SUCCESS) {
													setMerchantData(createRequest.data.Response);
												} else {
													notifyApp(createRequest.data.ResponseText);
												}
											}
										}
									}}
									text={'Сохранить'}
								/>
								<Button
									type={'danger'}
									visible={true}
									onClick={goBack}
									text={'Закрыть'}
								/>
							</div>
						</div>
					</div>
					<div className={'client-detail-scroll'}>
						<div className={'client-detail-inner'}>
							<ClientCreateTabs
								mainFormRef={mainFormRef}
								collapsableFormRef={collapsableFormRef}
								merchantData={merchantData}
								documents={documents}
								setIsShowAddPopup={setIsShowAddPopup}
								rights={rights}
								comments={comments}
								updateComments={updateComments}
								selectedUserType={selectedUserType}
								setSelectedUserType={setSelectedUserType}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ClientCreate;