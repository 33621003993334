import React from 'react';
import '../../App.scss';

export default class NotFound extends React.PureComponent {
	render() {
		return (
			<div className={'under-construction-wrapper'}>
				<div className={'under-construction'}>
					Page not found
				</div>
			</div>
		);
	}
}
