import React, {useEffect, useRef, useState} from 'react';
import ProfileParamsDetailButtons from './profile-params-detail-buttons';
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import DataGrid, {Button as GridButton, Column, Editing, Lookup} from 'devextreme-react/data-grid';
import {getDictionaryDataSource, getDictionaryDataSourceNew} from 'services/dataSource';
import {notifyApp} from 'utils/notifyWrapper';
import Button from 'devextreme-react/button';
import {confirm} from 'devextreme/ui/dialog';
import Collapsible from 'react-collapsible';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

const {
	APP_CODES: {SUCCESS},
	SETTLEMENT_MODE,
	CRYPTO_INCOME_DICTIONARY,
} = vars;

const ProfileParams = ({togglePage, formState}) => {
	console.group('ProfileParams');
	console.log(formState);
	console.groupEnd();
	const {profileParams, merchant} = formState;
	const {merchantApiProfilePaymentSystemType} = profileParams;
	const profileParamsRef = useRef(null);
	const paramsGridRef = useRef(null);

	const [isPayRetailersPaywall, setIsPayRetailersPaywall] = useState(() => {
		return profileParams.merchantApiProfilePaymentSystemType ?
			profileParams.merchantApiProfilePaymentSystemType.IsPayRetailersPaywall :
			null;
	});

	const [paymentSystemTypeDictionary] = useState(
		getDictionaryDataSourceNew({
			objectName: 'PaymentSystemType',
			sorts: ['Name'],
		}));

	const loadMerchantApiProfileSettlement = async () => {
		const objectName = 'MerchantApiProfileSettlement';
		const req = await apiRequest({
			operation: `${objectName}/List`,
			data: {
				Filters: {
					MerchantApiProfileID: merchant.ID, // ID профиля
					PaymentSystemTypeID: merchantApiProfilePaymentSystemType.PaymentSystemTypeID, // ID платежной системы
				},
				Sorts: ['MerchantOrderCurrencyCode',],
				Columns: [
					'ID',
					'MerchantApiProfileID',
					'PaymentSystemTypeID',
					'PaymentSystemTypeName',
					'MerchantOrderCurrencyID',
					'MerchantOrderCurrencyCode',
					'DefaultAccountCurrencyID',
					'DefaultAccountCurrencyCode',
					'SettlementPeriodDays',
					'SettlementDelayDays',
					'IsSettlementManualPay',
				],
				Page: -1,
			}
		});

		if (req.data.ResponseCode === SUCCESS) {
			return req.data.Response[objectName];
		} else {
			throw new Error(req.data.ResponseText);
		}
	}

	const [gridDs, setGridDs] = useState(null);
	const [currencyDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Currency',
		})
	);

	useEffect(() => {
		if (merchant.ID && merchantApiProfilePaymentSystemType) {
			loadMerchantApiProfileSettlement().then((result) => {
				setGridDs(result);
			}).catch((errorText) => {
				notifyApp(errorText);
			});
		}
	}, []);

	const onGridDataInserted = (e) => {
		const dsInstance = paramsGridRef.current.instance.getDataSource();

		dsInstance.store().update(e.key, {ID: newSettlementID}).then(() => {
			dsInstance.reload();
		});

	}

	const [newSettlementID, setNewSettlementID] = useState(null);

	const onGridDataChange = (e) => {
		e.cancel = new Promise((resolve, reject) => {
			saveDataToApi(e).then((response) => {
				if (response) {
					const {ID} = response;
					setNewSettlementID(ID);
				}
			}).then(() => {
				resolve(false);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	const saveDataToApi = async (e) => {
		const {oldData, newData, data} = e;
		const objectName = 'MerchantApiProfileSettlement';
		let payload;

		const actualData = {...oldData, ...newData};

		if (data) {
			payload = {
				MerchantApiProfileID: merchant.ID, //  ID профиля
				PaymentSystemTypeID: merchantApiProfilePaymentSystemType.PaymentSystemTypeID, //  ID платежной системы
				...data,
			}
			delete payload['__KEY__'];
		} else {
			payload = {
				ID: actualData.ID, // редактируемой записи
				MerchantOrderCurrencyID: actualData.MerchantOrderCurrencyID, // ID валюты значения MerchantOrderCurrencyCode
				DefaultAccountCurrencyID: actualData.DefaultAccountCurrencyID, // ID валюты значения DefaultAccountCurrencyCode
				SettlementPeriodDays: actualData.SettlementPeriodDays, // из поля "Период Settlement-а"
				SettlementDelayDays: actualData.SettlementDelayDays, // из поля "Отсрочка выплаты Settlement-а"
				IsSettlementManualPay: actualData.IsSettlementManualPay, // из поля "Режим выплаты Settlement-а": 0 - Auto, 1 - Manual
			}
		}

		const request = await apiRequest({
			operation: objectName + '/' + (data ? 'Ins' : 'Mod'),
			data: {
				Params: payload,
			}
		});

		if (request.data.ResponseCode === SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}

	const [localData, setLocalData] = useState({
		localPaymentSystemId: merchantApiProfilePaymentSystemType ?
			merchantApiProfilePaymentSystemType.PaymentSystemTypeID :
			null,
		localGeneralRollingReserveRate: merchantApiProfilePaymentSystemType ?
			merchantApiProfilePaymentSystemType.GeneralRollingReserveRate :
			null,
		localRollingReserveDays: merchantApiProfilePaymentSystemType ?
			merchantApiProfilePaymentSystemType.RollingReserveDays
			: null,
	});

	return (
		<div id={'profile-params-wrapper'}>
			<ProfileParamsDetailButtons
				localPaymentSystemId={localData.localPaymentSystemId}
				profileParamsRef={profileParamsRef}
				isEditingMode={profileParams.isEditingMode}
				merchantApiProfileID={merchant.ID}

				togglePage={togglePage}
			/>
			<Form
				ref={profileParamsRef}
				formData={merchantApiProfilePaymentSystemType}
				showColonAfterLabel={true}
				labelLocation={'left'}
			>
				<GroupItem caption='Основные параметры'>
					<SimpleItem
						dataField={'Name'}
						label={{text: 'Платежная система'}}
						editorOptions={{
							readOnly: profileParams.isEditingMode,
							dataSource: paymentSystemTypeDictionary,
							valueExpr: 'ID',
							displayExpr: 'Name',
							value: localData.localPaymentSystemId,
							onValueChanged: ({value}) => {
								const selected = paymentSystemTypeDictionary.store.__rawData.find((item) => {
									return item.ID === value;
								});

								setLocalData({
									localPaymentSystemId: value,
									localGeneralRollingReserveRate: selected.RollingReserveRate,
									localRollingReserveDays: selected.RollingReserveDays,
								});
							}
						}}
						editorType={'dxSelectBox'}
					/>
					<SimpleItem
						dataField={'IsAllowCheckoutIncome'}
						label={{text: 'Разрешена оплата'}}
						editorType={'dxCheckBox'}
					/>
					<SimpleItem
						dataField={'IsAllowCheckoutPayout'}
						label={{text: 'Разрешена выплата'}}
						editorType={'dxCheckBox'}
					/>
					<SimpleItem
						dataField={'IsAllowPayoutFromDefaultAccount'}
						label={{text: 'Разрешена выплата с мерчант счета'}}
						editorType={'dxCheckBox'}
					/>
					<SimpleItem
						dataField={'IsPayRetailersPaywall'}
						label={{text: 'Режим Paywall'}}
						editorType={'dxCheckBox'}
					/>
					<SimpleItem
						dataField={'CurrencyIDs'}
						label={{text: 'Разрешенные валюты для оплаты'}}
					/>
					<SimpleItem
						dataField={'CryptoIncomeProcessType'}
						label={{text: 'Тип обработки криптоплатежей'}}
						editorOptions={{
							dataSource: CRYPTO_INCOME_DICTIONARY,
							valueExpr: 'ID',
							displayExpr: 'Name',
							value: isPayRetailersPaywall,
							onValueChanged: ({value}) => {
								setIsPayRetailersPaywall(value);
							}
						}}
						editorType={'dxSelectBox'}
					/>
					<SimpleItem
						dataField={'GeneralRollingReserveRate'}
						label={{text: 'Резервирование, ставка %'}}
						editorOptions={{
							readOnly: true,
							value: localData.localGeneralRollingReserveRate,
						}}
					/>
					<SimpleItem
						dataField={'GeneralRollingReserveDays'}
						label={{text: 'Резервирование, дней'}}
						editorOptions={{
							readOnly: true,
							value: localData.localRollingReserveDays,
						}}
					/>
					<SimpleItem
						dataField={'IndividualRollingReserveRate'}
						label={{text: 'Резервирование (индивидуальное), ставка %'}}
						editorOptions={{
							min: 0,
							step: 0,
						}}
						editorType={'dxNumberBox'}
					/>
					<SimpleItem
						dataField={'IndividualRollingReserveDays'}
						label={{text: 'Резервирование (индивидуальное), дней'}}
						editorOptions={{
							min: 0,
							step: 0,
						}}
						editorType={'dxNumberBox'}
					/>
				</GroupItem>
			</Form>
			<Collapsible trigger={'Настройки Settlement'}>
				<div className={'accordion-form'}>
					<Button
						disabled={!profileParams.merchantApiProfilePaymentSystemType}
						className={'accordion-item'}
						text={'Добавить'}
						onClick={() => {
							if (paramsGridRef.current) {
								paramsGridRef.current.instance.addRow();
							}
						}}
					/>

					<DataGrid
						ref={paramsGridRef}
						dataSource={gridDs}
						onRowUpdating={onGridDataChange}
						onRowInserting={onGridDataChange}
						onRowInserted={onGridDataInserted}
					>
						<Editing
							allowUpdating={true}
							mode={'row'}
							// confirmDelete={false} on version 23.1
							texts={{
								confirmDeleteMessage: '',
							}}
							refreshMode={'repaint'}
						/>
						<Column
							dataField={'MerchantOrderCurrencyID'}
							caption={'Валюта заказа'}
						>
							<Lookup
								dataSource={currencyDictionary}
								displayExpr={'Code'}
								valueExpr={'ID'}
							/>
						</Column>
						<Column
							dataField={'DefaultAccountCurrencyID'}
							caption={'Валюта Settlement-а'}
						>
							<Lookup
								dataSource={currencyDictionary}
								displayExpr={'Code'}
								valueExpr={'ID'}
							/>
						</Column>
						<Column dataField={'SettlementPeriodDays'} caption={'Период Settlement-а'}/>
						<Column dataField={'SettlementDelayDays'} caption={'Отсрочка выплаты Settlement-а'}/>
						<Column
							dataField={'IsSettlementManualPay'}
							caption={'Режим выплаты Settlement-а'}
						>
							<Lookup
								dataSource={SETTLEMENT_MODE}
								displayExpr={'Name'}
								valueExpr={'ID'}
							/>
						</Column>
						<Column type={'buttons'}>
							<GridButton name={'edit'}/>
							<GridButton icon={'close'} onClick={async (e) => {
								const {row: {rowIndex, data: {ID}}} = e;
								const isApproved = await confirm("<i>Are you sure?</i>", "Confirm changes");

								if (isApproved) {
									// call delete operation MerchantApiProfileSettlement/Del
									const delSettlementRequest = await apiRequest({
										operation: 'MerchantApiProfileSettlement/Del',
										data: {
											Params: {
												ID: ID,
											}
										}
									});

									if (delSettlementRequest.data.ResponseCode === SUCCESS) {
										paramsGridRef.current.instance.deleteRow(rowIndex);
									} else {
										notifyApp(delSettlementRequest.data.ResponseText);
									}
								}
							}}/>
						</Column>
					</DataGrid>
				</div>
			</Collapsible>
		</div>
	);
}

export default ProfileParams;