import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterRow,
	Format,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {Button, TextArea} from 'devextreme-react';
import {getDataSource} from 'services/dataSource';
import {getRollingList} from 'services/requestConsts';
import {useHistory} from 'react-router-dom';
import {getAppliedFilters, getFromLocalStorage, isVisibleRollingBtn, toggleContextMenu} from 'utils/functions';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import AdminPopup from 'components/popup/admin-popup';
import {vars} from 'utils/variables';
import {menuItems} from 'app-routes';
import {ButtonGroup} from 'devextreme-react/button-group';

const {PRECISION, TRANSACTION, NOTIFY, APP_CODES: {SUCCESS}} = vars;

const initialConfirm = {remark: '', operation: null, title: ''};

const contextInnerButtons = [
	{
		action: 'Pay',
		actionText: 'Выплатить роллинг',
		hint: 'Выплатить',
		right: '31',
	},
	{
		action: 'CloseWithoutPay',
		actionText: 'Отметить как выплаченный',
		hint: 'Закрыть без выплаты',
		right: '32',
	},
]

const CustomButtonGroupCell = ({row, handleGridColumnButton, isVisibleButton, history, rights}) => {
	const handleClick = (args) => {
		const {element, event, itemData} = args;
		
		if (itemData.hint === 'Action') {
			const ctxMenu = document.getElementById('ctx-menu');
			const rect = element.getBoundingClientRect();
			
			contextInnerButtons.forEach((btn) => {
				const btnElement = document.getElementById(btn.action);
				
				if (isVisibleButton(rights, btn.right, row)) {
					btnElement.classList.remove('invisible');
				} else {
					btnElement.classList.add('invisible');
				}
				
				btnElement.onclick = () => {
					toggleContextMenu();
					const {ID, Amount, CurrencyCode} = row.data;
					const title = `${btn.actionText}: ID ${ID}, сумма ${Amount}, валюта ${CurrencyCode}`;
					handleGridColumnButton({
						remark: '',
						operation: 'RollingPayment/' + btn.action,
						title: title,
						row: row.data,
					});
				}
			});
			
			ctxMenu.style.top = (rect.bottom - 86) + 'px';
			ctxMenu.style.left = (rect.right - ctxMenu.offsetWidth) + 'px';
			
			toggleContextMenu();
		} else {
			window.sessionStorage.setItem('filter', JSON.stringify({
				id: row.data.PaymentID,
			}));
			history.push(menuItems.checkout[1].url);
		}
	};
	
	const gridButtons = [{hint: 'Показать платежи', icon: 'info'}];
	
	if (
		isVisibleButton(rights, contextInnerButtons[0].right, row) ||
		isVisibleButton(rights, contextInnerButtons[1].right, row)) {
		gridButtons.push({hint: 'Action', icon: 'mdi mdi-dots-vertical'});
	}
	
	return (
		<div className={'button-group-wrapper'}>
			<ButtonGroup
				items={gridButtons}
				keyExpr="hint"
				stylingMode="outlined"
				onItemClick={handleClick}
			/>
		</div>
	);
}

const Rolling = (props) => {
	const {rights} = props;
	const gridRef = useRef(null);
	const [rolling, setRolling] = useState(null);
	const [showFilter, setShowFilter] = useState(false);
	const history = useHistory();
	
	useEffect(() => {
		const dataSource = getDataSource(getRollingList());
		setRolling(dataSource);
	}, []);
	
	const [confirmData, setConfirmData] = useState(initialConfirm);
	
	const handleClose = () => {
		const dataSource = getDataSource(getRollingList());
		setConfirmData(initialConfirm);
		setRolling(dataSource);
	};
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter()
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<AdminPopup
				key={confirmData.title}
				title={confirmData.title}
				logo={false}
				handleClose={handleClose}
				visible={!!confirmData.operation}
				maxWidth={800}
			>
				<div className={'add-comment-wrapper'}>
					{confirmData.row ? (
						<div className="field-info">
							<div>{`Клиент: ${confirmData.row.MerchantName}`}</div>
							<div>{`Счёт: ${confirmData.row.AccountCode}`}</div>
							<div>{`Профиль: ${confirmData.row.MerchantApiProfileName}`}</div>
						</div>
					) : null}
					
					<div className="field-label">{'Примечание'}</div>
					<TextArea
						height={120}
						text={confirmData.remark}
						onValueChanged={({value}) => {
							setConfirmData({
								...confirmData,
								remark: value,
							});
						}}
					/>
					<Button id={'add-comment-btn'} text={'Ok'} onClick={async () => {
						const request = await apiRequest({
							operation: confirmData.operation,
							data: {
								Params: {
									RollingPaymentID: confirmData.row.ID,
									Remark: confirmData.remark,
								},
							},
						});
						
						if (request.data.ResponseCode === SUCCESS) {
							notifyApp('Success', NOTIFY.SUCCESS);
							handleClose();
						} else {
							notifyApp(request.data.ResponseText);
						}
					}}/>
				</div>
			</AdminPopup>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					id={'grid-acc-statement'}
					dataSource={rolling}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<FilterRow visible={showFilter}/>
					<Paging enabled defaultPageSize={50}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата'}
						dataType={'datetime'}
						format={'dd.MM.yyyy, HH:mm'}
					/>
					<Column dataField={'ID'} caption={'ID'}/>
					<Column dataField={'MerchantID'} caption={'Мерчант'}/>
					<Column dataField={'MerchantApiProfileID'} caption={'ID Профиля'}/>
					<Column
						dataField={'RollingReleaseDate'}
						caption={'Дата возврата'}
						dataType={'date'}
						format={'dd.MM.yyyy'}
					/>
					<Column dataField={'RollingStatusName'} caption={'Статус'}/>
					<Column
						dataField={'Amount'}
						caption={'Сумма'}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column dataField={'CurrencySymbol'} caption={'Валюта'}/>
					<Column
						type={'buttons'}
						caption={'Действия'}
						cellRender={(row) => {
							return <CustomButtonGroupCell
								row={row}
								handleGridColumnButton={setConfirmData}
								isVisibleButton={isVisibleRollingBtn}
								history={history}
								rights={rights}
							/>
						}}
					/>
				</DataGrid>
			</div>
			<div id={'ctx-menu'}>
				<div className={'ctx-menu'}>
					{contextInnerButtons.map((ctxMenuItem) => {
						return (
							<div key={ctxMenuItem.action} id={ctxMenuItem.action} className={'column-operation-button'}>
								{ctxMenuItem.icon ? (
									<i className={ctxMenuItem.icon}></i>
								) : null}
								<span>
									{ctxMenuItem.hint}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default Rolling;