import './polyfills';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';

const APP_HASH = process.env.REACT_APP_HASH;

if (typeof localStorage.APP_HASH === 'undefined' || localStorage.APP_HASH === null) {
	localStorage.setItem('APP_HASH', APP_HASH);
}

if (localStorage.APP_HASH !== APP_HASH) {
	localStorage.clear();
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);