import React from 'react';
import PageMenu from 'components/navigation-menu/page-menu';
import {getDefaultPageRoute, getMenuComponent, menuItems} from '../../app-routes';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

const {PATH_KEY: {CLIENTS}} = vars;

const Clients = (props) => {
	const {rights} = props;
	
	const history = useHistory();
	const location = useLocation();
	let ActiveComponent = null;
	
	const activePageId = location.pathname.split('/')[2];
	
	if (activePageId) {
		ActiveComponent = getMenuComponent(CLIENTS, activePageId);
	} else {
		history.push(getDefaultPageRoute(CLIENTS));
	}
	
	return (
		<div className={'clients-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CLIENTS]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{activePageId ? (
					<ActiveComponent rights={rights}/>
				) : null}
			</div>
		</div>
	);
}

export default Clients;
