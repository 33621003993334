import React, {useState} from 'react';
import {Button, SelectBox, TextArea} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {getDictionaryDataSource} from 'services/dataSource';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

import './income-payout.scss';

const I = {
	STATUS: 'Статус',
	LEVEL: 'Уровень',
	VERIFICATION_COMMENT: 'Комментарий',
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
};

const {APP_CODES: {SUCCESS}} = vars;

const ChangeVerification = ({closePopup, rowData, updateMerchant}) => {
	const [statusList] = useState(getDictionaryDataSource({
		objectName: 'VmVerificationStatus',
	}));
	const [levelList] = useState(
		getDictionaryDataSource({
			objectName: 'VmVerificationLevel',
			withEmptyValue: true,
		}));

	const [status, setStatus] = useState(null);
	const [level, setLevel] = useState(null);
	const [comment, setComment] = useState('');

	const changeVerificationClick = async () => {
		const result = validationEngine.validateGroup('verification-group');

		if (result.isValid) {
			const request = await apiRequest({
				operation: 'Merchant/ChangeVerificationStatus',
				data: {
					Params: {
						MerchantID: rowData.MerchantID,
						VmVerificationStatusID: status,
						VmVerificationLevelID: level > 0 ? level : null,
						Remark: comment,
					}
				},
			});

			const {data: {ResponseCode, ResponseText}} = request;

			if (ResponseCode === SUCCESS) {
				updateMerchant();
				closePopup();
			} else {
				notifyApp(ResponseText);
			}
		}
	};

	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					dataSource={statusList}
					placeholder={I.STATUS}
					valueExpr={'ID'}
					displayExpr={'Name'}
					value={status}
					onValueChanged={({value}) => {
						setStatus(value);
					}}
				>
					<Validator validationGroup={'verification-group'}>
						<RequiredRule message={'Status required'}/>
					</Validator>
				</SelectBox>
			</div>
			<div className={'clients-popup-field'}>
				<SelectBox
					dataSource={levelList}
					placeholder={I.LEVEL}
					valueExpr={'ID'}
					displayExpr={'Name'}
					value={level}
					onValueChanged={({value}) => {
						setLevel(value);
					}}
				/>
			</div>
			<div className={'clients-popup-field'}>
				<TextArea
					value={comment}
					placeholder={I.VERIFICATION_COMMENT}
					onValueChanged={({value}) => {
						setComment(value);
					}}
				>
					<Validator validationGroup={'verification-group'}>
						<RequiredRule message={'Comment required'}/>
					</Validator>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={changeVerificationClick}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
};

export default ChangeVerification;
