import React, {useState} from 'react';
import {Button, TextArea} from 'devextreme-react';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import {notifyApp} from "../../../../utils/notifyWrapper";

const {APP_CODES} = vars;

const ShowComment = (props) => {
	const {actionType, rowData, closePopup, operation = 'Merchant/ModRemark', fieldName = 'MerchantID'} = props;
	const [remark, setRemark] = useState(actionType.remark);

	const createComment = async () => {
		const request = await apiRequest(
			{
				operation: operation,
				data: {
					Params: {
						[fieldName]: rowData.MerchantID,
						Remark: remark,
					}
				},
			}
		);

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			await actionType.updateComments();
			closePopup();
		} else {
			notifyApp(request.data.ResponseText);
		}
	};

	return (
		<div className={'add-comment-wrapper'}>
			<TextArea
				height={120}
				readOnly={actionType.subtype !== 'add-comment'}
				text={remark}
				onValueChanged={({value}) => {
					setRemark(value);
				}}
			/>
			{actionType.subtype === 'add-comment' && (
				<Button id={'add-comment-btn'} text={'Добавить'} onClick={createComment}/>
			)}
		</div>
	);
};

export default ShowComment;