import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow, Item,
	Paging,
	RemoteOperations,
	Scrolling, Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {exportFXCurrencyExchange, getFXPaymentList} from 'services/requestConsts';
import {
	getAppliedFilters,
	getFromLocalStorage,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import Button from 'devextreme-react/button';
import AdminPopup from 'components/popup/admin-popup';
import FXDetail from 'pages/clients/fx/fx-popup/FXDetail';
import classNames from 'classnames/bind';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

import 'pages/clients/transcactions/transactions.scss';

const {TRANSACTION, STATE_STORING_KEYS: {CLIENTS: {TRANSACTIONS_PAGE}}} = vars;

const getTitle = (actionType, {ID}) => {
	const titles = {
		info: `Детальная информация о сделке ID ${ID}`,
		accept: `Подтвердить платеж: ID ${ID}`,
		decline: `Отменить платеж: ID ${ID}`,
		replay: `Проверить статус платежа: ID ${ID}`,
	};

	return titles[actionType];
};


class FXCurrencyExchange extends React.Component {
	constructor(props) {
		super(props);
		const merchantFilterID = getFromSessionStorage('filter', 'merchantFilterID');
		const accountFilterID = getFromSessionStorage('filter', 'accountFilterID');
		const merchantSenderAccountFilter = getFromSessionStorage('filter', 'merchantSenderAccountFilter');
		this.gridRef = React.createRef();

		this.state = {
			payments: [],
			showFilter: !!merchantFilterID,
			rowData: {
				ID: null,
				Amount: null,
				ForeignCurrencyID: null,
			},
			popupFields: null,
			popupActionType: null,
			merchantFilterID: merchantFilterID,
			accountFilterID: accountFilterID,
			merchantSenderAccountFilter: merchantSenderAccountFilter,
			isShowExportDatePopup: false,
		};

		this.filterOptions = {
			BoPaymentTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('BoPaymentTypeID'),
				options: {
					object: 'BoPaymentType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentSystemTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeID'),
				options: {
					object: 'PaymentSystemType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentStatusID'),
				options: {
					object: 'PaymentStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			ReceiveCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('ForeignCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				}
			},
			AccountCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('AccountCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				}
			},
		};
	}

	componentDidMount() {
		this.getFXCurrencyExchangeList();
	}

	getFXCurrencyExchangeList = async () => {
		const paramObj = getFXPaymentList();
		const payments = getDataSource(paramObj);
		window.sessionStorage.removeItem('filter');
		this.setState({
			payments,
		});
	};

	dateColumn(props) {
		return (
			<div>
				<span>
					{props.text}
				</span>
				<br/>
				<span className={'utc'}>
					{props.data.UTC}
				</span>
			</div>
		);
	}

	colorRedClass(id) {
		return classNames({
			'special-payment-type-id': id === TRANSACTION.SPECIAL_BO_PAYMENT_TYPE_ID
		});
	}

	feeColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	displayCurrency(code, symbol) {
		return `${code} ${symbol}`
	}

	statusColumn({value, data}) {
		const {STATUS_COLOR} = TRANSACTION;
		let statusClass;
		switch (data.PaymentStatusID) {
			case 4:
				statusClass = STATUS_COLOR.GREEN;
				break;
			case 5:
			case 6:
				statusClass = STATUS_COLOR.RED;
				break;
			case 1:
				statusClass = STATUS_COLOR.BLACK;
				break;
			default:
				statusClass = STATUS_COLOR.OTHER;
		}
		return <span className={`status-column ${statusClass}`}>{value}</span>;
	}

	handleGridColumnButton = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData: rowData,
			popupActionType: actionType,
		});
	};
	
	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};

	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	saveTransactionsSettings = (settings) => {
		saveGridSettings(settings, TRANSACTIONS_PAGE);
	};

	loadTransactionSettings = () => {
		const {accountFilterID, merchantFilterID, merchantSenderAccountFilter} = this.state;
		return loadGridSettings(accountFilterID ?
				{AccountID: accountFilterID} :
				merchantFilterID ?
					{AccountMerchantID: merchantFilterID} :
					merchantSenderAccountFilter ?
						{MerchantSenderAccount: merchantSenderAccountFilter} :
						null,
			TRANSACTIONS_PAGE
		);
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	inOrderCurrencyCaptions = () => {
		return (
			<div className={'in-order-currency'}>
				<p>Сумма</p>
				<p>Комиссия</p>
				<p>Итого</p>
				<p>Курс</p>
			</div>
		);
	};

	inAccountCurrency = (obj) => {
		const {
			AccountCurrencyCode, Amount, FeeAmount, ClearAmount
		} = obj.data;

		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ClearAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${FeeAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${Amount} ${AccountCurrencyCode}`}</p>
			</div>
		);
	};

	inPaymentCurrency = (obj) => {
		const {
			ForeignCurrencyCode, ForeignAmount, ForeignFeeAmount, ForeignClearAmount, CurrencyRate
		} = obj.data;

		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ForeignClearAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`${ForeignFeeAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`${ForeignAmount} ${ForeignCurrencyCode}`}</p>
				<p>{CurrencyRate}</p>
			</div>
		);
	}
	
	toggleFilter = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	}
	
	clearFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.instance.clearFilter()
		}
	}
	
	exportGrid = () => {
		const {isShowExportDatePopup} = this.state;
		this.setState({isShowExportDatePopup: !isShowExportDatePopup});
	}

	render() {
		const {payments, showFilter, popupFields, popupActionType, rowData, isShowExportDatePopup} = this.state;
		const PopupFields = popupFields;
		const popupWidth = popupActionType === 'info' ? 1200 : 700;
		const popupHeight = popupActionType === 'info' ? '80%' : 'auto';

		return (
			<div className={'page-component-wrapper-custom'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					maxWidth={popupWidth}
					height={popupHeight}
					title={getTitle(popupActionType, rowData)}
				>
					{popupFields ? (
						<PopupFields
							updatePaymentsList={this.getPaymentList}
							popupActionType={popupActionType}
							closePopup={this.closePopup}
							rowData={rowData}
						/>
					) : null}
				</AdminPopup>
				<div className={'grid-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						ref={this.gridRef}
						alignment={'center'}
						dataSource={payments}
						hoverStateEnabled={true}
						showBorders={false}
						focusedRowEnabled={false}
						columnHidingEnabled={true}
						onEditorPreparing={this.onEditorPreparing}
						height={'100%'}
						onContentReady={({component, element}) => {
							getAppliedFilters(component, element);
						}}
						onRowDblClick={({data}) => {
							this.handleGridColumnButton('info', FXDetail, data);
						}}
					>
						<Toolbar>
							<Item location={'before'}>
								<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
								</div>
							</Item>
							<Item location={'before'}>
								<Button icon={'filter'} onClick={this.toggleFilter}/>
							</Item>
							<Item location={'before'}>
								<Button icon={'clearsquare'} onClick={this.clearFilters}/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'xlsxfile'}
									onClick={this.exportGrid}
								/>
							</Item>
						</Toolbar>
						<RemoteOperations
							paging
							filtering
						/>
						<Paging enabled defaultPageSize={50}/>
						<FilterRow visible={showFilter}/>
						<FilterPanel visible={true}/>
						<Scrolling
							mode={'infinite'}
							showScrollbar='onHover'
						/>
						
						<Column
							dataField={'InsDate'}
							dataType={'datetime'}
							caption={exportFXCurrencyExchange.InsDate}
							format={'dd.MM.yyyy, HH:mm'}
							cellRender={this.dateColumn}
							width={130}
							hidingPriority={13}
						/>
						<Column
							dataField={'ID'}
							caption={exportFXCurrencyExchange.ID}
							width={100}
							hidingPriority={12}
						/>
						<Column
							dataField={'PaymentDocumentID'}
							caption={exportFXCurrencyExchange.PaymentDocumentID}
							width={100}
							hidingPriority={11}
						/>
						<Column
							dataField={'AccountMerchantID'}
							caption={exportFXCurrencyExchange.AccountMerchantID}
							width={100}
							hidingPriority={10}
						/>
						<Column
							dataField={'MerchantOrderID'}
							caption={exportFXCurrencyExchange.MerchantOrderID}
							width={100}
							hidingPriority={9}
						/>
						<Column
							dataField={'Actives'}
							caption={exportFXCurrencyExchange.Actives}
							width={95}
							hidingPriority={8}
						/>
						<Column
							dataField={'OriginalCurrencyRate'}
							caption={exportFXCurrencyExchange.OriginalCurrencyRate}
							width={130}
							hidingPriority={7}
						/>
						<Column
							dataField={'CurrencyRate'}
							caption={exportFXCurrencyExchange.CurrencyRate}
							width={130}
							hidingPriority={6}
						/>
						<Column
							dataField={'AmountPayTo'}
							caption={exportFXCurrencyExchange.AmountPayTo}
							width={130}
							hidingPriority={5}
						/>
						<Column
							dataField={'AmountReceivable'}
							caption={exportFXCurrencyExchange.AmountReceivable}
							width={130}
							hidingPriority={4}
						/>
						<Column
							dataField={'Profit'}
							caption={exportFXCurrencyExchange.Profit}
							alignment={'right'}
							width={150}
							hidingPriority={3}
						/>
						<Column
							dataField={'Currency'}
							caption={exportFXCurrencyExchange.Currency}
							width={100}
							hidingPriority={2}
						/>
						<Column
							dataField={'PaymentStatusName'}
							caption={exportFXCurrencyExchange.PaymentStatusName}
							filterOperations={this.filterOptions['PaymentStatusName'].filterOperations}
							calculateFilterExpression={this.filterOptions['PaymentStatusName'].calculateFilterExpression}
							cellRender={this.statusColumn}
							width={150}
							hidingPriority={1}
						/>
						<Column
							dataField={'ServiceName'}
							caption={exportFXCurrencyExchange.ServiceName}
							hidingPriority={0}
						/>
						<Column
							caption={'Действие'}
							type={'buttons'}
							buttons={[
								{
									hint: 'Детали',
									icon: 'mdi mdi-book-open',
									onClick: (e) => {
										this.handleGridColumnButton('info', FXDetail, e.row.data);
									}
								},
							]}
						/>
					</DataGrid>
				</div>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'FXCurrencyExchangeExport'}
					getGridParams={getFXPaymentList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
					exportCaptions={exportFXCurrencyExchange}
				/>
			</div>
		);
	}
}

export default withRouter(FXCurrencyExchange);
