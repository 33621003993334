import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {useHistory} from 'react-router-dom';
import {getDataSource} from 'services/dataSource';
import {getReferrersList} from 'services/requestConsts';
import {fileDateTextFormat, firstDateOfMonth, formatDateToApi, lastDateOfMonth} from 'services/dataSourceFilterUtils';
import {Button, DateBox, NumberBox, Popover} from 'devextreme-react';
import {vars} from 'utils/variables';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';

const {TRANSACTION, REFERRER_DETAIL_PATH} = vars;

const Referrers = () => {
	const history = useHistory();
	const referrerTableRef = useRef(null);
	const [isReadyToExport, setIsReadyToExport] = useState(false);
	const [isShowFilterPopup, setIsShowFilterPopup] = useState(false);
	const [startDate, setStartDate] = useState({
		value: firstDateOfMonth(),
		text: fileDateTextFormat(firstDateOfMonth()),
	});
	const [endDate, setEndDate] = useState({
		value: lastDateOfMonth(),
		text: fileDateTextFormat(lastDateOfMonth()),
	});
	const [showFilter, setShowFilter] = useState(false);
	const [referrerId, setReferrerId] = useState(null);
	const [referrerDs, setReferrerDs] = useState(null);
	
	useEffect(() => {
		if (!referrerDs) {
			getRefs().catch((e) => {
				console.error(e);
			});
		}
	}, [referrerDs]);
	
	const getRefs = async (filter) => {
		const params = getReferrersList(filter);
		const ds = getDataSource(params);
		setReferrerDs(ds);
	}
	
	const filterReferrers = async () => {
		await getRefs({
			merchantId: referrerId,
			startDate: formatDateToApi(startDate.value),
			endDate: formatDateToApi(endDate.value),
		});
	}
	
	const exportReferrers = async () => {
		await filterReferrers();
		setIsReadyToExport(true);
	}
	
	const exportToXlsx = () => {
		if (isReadyToExport) {
			const exportInstance = referrerTableRef.current.instance;
			
			const workbook = new Workbook();
			const worksheet = workbook.addWorksheet('Main');
			
			exportDataGrid({
				component: exportInstance,
				worksheet: worksheet,
				keepColumnWidths: false,
			}).then(function () {
				workbook.xlsx.writeBuffer()
					.then(function (buffer) {
						const fileName = onExporting();
						saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${fileName}.xlsx`);
						closeExportPopup();
						setReferrerDs(null);
					});
			})
			
			setIsReadyToExport(false);
		}
	}
	
	const onExporting = () => {
		let dateStr = startDate.text;
		
		if (startDate.text !== endDate.text) {
			dateStr = startDate.text + '-' + endDate.text;
		}
		
		return `Referrers(${dateStr})`;
	}
	
	const closeExportPopup = () => {
		setIsShowFilterPopup(false);
	}
	
	const exportGrid = () => {
		setIsShowFilterPopup(!isShowFilterPopup);
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (referrerTableRef.current) {
			referrerTableRef.current.instance.clearFilter();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={referrerTableRef}
					dataSource={referrerDs}
					id={'grid-acc-statement'}
					alignment={'center'}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onExporting={onExporting}
					onContentReady={exportToXlsx}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								icon={'xlsxfile'}
								onClick={exportGrid}
							/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Column caption={'ID клиента'} dataField={'MerchantID'}/>
					<Column caption={'Имя'} dataField={'Name'}/>
					<Column caption={'Кол-во за все время'} dataField={'AllReferralCount'}/>
					<Column caption={'Оборот за все время'} dataField={'AllReferallIncome'}/>
					<Column caption={'Кол-во за период'} dataField={'PeriodReferralCount'}/>
					<Column caption={'Оборот за период'} dataField={'PeriodReferralIncome'}/>
					<Column
						caption={'Быстрый просмотр'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Просмотреть',
								text: 'Просмотреть',
								onClick: ({row}) => {
									history.push({
										pathname: REFERRER_DETAIL_PATH,
										state: {referrerUserID: row.data.ID},
									});
								},
							},
						]}
					/>
				</DataGrid>
			</div>
			
			<div id={'filter-wrapper'}>
				<Popover
					target={'.dx-toolbar-after'}
					visible={isShowFilterPopup}
					onHiding={closeExportPopup}
				>
					<div className={'export-fields'}>
						<div className="dx-field">
							<div className="dx-field-label">ID Клиента</div>
							<div className="dx-field-value">
								<NumberBox
									value={referrerId}
									onValueChanged={({value}) => {
										setReferrerId(value);
									}}
								/>
							</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">Начало периода</div>
							<div className="dx-field-value">
								<DateBox
									value={startDate.value}
									max={endDate.value}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setStartDate({
											value: value,
											text: fileDateTextFormat(value),
										});
									}}
								/>
							</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">Окончание периода</div>
							<div className="dx-field-value">
								<DateBox
									value={endDate.value}
									min={startDate.value}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setEndDate({
											value: value,
											text: fileDateTextFormat(value),
										});
									}}
								/>
							</div>
						</div>
						<div className={'popover-buttons'}>
							<Button text={'Фильтровать'} onClick={filterReferrers}/>
							<Button text={'Экспорт'} onClick={exportReferrers}/>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	);
}

export default Referrers;