import React from "react";
import {Popup, TextArea, Button, Validator, SelectBox} from "devextreme-react";
import {RequiredRule} from "devextreme-react/validator";
import {apiRequest} from '../../../../../services/async';
import './restrict-popup.scss';
import {vars} from "../../../../../utils/variables";
import {notifyApp} from "../../../../../utils/notifyWrapper";

const {APP_CODES: {SUCCESS}, MERCHANT: {ARREST_ACCOUNT_OBJECT}, NOTIFY} = vars;

const I = {
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
	FIELD_REQUIRED: 'Поле обязательное',
};

const initialState = {
	remark: null,
	reason: null,
};

export default class RestrictPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.validatorRef = React.createRef();
	}

	changeRemarkValue = ({value}) => {
		this.setState({
			remark: value
		});
	};

	cancelAccountRequest = async () => {
		const {remark, reason} = this.state;
		const {confirmOperation, selectedAccounts, updateCallback, closePopup} = this.props;
		let accountCodes = [];

		const requests = selectedAccounts.map((account) => {
			accountCodes.push(account.Code);
			const payload = {
				operation: confirmOperation,
				data: {
					Params: {
						WallettoCardFullBlockReasonID: reason,
						AccountID: account.ID,
						Remark: remark
					},
				}
			};
			return new Promise((resolve) => {
				resolve(apiRequest(payload));
			});
		});

		const responseArray = await Promise.all(requests);

		let errorText = '';
		responseArray.forEach((response, key) => {
			if (response.data.ResponseCode !== SUCCESS) {
				errorText += `${accountCodes[key]} - ${response.data.ResponseText}\n`;
			}
		});

		if (errorText) {
			notifyApp(errorText, NOTIFY.ERROR, true)
		} else {
			updateCallback();
			closePopup();
		}
	};

	confirmRequest = async () => {
		const {remark} = this.state;
		const {confirmOperation, rowData, selectedAccounts, updateCallback, closePopup} = this.props;

		let payload = {
			Params: {
				MerchantID: rowData.MerchantID,
				Remark: remark
			}
		};

		if (confirmOperation.split('/')[0] === ARREST_ACCOUNT_OBJECT) {
			const account = selectedAccounts.map(account => {
				return {ID: account.ID};
			});

			payload = {
				Request: {
					Account: account,
					MerchantID: rowData.MerchantID,
					Remark: remark
				}
			};
		}

		const request = await apiRequest({
			operation: confirmOperation,
			data: payload,
		});

		if (request.data.ResponseCode === SUCCESS) {
			updateCallback();
			closePopup();
			notifyApp('Операция успешна', NOTIFY.SUCCESS);
		} else {
			notifyApp(request.data.ResponseText);
		}
	};

	confirmOperation = async (args) => {
		if (!args.validationGroup.validate().isValid) {
			return;
		}

		const {isCancelAccount} = this.props;

		if(isCancelAccount) {
			this.cancelAccountRequest();
		} else {
			this.confirmRequest();
		}
	};

	clearValidator = () => {
		this.validator.reset();
	};

	clearState = () => {
		this.setState(initialState, this.clearValidator);
	};

	get validator() {
		return this.validatorRef.current.instance;
	}

	render() {
		const {remark, reason} = this.state;
		const {confirmOperation, closePopup, remarkText, isCancelAccount, reasonsList} = this.props;

		return (
			<Popup
				visible={!!confirmOperation}
				onHiding={closePopup}
				onHidden={this.clearState}
				width={500}
				height={'auto'}
				title={'Подтверждение/отмена действий по ограничению  возможностей мерчантов'}
				showCloseButton={true}
			>
				{remarkText ? (
					<div className={'remark-text'}>
						<div className="dx-fieldset">
							<div className="dx-fieldset-header">Комментарий:</div>
							<TextArea
								value={remarkText}
								readOnly={true}
							>
								<Validator ref={this.validatorRef}>
									<RequiredRule message={I.FIELD_REQUIRED}/>
								</Validator>
							</TextArea>
						</div>
					</div>
				) : (
					<div className={'action-wrapper'}>
						<div className={'clients-popup-field'}>
							<TextArea
								value={remark}
								placeholder={'Комментарий'}
								onValueChanged={this.changeRemarkValue}
							>
								<Validator ref={this.validatorRef}>
									<RequiredRule message={I.FIELD_REQUIRED}/>
								</Validator>
							</TextArea>
						</div>
						{isCancelAccount && (
							<div className={'clients-popup-field'}>
								<SelectBox
									dataSource={reasonsList}
									displayExpr={'Name'}
									valueExpr={'ID'}
									value={reason}
									onValueChanged={({value}) => {
										this.setState({
											reason: value
										});
									}}
								>
									<Validator>
										<RequiredRule message={I.FIELD_REQUIRED}/>
									</Validator>
								</SelectBox>
							</div>
						)}
						<div className={'action-buttons'}>
							<Button
								className={'action-button'}
								onClick={(e) => {
									this.confirmOperation(e).catch((e) => {
										console.warn(e);
									});
								}}
								text={I.ACCEPT_BUTTON}
							/>
							<Button
								className={'action-button'}
								onClick={closePopup}
								text={I.CANCEL_BUTTON}
							/>
						</div>
					</div>
				)}
			</Popup>
		);
	}
}
