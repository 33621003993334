import React from 'react';
import {apiRequest, updateSelectedMerchantDetails} from 'services/async';
import DataGrid, {
	Column,
	Selection,
	Button as GridButton,
} from 'devextreme-react/data-grid';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import {Template} from 'devextreme-react/core/template';
import Button from 'devextreme-react/button';
import RestrictPopup from './restrict/restrict-popup';

import './restriction-form.scss';
import {getFromLocalStorage} from '../../../../utils/functions';

const {
	APP_CODES: {SUCCESS},
	WALLETTO_ACCOUNT_ID,
	MERCHANT: {RIGHTS, ACCOUNT_STATUS, COMMON_RESTRICTION_OBJECT, CANCEL_ACCOUNT_OPERATION}
} = vars;

const haveAnyOf = (selectedItems) => {
	const statuses = selectedItems.map(item => item.IsArrest);
	const uniqueStatuses = [...new Set(statuses)];
	return {
		haveArrested: uniqueStatuses.includes(ACCOUNT_STATUS.IS_ARRESTED),
		haveActive: uniqueStatuses.includes(ACCOUNT_STATUS.IS_ACTIVE),
	};
};

const checkWallettoAccounts = (selectedAccounts) => {
	const wallettoAccounts = selectedAccounts.filter((account) => {
		return account.PaymentSystemTypeID === WALLETTO_ACCOUNT_ID;
	});
	return wallettoAccounts.length === selectedAccounts.length;
};

export default class RestrictionForm extends React.Component {
	constructor(props) {
		super(props);
		console.log('propsprops')
		console.log(props)
		const {rowData} = props;

		this.state = {
			confirmOperation: '',
			accounts: [],
			selectedAccounts: [],
			restrictionHistory: [],
			reasonsList: [],
			remarkText: null,
			isCancelAccountOperation: false,
			userIsBan: !!rowData.UserIsBan,
			isRestrictAddAccount: !!rowData.IsRestrictAddAccount,
			isRestrictAddExternalAccount: !!rowData.IsRestrictAddExternalAccount,
		};
	}

	componentDidMount() {
		this.updateRestrictionState().catch((e) => {
			console.error(e);
		})
	}

	updateRestrictionState = async (merchant) => {
		await this.getAccounts();
		await this.fetchReasonsList();
		if (merchant) {
			await this.updateBanStatus(merchant);
		}
	};

	updateBanStatus = (merchant) => {
		this.setState({
			userIsBan: !!merchant.UserIsBan,
			isRestrictAddAccount: !!merchant.IsRestrictAddAccount,
			isRestrictAddExternalAccount: !!merchant.IsRestrictAddExternalAccount,
		});
	};

	getAccounts = async () => {
		const {rowData: {MerchantID}} = this.props;
		const {confirmOperation} = this.state;
		const needCallback = confirmOperation.split('/')[0] === COMMON_RESTRICTION_OBJECT;
		let accounts = [];
		let restrictHistory = [];

		const requestAccount = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: MerchantID
				}
			}
		});

		const requestRestrictHistory = await apiRequest({
			operation: 'BoMerchantHistory/List',
			data: {
				Sorts: ['-InsDate'],
				Filters: {
					MerchantID: MerchantID
				}
			}
		});

		if (requestRestrictHistory.data.ResponseCode === SUCCESS) {
			restrictHistory = requestRestrictHistory.data.Response.BoMerchantHistory;
		} else {
			notifyApp(requestRestrictHistory.data.ResponseText);
		}

		if (requestAccount.data.ResponseCode === SUCCESS) {
			accounts = requestAccount.data.Response.Account;
		} else {
			notifyApp(requestAccount.data.ResponseText);
		}

		this.setState({
			accounts,
			restrictHistory,
		}, needCallback ? this.updateCommonRestrictionBlock(MerchantID) : null);
	};

	fetchReasonsList = async () => {
		const reason = await apiRequest({
			operation: 'WallettoCardFullBlockReason/List',
		});

		if (reason.data.ResponseCode === SUCCESS) {
			this.setState({
				reasonsList: reason.data.Response.WallettoCardFullBlockReason
			});
		} else {
			notifyApp(reason.data.ResponseText);
		}
	};
	
	localUpdateSelectedMerchant = async (id) => {
		const {rights} = this.props;
		let merchant = null;
		
		try {
			merchant = await updateSelectedMerchantDetails(id);
			if (merchant) {
				merchant.UserRights = rights;
			}
			return  merchant;
		} catch (e) {
			throw new Error(e);
		}
	};

	updateCommonRestrictionBlock = (id) => {
		const {updateSelectedMerchant} = this.props;
		const updateCallback = updateSelectedMerchant ? updateSelectedMerchant : this.localUpdateSelectedMerchant;
		
		updateCallback(id).then((merchant) => {
			this.updateRestrictionState(merchant).catch((e) => {
				console.error(e);
			});
		}).catch(e => {
			notifyApp(e);
		});
	};

	arrestAccount = () => {
		this.setState({
			confirmOperation: 'Account/Arrest',
			isCancelAccountOperation: false,
		});
	};

	removeArrestFromAccount = () => {
		this.setState({
			confirmOperation: 'Account/Unarrest',
			isCancelAccountOperation: false,
		});
	};

	cancelAccount = () => {
		this.setState({
			confirmOperation: CANCEL_ACCOUNT_OPERATION,
			isCancelAccountOperation: true,
		});
	};

	toolbarItemRender = () => {
		const {rowData: {UserRights}} = this.props;
		const {selectedAccounts} = this.state;
		const isSelected = selectedAccounts.length > 0;
		const haveArrestRight = UserRights.includes(RIGHTS.ARREST);
		const haveCancelRight = UserRights.includes(RIGHTS.CANCEL_ACCOUNT);
		const haveActiveRight = UserRights.includes(RIGHTS.REMOVE_ARREST);
		const accounts = haveAnyOf(selectedAccounts);
		const isAllWallettoAccounts = checkWallettoAccounts(selectedAccounts);

		const isArrestAvailable = isSelected && haveArrestRight && accounts.haveActive;
		const isCancelAvailable = isSelected && haveCancelRight && isAllWallettoAccounts;
		const isActiveAvailable = isSelected && haveActiveRight && accounts.haveArrested;

		return (
			<div className={'restr'}>
				<Button
					text={'Арест'}
					disabled={!isArrestAvailable}
					onClick={this.arrestAccount}
				/>
				<Button
					text={'Снять арест'}
					disabled={!isActiveAvailable}
					onClick={this.removeArrestFromAccount}
				/>
				<Button
					text={'Аннулировать'}
					disabled={!isCancelAvailable}
					onClick={this.cancelAccount}
				/>
			</div>
		);
	};

	onToolbarPreparing(e) {
		const {toolbarOptions} = e;
		const {items} = toolbarOptions;
		toolbarOptions.renderAs = 'bottomToolbar';
		items.unshift(
			{
				location: 'before',
				template: 'customToolbar1'
			},
		);
	}

	restrictButton = ({data}) => {
		const {rowData: {UserRights}} = this.props;
		const {userIsBan, isRestrictAddAccount, isRestrictAddExternalAccount,} = this.state;
		let buttonProps;

		switch (data.ID) {
			case 0:
				if (userIsBan) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения userIsBan');
							this.setState({
								confirmOperation: 'Merchant/UnrestrictUser',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить userIsBan');
							this.setState({
								confirmOperation: 'Merchant/RestrictUser',
							});
						}
					};
				}
				break;
			case 2:
				if (isRestrictAddExternalAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddExternalAccount');
							this.setState({
								confirmOperation: 'Merchant/UnrestrictAddExternalAccount',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							this.setState({
								confirmOperation: 'Merchant/RestrictAddExternalAccount',
							});
						}
					};
				}
				break;
			case 1:
			default:
				if (isRestrictAddAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddAccount');
							this.setState({
								confirmOperation: 'Merchant/UnrestrictAddAccount',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							this.setState({
								confirmOperation: 'Merchant/RestrictAddAccount',
							});
						}
					};
				}
		}

		return (
			<Button
				{...buttonProps}
			/>
		);
	};

	getArrestStatus = ({value}) => {
		let status;
		switch (value) {
			case ACCOUNT_STATUS.IS_ARRESTED:
				status = 'арестован';
				break;
			case ACCOUNT_STATUS.IS_ACTIVE:
				status = 'активный';
				break;
			default:
				status = 'неизвестно'
		}
		return (
			<span>{status}</span>
		);
	};

	accountSelectedChange = ({selectedRowKeys}) => {
		this.setState({
			selectedAccounts: selectedRowKeys
		});
	};

	showAllRemarkOnDblClick = ({data}) => {
		this.setState({
			confirmOperation: 'showAllRemarkOnDblClick',
			remarkText: data.Remark,
		});
	};

	showAllRemark = ({row}) => {
		this.setState({
			confirmOperation: 'showAllRemark',
			remarkText: row.data.Remark,
		});
	};

	closeRestrictPopup = () => {
		this.setState({
			confirmOperation: '',
			remarkText: null,
		});
	};

	render() {
		const {rowData} = this.props;
		const {
			accounts,
			selectedAccounts,
			restrictHistory,
			confirmOperation,
			remarkText,
			isCancelAccountOperation,
			reasonsList,
			userIsBan,
			isRestrictAddAccount,
			isRestrictAddExternalAccount,
		} = this.state;

		return (
			<div className={'restriction-field-wrap'}>
				<RestrictPopup
					rowData={rowData}
					confirmOperation={confirmOperation}
					selectedAccounts={selectedAccounts}
					remarkText={remarkText}
					isCancelAccount={isCancelAccountOperation}
					reasonsList={reasonsList}
					updateCallback={this.getAccounts}
					closePopup={this.closeRestrictPopup}
				/>
				<div className={'dx-fieldset restriction-fieldset'}>
					<span className={'dx-fieldset-header'}>Общие ограничения мерчанта</span>
					<DataGrid
						dataSource={[
							{
								ID: 0,
								Type: 'Ограничить работу пользователя (всех его мерчантов)',
								Status: userIsBan ? 'Ограничен' : 'Нет ограничений',
							},
							{
								ID: 1,
								Type: 'Ограничить  мерчанту добавление новых счетов',
								Status: isRestrictAddAccount ? 'Ограничен' : 'Нет ограничений',
							},
							{
								ID: 2,
								Type: 'Ограничить  мерчанту добавление внешних счетов',
								Status: isRestrictAddExternalAccount ? 'Ограничен' : 'Нет ограничений',
							},
						]}
					>
						<Column
							dataField={'ID'}
							caption={'№пп'}
							width={50}
						/>
						<Column
							dataField={'Type'}
							caption={'Тип ограничения (операция)'}
						/>
						<Column
							dataField={'Status'}
							caption={'Текущий статус ограничения'}
						/>
						<Column type={'buttons'}>
							<GridButton
								render={this.restrictButton}
							/>
						</Column>
					</DataGrid>
				</div>
				<div className={'dx-fieldset restriction-fieldset'}>
					<span className={'dx-fieldset-header'}>Арест счетов мерчанта</span>
					<DataGrid
						dataSource={accounts}
						onToolbarPreparing={this.onToolbarPreparing}
						noDataText={'Нет счетов'}
						onSelectionChanged={this.accountSelectedChange}
					>
						<Selection
							mode="multiple"
							selectAllMode={'allPages'}
							showCheckBoxesMode={'onClick'}
						/>

						<Column
							dataField="ID"
							caption="№пп"
							width={50}
						/>
						<Column
							dataField="PaymentSystemTypeName"
							caption="Платежная система"
						/>
						<Column
							dataField="PaymentSystemTypeInternalName"
							caption="Внутреннее название ПС"
							width={165}
						/>
						<Column
							dataField="Code"
							caption="Внутренний счет"
						/>
						<Column
							dataField="ExternalAccountID"
							caption="Внешний счет"
						/>
						<Column
							dataField="Name"
							caption="Карта"
						/>
						<Column
							dataField="CurrencyCode"
							caption="Валюта"
						/>
						<Column
							dataField="IsArrest"
							caption="Текущий статус"
							cellRender={this.getArrestStatus}/>
						<Template name={'customToolbar1'} render={this.toolbarItemRender}/>
					</DataGrid>
				</div>
				<div className={'dx-fieldset'}>
					<span className={'dx-fieldset-header'}>
						История действий по ограничениям мерчанта
					</span>
					{rowData.UserRights.includes(RIGHTS.RESTRICTION_HISTORY) ? (
						<DataGrid
							dataSource={restrictHistory}
							onRowDblClick={this.showAllRemarkOnDblClick}
						>
							<Column
								dataField={'InsDate'}
								caption={'Дата ограничения'}
							/>
							<Column
								dataField={'MerchantName'}
								caption={'Мерчант'}
							/>
							<Column
								dataField={'BoMerchantHistoryTypeName'}
								caption={'Тип ограничения'}
							/>
							<Column
								dataField={'BoUserName'}
								caption={'Пользователь Back Office'}
							/>
							<Column
								dataField={'AccountCode'}
								caption={'Название счета'}
							/>
							<Column
								dataField={'Remark'}
								caption={'Комментарий'}
							/>
							<Column
								type={'buttons'}
								buttons={[
									{
										hint: 'Подробнее',
										text: 'Подробнее',
										onClick: this.showAllRemark
									},
								]}
							/>
						</DataGrid>
					) : (
						<span>Нет прав для просмотра истории действий по ограничениям мерчанта</span>
					)}
				</div>
			</div>
		);
	}
}
