import axios from 'axios';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {prepareRequestOptions, saveEditDataToApi} from './async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import {getFilter} from './dataSourceFilterUtils';

const {APP_CODES: {SUCCESS}} = vars;

/**
 * @param {string} key
 * @param {string[]} columns
 * @param {string} object
 * @param {object} defFilter
 * @param {object} [fieldsSettings]
 * @param {string} sorts
 * @param {function} modify
 * @return {DataSource}
 */
export const getDataSource = ({
																key,
																columns = undefined,
																object,
																defFilter = {},
																fieldsSettings,
																sorts = '-ID',
																modifier = null,
																insertKeys = [],
															}) => {
	const dataSource = new DataSource(
		{
			key,
			load: async ({isLoadingAll, take, skip, filter}) => {
				let page = (skip + take) / take || -1;
				
				let apiFilter = fieldsSettings ? getFilter(filter, fieldsSettings) || {} : {};
				
				let requestObj = {
					Filters: {
						...defFilter,
						...apiFilter,
					},
					Sorts: [sorts],
					Page: isLoadingAll ? -1 : page,
					RowsPerPage: take,
					Columns: columns,
				};
				
				if (!Object.keys(requestObj.Filters).length) {
					delete requestObj.Filters;
				}
				
				const response = await axios(prepareRequestOptions({
					operation: `${object}/List`,
					data: requestObj,
				}));
				
				if (response.data.ResponseCode === SUCCESS) {
					const result = response.data.Response[object];
					return modifier ? modifier(result) : result;
				}
				
				const {NOTIFY} = vars;
				notifyApp(response.data.ResponseText, NOTIFY.ERROR);
				return [];
			},
			update: async (key, newData) => {
				if (insertKeys.length > 0) {
					const oldData = dataSource.items().find((item) => {
						return item.ID === key;
					});
					
					if (oldData) {
						const paramsData = {};
						const data = {...oldData, ...newData};
						
						insertKeys.forEach((key) => {
							paramsData[key] = data[key];
						});
						
						paramsData.ID = data.ID;
						
						try {
							await saveEditDataToApi(`${object}/Mod`, paramsData);
						} catch (error) {
							notifyApp(error);
						}
					}
				}
			},
			insert: async (data) => {
				if (insertKeys.length > 0 && Object.keys(data).length > 0) {
					const paramsData = {};
					
					insertKeys.forEach((key) => {
						if (data.hasOwnProperty(key)) {
							paramsData[key] = data[key];
						} else {
							paramsData[key] = null;
						}
					});
					
					try {
						return await saveEditDataToApi(`${object}/Ins`, paramsData);
					} catch (error) {
						notifyApp(error);
					}
				}
			},
			remove: async (key) => {
				const request = await axios(prepareRequestOptions({
					operation: `${object}/Del`,
					data: {
						Params: {
							ID: key,
						},
					},
				}));
				
				if (request.data.ResponseCode === SUCCESS) {
					return request.data.ResponseCode;
				} else {
					throw new Error(request.data.ResponseText);
				}
			},
		},
	);
	
	return dataSource;
};

export const getLookupDataSource = (objectName, columnName, displayExpr = 'Name', valueExpr = 'ID') => {
	return {
		store: new CustomStore({
			key: 'ID',
			loadMode: 'raw',
			load: async () => {
				let response = [];
				
				const request = await axios(prepareRequestOptions({
					operation: `${objectName}/List`,
					data: {
						Page: -1,
					},
				}));
				
				if (request.data.ResponseCode === SUCCESS) {
					const response = request.data.Response[objectName];
					response.forEach((item) => {
						item.LookupName = item[displayExpr];
						item.LookupId = item[valueExpr];
						if (columnName) {
							item[columnName] = item[displayExpr];
						}
					});
					return response;
				}
				
				return response;
			},
		}),
	}
};

export const getDictionaryDataSource = ({objectName, withEmptyValue = false, names, filter}) => {
	return {
		store: new CustomStore({
			key: 'ID',
			loadMode: 'raw',
			load: async () => {
				let response = [];
				
				if (withEmptyValue) {
					if (Array.isArray(names)) {
						response.push({[names[0]]: null, [names[1]]: ''});
					} else {
						response.push({ID: null, Name: ''});
					}
				}
				
				const data = {
					Page: -1,
				};
				
				if (filter) {
					data.Filters = filter;
				}
				
				const request = await axios(prepareRequestOptions({
					operation: `${objectName}/List`,
					data: data,
				}));
				
				if (request.data.ResponseCode === SUCCESS) {
					return [
						...response,
						...request.data.Response[objectName],
					];
				}
				
				return response;
			},
		}),
	}
};

export const getDictionaryDataSourceNew = ({objectName, withEmptyValue = false, emptyFieldParams, sorts}) => {
	return {
		store: new CustomStore({
			key: 'ID',
			loadMode: 'raw',
			load: async () => {
				let response = [];
				
				if (withEmptyValue) {
					if (Array.isArray(emptyFieldParams)) {
						response.push({[emptyFieldParams[0]]: null, [emptyFieldParams[1]]: ''});
					} else {
						response.push({ID: null, Name: ''});
					}
				}
				
				const data = {
					Page: -1,
				};
				
				if (sorts) {
					data.Sorts = sorts;
				}
				
				const request = await axios(prepareRequestOptions({
					operation: `${objectName}/List`,
					data: data,
				}));
				
				if (request.data.ResponseCode === SUCCESS) {
					return [
						...response,
						...request.data.Response[objectName],
					];
				}
				
				return response;
			},
		}),
	}
};
