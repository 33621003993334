import React from 'react';
import Button from 'devextreme-react/button';
import {NavLink, useLocation} from 'react-router-dom';
import UserPanel from '../user-panel/user-panel';
import './header.scss';

const Header = ({userMenuItems, allowedRoutes}) => {
  const location = useLocation();
  const activePageId = location.pathname.split('/')[1];
  
  return (
    <header className={'header-component'}>
      <div className={'header-menu-wrapper'}>
        <div className={'box left-content'}>
          <div className={'top-menu-wrapper'}>
            {allowedRoutes.map((props) => {
              if (props.hidden) return;
              
              return (
                <NavLink
                  key={props.id}
                  id={props.id}
                  activeClassName={props.id === activePageId ? 'selected' : ''}
                  to={props.url}
                >
                  {props.title}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className={'box center-content'}>
        
        </div>
        
        <div className={'box right-content'}>
          <Button
            className={'user-button authorization'}
            focusStateEnabled={false}
            activeStateEnabled={false}
            hoverStateEnabled={false}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuItems={userMenuItems} menuMode={'context'}/>
          </Button>
        </div>
      </div>
    </header>
  );
  
}

export default Header;
