import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Button as GridButton,
	Column,
	Editing,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {vars} from 'utils/variables';
import {getDataSource} from 'services/dataSource';
import {getConfigurations} from 'services/requestConsts';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import classNames from 'classnames';

const {APP_CODES: {SUCCESS}, TRANSACTION} = vars;

const SystemSettings = ({rights}) => {
	const systemSettingsRef = useRef(null);
	const [settingsDs, setSettingsDs] = useState([]);
	const haveEditRight = rights.includes('44');
	const [isShowFilterRow, setIsShowFilterRow] = useState(false);
	
	const updateRow = (event) => {
		const {oldData, newData} = event;
		if (newData.Value === '***') {
			event.cancel = true;
		} else {
			const isCanceled = new Promise((resolve, reject) => {
				saveToApi(oldData, newData).then(() => {
					resolve(false);
				}).catch((error) => {
					reject(error);
				});
			});
			
			event.cancel = isCanceled;
		}
	}
	
	const saveToApi = async (oldData, newData) => {
		const request = await apiRequest({
			operation: 'Configurations/ModValue',
			data: {
				Params: {ID: oldData.ID, Value: newData.Value},
			},
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			return request.data.ResponseCode;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}
	
	const showHiddenValue = async ({component, row: {data: {ID}, rowIndex}}) => {
		const request = await apiRequest({
			operation: 'Configurations/GetValue',
			data: {
				Params: {ID: ID},
			},
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			const {Value} = request.data.Response;
			component.instance().cellValue(rowIndex, 'Value', Value);
			component.instance().saveEditData();
		} else {
			notifyApp(request.data.ResponseText);
		}
	}
	
	useEffect(() => {
		const ds = getDataSource(getConfigurations());
		setSettingsDs(ds);
	}, []);
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					id={'grid-acc-statement'}
					dataSource={settingsDs}
					onRowUpdating={updateRow}
					repaintChangesOnly={true}
					ref={systemSettingsRef}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
								<span className={'recent-operation-text'}>{TRANSACTION.TEXT.FILTERS}</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button
								icon={'filter'}
								onClick={() => {
									setIsShowFilterRow(!isShowFilterRow);
								}}/>
						</Item>
						<Item location={'before'}>
							<Button
								icon={'clearsquare'}
								onClick={() => {
									if (systemSettingsRef.current) {
										systemSettingsRef.current.instance.clearFilter();
									}
								}}/>
						</Item>
					</Toolbar>
					<Editing
						allowUpdating={true}
						mode={'row'}
						refreshMode={'repaint'}
					/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={isShowFilterRow}/>
					<RemoteOperations
						paging
					/>
					<Column dataField={'OrderNum'} caption={'№ п/п'} allowEditing={false} width={140}/>
					<Column dataField={'Description'} caption={'Описание'} allowEditing={false}/>
					<Column dataField={'ID'} caption={'Параметр'} allowEditing={false}/>
					<Column dataField={'Value'} caption={'Значение'}/>
					<Column type={'buttons'}>
						<GridButton
							name={'show'}
							icon={'mdi mdi-eye-outline'}
							onClick={showHiddenValue}
							visible={({row: {data: {IsMask}}}) => {
								return IsMask && rights.includes('46');
							}}
						/>
						{haveEditRight ? (
							<GridButton name={'edit'}/>
						) : null}
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default SystemSettings;