import React, {useEffect, useState} from 'react';
import {Button, SelectBox, TextArea} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}, NOTIFY} = vars;

const I = {
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
};

const CloseCard = ({selectedCards, closePopup}) => {
	const [remark, setRemark] = useState('');
	const [reasons, setReasons] = useState();
	const [selectedReason, setSelectedReason] = useState(null);
	
	useEffect(() => {
		fetchReasons().then((response) => {
			setReasons(response);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	const fetchReasons = async () => {
		const objectName = 'WallettoCardFullBlockReason';
		const request = await apiRequest({
			operation: `${objectName}/List`,
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			return request.data.Response[objectName];
		} else {
			throw new Error(request.data.ResponseText);
		}
	}
	
	const closeCard = async ({component}) => {
		const result = validationEngine.validateGroup('close-card-group');
		
		if (result.isValid) {
			component.option('disabled', true);
			
			try {
				let errors = [];
				for (let card of selectedCards) {
					try {
						const response = await apiRequest({
							operation: 'WallettoCard/FullBlock',
							data: {
								Params: {
									WallettoCardID: card.ID,
									WallettoCardFullBlockReasonID: selectedReason,
									Remark: remark,
								},
							},
						});
						
						if (response.data.ResponseCode !== SUCCESS) {
							errors.push(`Карта (${card.CardNumber}) - ` + response.data.ResponseText + '\n');
						}
					} catch (error) {
						notifyApp(error);
					}
				}
				
				if (errors.length > 0) {
					notifyApp(errors.join(''), NOTIFY.ERROR, true);
				}
			} finally {
				component.option('disabled', false);
			}
		}
	}
	
	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={reasons}
					valueExpr={'ID'}
					displayExpr={'Name'}
					value={selectedReason}
					onValueChanged={({value}) => {
						setSelectedReason(value);
					}}
				>
					<Validator validationGroup={'close-card-group'}>
						<RequiredRule message={'Reason required'}/>
					</Validator>
				</SelectBox>
			</div>
			<div className={'clients-popup-field'}>
				<TextArea
					value={remark}
					onValueChanged={({value}) => {
						setRemark(value);
					}}
				>
					<Validator validationGroup={'close-card-group'}>
						<RequiredRule message={'Remark required'}/>
					</Validator>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={closeCard}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
}

export default CloseCard;