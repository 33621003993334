import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format, Item,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring, Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getAccountsList, getMerchantsList} from 'services/requestConsts';
import AdminPopup from 'components/popup/admin-popup';
import AccountDetails from './accountsPopupFields/accounts-details';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {
	getAppliedFilters,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import {vars} from 'utils/variables';
import Button from 'devextreme-react/button';

const {TRANSACTION, STATE_STORING_KEYS: {CLIENTS: {ACCOUNTS_PAGE}}, PRECISION} = vars;

const getTitle = (actionType, {
	ID = null,
	MerchantID = null,
	Name = null,
	MerchantName = null,
	Code,
	UserName = 'No response',
}) => {
	const titles = {
		open: `ID: ${ID}, Счёт: ${Code}`,
		restriction: `Ограничения возможностей мерчантов ${MerchantID} ${Name}`,
		income: 'Техническое пополнение',
		payout: 'Техническое списание',
		atm: 'Снятие в АТМ',
		contactless: 'Бесконтактная оплата',
		magnetStripe: 'Магнитная полоса',
		ecommerce: 'Интернет операции',
	};
	
	return titles[actionType];
};

export default class Accounts extends React.Component {
	constructor(props) {
		super(props);
		const merchantFilterID = getFromSessionStorage('filter', 'merchantFilterID');
		window.sessionStorage.removeItem('filter');
		this.gridRef = React.createRef();
		
		this.state = {
			accounts: [],
			showFilter: !!merchantFilterID,
			popupFields: null,
			rowData: {
				ID: null,
				Name: null,
				UserName: null,
			},
			actionType: null,
			merchantFilterID: merchantFilterID,
			isShowExportDatePopup: false,
		};
		
		this.filterOptions = {
			UserTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserTypeID'),
				options: {
					object: 'UserType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			UserStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserStatusID'),
				options: {
					object: 'UserStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
		}
	}
	
	componentDidMount() {
		this.getAccountsList().catch((e) => {
			console.warn(e);
		});
	}
	
	getMerchantList = async () => {
		const merchants = getDataSource(getMerchantsList());
		await merchants.load();
		this.setState({merchants: merchants});
	};
	
	getAccountsList = async () => {
		const accounts = getDataSource(getAccountsList());
		this.setState({accounts: accounts});
	};
	
	gridButtonHandler = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData,
			actionType,
		});
	};
	
	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};
	
	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};
	
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	};
	
	saveAccountsSettings = (settings) => {
		saveGridSettings(settings, ACCOUNTS_PAGE);
	};
	
	loadAccountsSettings = () => {
		const {merchantFilterID} = this.state;
		return loadGridSettings(merchantFilterID ? {MerchantID: merchantFilterID} : null, ACCOUNTS_PAGE);
	};
	
	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}
	
	toggleFilter = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	}
	
	clearFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.instance.clearFilter()
		}
	}
	
	exportGrid = () => {
		const {isShowExportDatePopup} = this.state;
		this.setState({isShowExportDatePopup: !isShowExportDatePopup});
	}
	
	render() {
		const {rights} = this.props;
		const {accounts, showFilter, popupFields, rowData, actionType, isShowExportDatePopup} = this.state;
		const PopupFields = popupFields;
		const maxPopupWidth = actionType === 'restriction' ? 1200 : 800;
		
		return (
			<div className={'page-component-wrapper-custom'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					title={getTitle(actionType, rowData)}
					maxWidth={maxPopupWidth}
				>
					{popupFields ? (
						<PopupFields
							rowData={rowData}
							actionType={actionType}
							gridButtonHandler={this.gridButtonHandler}
							closePopup={this.closePopup}
							accountsData={accounts}
							gridRef={this.gridRef}
							rights={rights}
						/>
					) : null}
				</AdminPopup>
				<div className={'grid-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						alignment={'center'}
						ref={this.gridRef}
						dataSource={accounts}
						hoverStateEnabled={true}
						showBorders={false}
						focusedRowEnabled={false}
						columnHidingEnabled={true}
						onEditorPreparing={this.onEditorPreparing}
						height={'100%'}
						onContentReady={({component, element}) => {
							getAppliedFilters(component, element);
						}}
						onRowDblClick={(row) => {
							this.gridButtonHandler('open', AccountDetails, row.data);
						}}
					>
						<Toolbar>
							<Item location={'before'}>
								<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
								</div>
							</Item>
							<Item location={'before'}>
								<Button icon={'filter'} onClick={this.toggleFilter}/>
							</Item>
							<Item location={'before'}>
								<Button icon={'clearsquare'} onClick={this.clearFilters}/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'xlsxfile'}
									onClick={this.exportGrid}
								/>
							</Item>
						</Toolbar>
						<RemoteOperations
							paging
							filtering
						/>
						<StateStoring
							enabled={true}
							type="custom"
							customLoad={this.loadAccountsSettings}
							customSave={this.saveAccountsSettings}
							savingTimeout={100}
						/>
						<Paging enabled defaultPageSize={50}/>
						<FilterRow visible={showFilter}/>
						<FilterPanel visible={true}/>
						<Scrolling
							mode={'infinite'}
							showScrollbar="onHover"
						/>
						<Column
							dataField={'MerchantID'}
							caption={'ID клиента'}
							width={100}
						/>
						<Column
							dataField={'MerchantName'}
							caption={'Клиент'}
						/>
						<Column
							dataField={'UserTypeName'}
							filterOperations={this.filterOptions['UserTypeName'].filterOperations}
							calculateFilterExpression={this.filterOptions['UserTypeName'].calculateFilterExpression}
							caption={'Тип клиента'}
							width={130}
						/>
						<Column
							dataField={'PaymentSystemTypeName'}
							caption={'Тип счета'}
						/>
						<Column
							dataField={'PaymentSystemTypeInternalName'}
							caption={'Внутренний тип счёта'}
						/>
						<Column
							dataField={'InsDate'}
							caption={'Дата создания'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
							width={150}
						/>
						<Column
							dataField={'BoAccountNumber'}
							caption={'Счет'}
						/>
						<Column
							dataField={'CurrencyCode'}
							caption={'Валюта'}
							width={65}
						/>
						<Column
							dataField={'Balance'}
							caption={'Баланс'}
						>
							<Format
								type={'fixedPoint'}
								precision={PRECISION}
							/>
						</Column>
						<Column
							dataField={'LastPaymentInsDate'}
							caption={'Дата последней операции'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
						/>
						<Column
							dataField={'AccountStatusName'}
							caption={'Статус'}
							width={100}
						/>
						<Column
							caption={'Быстрый просмотр'}
							type={'buttons'}
							buttons={[
								{
									hint: 'Open',
									text: 'Открыть',
									onClick: ({row}) => {
										this.gridButtonHandler('open', AccountDetails, row.data);
									},
								},
							]}
						/>
					</DataGrid>
				</div>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'AccountsExport'}
					getGridParams={getAccountsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}
