import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.custom-scheme-teal.css';
import React, {Component} from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import RouteWithProps from './components/route-with-props/route-with-props';
import appInfo from './app-info';
import {getAllowedRoutes} from './app-routes';
import {Footer, LoginForm} from './components';
import {AdminLayout as Layout, SingleCard} from './layouts';
import {sizes, subscribe, unsubscribe} from './utils/media-query';
import {locale} from 'devextreme/localization';
import {vars} from './utils/variables';
import {getFromLocalStorage} from './utils/functions';
import config from 'devextreme/core/config';
import {licenseKey} from './devextreme-license';
import './App.scss';
import './dx-styles.scss';

config({licenseKey});

const {CLIENT_PATH} = vars;

const LoginContainer = ({logIn}) => <LoginForm onLoginClick={logIn}/>;

const NotAuthPage = (props) => (
	<SingleCard>
		<Route render={() => <LoginContainer {...props} />}/>
	</SingleCard>
);

const AuthPage = (props) => {
	const rights = getFromLocalStorage('userSession', 'Rights') || [];
	const allowedRoutes = getAllowedRoutes(rights);
	
	return (
		<Layout allowedRoutes={allowedRoutes} title={appInfo.title} {...props}>
			<Switch>
				{allowedRoutes.map(item => {
					return (
						<RouteWithProps
							exact
							key={item.path}
							path={item.path}
							component={item.component}
							rights={rights}
						/>
					);
				})}
				<Redirect to={CLIENT_PATH}/>
			</Switch>
			<Footer>
				Copyright © 2011-2019 Developer Express Inc.
				<br/>
				All trademarks or registered trademarks are property of their
				respective owners.
			</Footer>
		</Layout>
	)
};

class App extends Component {
	constructor(props) {
		super(props);
		
		locale('en');
		this.state = {
			loggedIn: false,
			screenSizeClass: this.getScreenSizeClass(),
		};
		
		this.userMenuItems = [
			{
				text: 'Logout',
				icon: 'runner',
				onClick: this.logOut,
			},
		];
	}
	
	componentDidMount() {
		const user = localStorage.getItem('user');
		
		this.setState({
			loggedIn: !!user,
		});
		
		subscribe(this.screenSizeChanged);
	}
	
	componentWillUnmount() {
		unsubscribe(this.screenSizeChanged);
	}
	
	render() {
		const {loggedIn} = this.state;
		const id = loggedIn ? '' : 'login-page';
		
		return (
			<div id={id} className={`app ${this.state.screenSizeClass}`}>
				<Router>{loggedIn ? <AuthPage userMenuItems={this.userMenuItems}/> : <NotAuthPage logIn={this.logIn}/>}</Router>
			</div>
		);
	}
	
	getScreenSizeClass() {
		const screenSizes = sizes();
		return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
	}
	
	screenSizeChanged = () => {
		this.setState({
			screenSizeClass: this.getScreenSizeClass(),
		});
	};
	
	logIn = (userSession) => {
		localStorage.setItem('user', userSession.UserID);
		this.setState({loggedIn: true});
	};
	
	logOut = () => {
		localStorage.clear();
		this.setState({loggedIn: false});
	};
}

export default App;
