import React, {useEffect, useState} from 'react';
import PageMenu from 'components/navigation-menu/page-menu';
import {menuItems} from 'app-routes';
import {vars} from 'utils/variables';
import ProfileDetailsForm from 'pages/checkout/profiles/profile-details-form';
import ProfileParams from './profile-params/profile-params';
import './details.scss';

const {PATH_KEY: {CHECKOUT}, PROFILE_DETAIL_STATE_KEY} = vars;

const getTitle = ({merchant}) => {
	return (
		<div className={'profile-detail-title'}>
			<h1>{merchant ? 'Профиль мерчанта' : 'Новый профиль'}</h1>
			{merchant && (
				<h2>
					{`ID Мерчанта: ${merchant.MerchantID}, Название мерчанта: ${merchant.MerchantName}, ID профиля: ${merchant.ID}, Название профиля: ${merchant.Name}`}
				</h2>
			)}
		</div>
	);
};

const getParamsTitle = ({merchant, profileParams}) => {
	return (
		<div className={'profile-detail-title'}>
			<h1>{`${profileParams.isEditingMode ? 'Параметры' : 'Новые параметры'} платежной системы ID профиля: ${merchant.ID}`}</h1>
		</div>
	);
}

const Details = (props) => {
	console.group('details');
	console.log(props);
	console.groupEnd();

	const [formState, setFormState] = useState({
		merchant: null,
		merchantFilterID: -1,
	});

	useEffect(() => {
		if (formState.merchantFilterID === -1) {
			const {location: {state}} = props;

			if (state) {
				window.localStorage.setItem(PROFILE_DETAIL_STATE_KEY, JSON.stringify(state));
				setFormState(state);
			} else {
				const lsState = window.localStorage.getItem(PROFILE_DETAIL_STATE_KEY);
				if (lsState) {
					setFormState(JSON.parse(lsState));
				}
			}
		}
	}, [formState, props]);

	const togglePage = (props) => {
		setFormState({
			...formState,
			profileParams: props,
		});
	}

	return (
		<div className={'checkout-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CHECKOUT]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{!formState.profileParams ? (
					<div className={'profile-details'}>
						{getTitle(formState)}
						{formState.merchantFilterID !== -1 && (
							<ProfileDetailsForm formState={formState} togglePage={togglePage}/>
						)}
					</div>
				) : (
					<div className={'profile-details'}>
						{/*{getParamsTitle({merchant: {ID: 232}})}*/}
						{getParamsTitle(formState)}
						{formState.merchantFilterID !== -1 && (
							<ProfileParams formState={formState} togglePage={togglePage}/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Details;
