import React, {useRef, useState} from 'react';
import DataGrid, {Export} from 'devextreme-react/data-grid';
import {Button, DateBox, Popover} from 'devextreme-react';
import {prepareGridExport} from 'utils/functions';
import {getFilter, prepareDatesForGridExport} from 'services/dataSourceFilterUtils';
import {getDataSource} from 'services/dataSource';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import saveAs from 'file-saver';

const ExportDataGrid = React.forwardRef(({
																					 isShowExportDatePopup,
																					 closeExportDatePopup,
																					 getGridParams,
																					 exportFileName,
																					 filterFieldName = 'InsDate',
																					 exportCaptions,
																					 target = '.dx-toolbar-after',
																				 }, ref) => {
	const exportRef = useRef(null);
	const [exportDataSource, setExportDataSource] = useState(null);
	const initDate = new Date();
	const [startDate, setStartDate] = useState(initDate);
	const [endDate, setEndDate] = useState(initDate);
	
	const prepareDatasource = () => {
		const gridInstance = ref.current.instance;
		
		const paramObj = getGridParams();
		
		const apiFilters = getFilter(gridInstance.getCombinedFilter(), paramObj.fieldsSettings);
		
		paramObj.defFilter = {
			...paramObj.defFilter,
			...apiFilters,
			[filterFieldName]: {
				between: prepareDatesForGridExport(startDate, endDate),
			},
		};
		
		const merchantsDataSource = getDataSource(paramObj);
		
		setExportDataSource(merchantsDataSource);
	}
	
	const onContentReadyExport = () => {
		const exportInstance = exportRef.current.instance;
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');
		
		prepareGridExport(exportInstance, ref.current.instance, exportCaptions);
		
		exportDataGrid({
			component: exportInstance,
			worksheet: worksheet,
			keepColumnWidths: false,
			/*customizeCell: function(options) {
				options.excelCell.font = { name: 'Arial', size: 12 };
				options.excelCell.alignment = { horizontal: 'left' };
			}*/
		}).then(function() {
			workbook.xlsx.writeBuffer()
				.then(function(buffer) {
					let filename = exportFileName;
					
					if (typeof exportFileName === 'function') {
						filename = exportFileName(startDate, endDate);
					}
					
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${filename}.xlsx`);
					setExportDataSource(null);
				});
		});
		
		closeExportDatePopup();
	}
	
	return (
		<div>
			{exportDataSource && (
				<DataGrid
					id={'grid-acc-statement-export'}
					ref={exportRef}
					onContentReady={onContentReadyExport}
					dataSource={exportDataSource}
				>
					<Export enabled/>
				</DataGrid>
			)}
			<div id={'calendar-wrapper'}>
				<Popover
					target={target}
					visible={isShowExportDatePopup}
					onHiding={closeExportDatePopup}
				>
					<div className={'export-fields'}>
						<div className="dx-field">
							<div className="dx-field-label">С</div>
							<div className="dx-field-value">
								<DateBox
									value={startDate}
									max={endDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										console.log(value.toString());
										
										setStartDate(value);
									}}
								/>
							</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">По</div>
							<div className="dx-field-value">
								<DateBox
									value={endDate}
									min={startDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										console.log(typeof value);
										console.log(value.toString());
										setEndDate(value);
									}}
								/>
							</div>
						</div>
						<div className={'export-button'}>
							<Button text={'Экспорт'} onClick={prepareDatasource}/>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	);
});

export default ExportDataGrid;