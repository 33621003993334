import notify from 'devextreme/ui/notify';
import {vars} from './variables';

const {NOTIFY} = vars;

export const notifyApp = (text, type = NOTIFY.ERROR, specialFormat = false) => {
	let notifyOptions = {
		message: text,
		position: {
			my: 'center top',
			at: 'center top'
		},
	};

	if (specialFormat) {
		notifyOptions = Object.assign(notifyOptions, {
			contentTemplate: (node) => {
				let lines = node.innerHTML.split('\n');
				node.innerText = '';

				lines.forEach((line, key) => {
					let el = document.createElement('span');
					el.innerText = line.replace(/(<([^>]+)>)/gi, "");
					node.appendChild(el);
				});

				return node;
			}
		});
	}

	return notify(notifyOptions, type, 3000);
};
