import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import appInfo from '../../app-info';
import {sha512} from 'utils/functions';
import {apiRequest} from 'services/async';
import AdminPopup from '../popup/admin-popup';
import {notifyApp} from 'utils/notifyWrapper';
import RecoverLoginFields from '../popup/recover-login-fields/recover-login-fields';
import {vars} from 'utils/variables';
import Loader from 'components/loader/loader';
import './login-form.scss';

const {APP_CODES: {SUCCESS}} = vars;

const LOGIN_FORM = {
	SUCCESS_CHANGE: 'Your password has been successfully changed',
	SUCCESS_BUTTON: 'Ok',
	RECOVER_POPUP_TITLE: 'Setting password',
};

export default class LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			login: '',
			password: '',
			hashedPassword: null,
			isPasswordChange: false,
			isShowLoader: false,
		};
	}

	successChangePasswordHandler = () => {
		this.setState({
			isPasswordChange: true,
		});
	};

	handleClosePopup = () => {
		this.setState({
			hashedPassword: null,
		});
	};

	loginChanged = e => {
		this.setState({login: e.value});
	};

	passwordChanged = e => {
		this.setState({password: e.value});
	};

	onLoginClick = async ({component, validationGroup}) => {
		if (!validationGroup.validate().isValid) {
			return;
		}
		
		const {login, password} = this.state;

		const hashedPassword = await sha512(password);

		if (hashedPassword) {
			component.option('disabled', true);
			
			try {
				const loginRequest = await apiRequest({
					operation: 'Login',
					data: {
						Login: login,
						Password: hashedPassword,
					}
				});
				
				if (loginRequest.data) {
					const {ResponseCode, Response, ResponseText} = loginRequest.data;
					
					if (ResponseCode === SUCCESS) {
						if (Response.IsPasswordExpired) {
							// show popup
							this.setState({
								hashedPassword: hashedPassword
							});
						} else {
							try {
								const userSession = await this.requestUserSession();
								this.props.onLoginClick(userSession);
							} catch (error) {
								notifyApp(error);
							}
						}
					} else {
						notifyApp(ResponseText);
					}
				} else {
					notifyApp('Network Error');
				}
			} finally {
				component.option('disabled', false);
			}
		} else {
			notifyApp('Client WebAPI error');
		}
	};

	requestUserSession = async () => {
		const request = await apiRequest({
			operation: 'Login/SessionConfiguration',
		});

		if (request.data.ResponseCode === SUCCESS) {
			window.localStorage.setItem('userSession', JSON.stringify(request.data.Response));
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	};

	render() {
		const {login, password, hashedPassword, isPasswordChange, isShowLoader} = this.state;
		return (
			<div className={'login-wrapper'}>
				<Loader isShowLoader={isShowLoader}/>
				<AdminPopup
					visible={!!hashedPassword}
					container={document.getElementById('login-page')}
					title={isPasswordChange ? LOGIN_FORM.SUCCESS_CHANGE : LOGIN_FORM.RECOVER_POPUP_TITLE}
					handleClose={this.handleClosePopup}
				>
					{isPasswordChange ?
						(
							<div className={'success-button'}>
								<Button
									text={LOGIN_FORM.SUCCESS_BUTTON}
									onClick={this.handleClosePopup}
								/>
							</div>
						) : (
							<RecoverLoginFields
								login={login}
								password={hashedPassword}
								successHandler={this.successChangePasswordHandler}
							/>
						)
					}
				</AdminPopup>
				<ValidationGroup>
					<div className={'login-header'}>
						<div className={'title'}>{appInfo.title}</div>
						<div>Sign In to your account</div>
					</div>
					<div className={'dx-field'}>
						<TextBox
							value={login}
							onValueChanged={this.loginChanged}
							placeholder={'Login'}
							width={'100%'}
						>
							<Validator>
								<RequiredRule message={'Login is required'}/>
							</Validator>
						</TextBox>
					</div>
					<div className={'dx-field'}>
						<TextBox
							mode={'password'}
							value={password}
							onValueChanged={this.passwordChanged}
							placeholder={'Password'}
							width={'100%'}
						>
							<Validator>
								<RequiredRule message={'Password is required'}/>
							</Validator>
						</TextBox>
					</div>
					<div className={'dx-field'}>
						<Button
							type={'default'}
							text={'Login'}
							onClick={this.onLoginClick}
							width={'100%'}
						/>
					</div>
				</ValidationGroup>
			</div>
		);
	}
}
