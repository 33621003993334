import axios from 'axios';
import {vars} from 'utils/variables';

const {APP_CODES} = vars;

export const baseUrl = process.env.REACT_APP_MOCK ? 'mock' : 'pfs3bo/wapi';

const makeUrl = operation => `${baseUrl}/${operation}`;

export const prepareRequestOptions = ({method = 'POST', operation, data = {}}) => {
	return {
		method,
		url: makeUrl(operation),
		data,
		validateStatus: function (code) {
			return code < 500;
		}
	}
};

const deAuth = () => {
	window.localStorage.removeItem('user');
	window.location.replace("/");
};

axios.interceptors.response.use(function (response) {
	if (response.data.ResponseCode === '401') {
		deAuth();
	}
	return response;
}, function (error) {
	if (error.status === 401) {
		deAuth();
	}
	return Promise.reject(error);
});

export const apiRequest = async (options) => {
	try {
		return await axios(prepareRequestOptions(options));
	} catch (error) {
		return error;
	}
};

export const createLegalClient = async ({merchantId, banxeTariffId}) => {
	const request = await apiRequest({
		operation: 'Banxe/CreateLegalClient',
		data: {
			Params: {
				MerchantID: merchantId,
				BanxeTariffID: banxeTariffId,
			}
		}
	});
	
	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return 'good';
	} else {
		throw new Error(request.data.ResponseText);
	}
}

export const getBanxeData = async ({merchantId}) => {
	const request = await apiRequest({
		operation: 'Banxe/GetBanxeClient',
		data: {
			Params: {
				MerchantID: merchantId,
			}
		}
	});
	
	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response;
	} else {
		throw new Error(request.data.ResponseText);
	}
}

export const getBanxeTariff = async () => {
	const request = await apiRequest({
		operation: 'BanxeTariff/List',
	});
	
	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response.BanxeTariff;
	} else {
		throw new Error(request.data.ResponseText);
	}
}

export const getDocuments = async (merchantId) => {
	const request = await apiRequest({
		operation: 'MerchantDocument/List',
		data: {
			Filters: {MerchantID: merchantId},
			NestedColumns: {
				MerchantDocumentFile: null
			}
		}
	});

	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response.MerchantDocument;
	} else {
		throw new Error(request.data.ResponseText);
	}
};

export const getMerchantHistory = async (merchantId) => {
	const request = await apiRequest({
		operation: 'BoMerchantHistory/List',
		data: {
			Sorts: ['-InsDate'],
			Filters: {
				MerchantID: merchantId,
			},
			Page: -1
		}
	});

	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response.BoMerchantHistory;
	} else {
		throw new Error(request.data.ResponseText);
	}
};

export const updateSelectedMerchantDetails = async (id) => {
	const request = await apiRequest({
		operation: 'Merchant/GetDetail',
		data: {
			Params: {
				MerchantID: id,
			}
		},
	});

	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response;
	} else {
		throw new Error(request.data.ResponseText);
	}
}

export const saveEditDataToApi = async (fullOperation, paramsData) => {
	const request = await apiRequest({
		operation: fullOperation,
		data: {
			Params: paramsData,
		}
	});

	if (request.data.ResponseCode === APP_CODES.SUCCESS) {
		return request.data.Response;
	} else {
		throw new Error(request.data.ResponseText);
	}
}

