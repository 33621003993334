import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
const isArray = (value) => value != null && typeof value === "object" && value.hasOwnProperty('length');
const isOperator = value => ["=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"].includes(value);

export const formatDateToApi = (value, withTime = false) => {
	if (value == null)
		return value;

	if (withTime) {
		return dayjs(new Date(value)).format(dateTimeFormat);
	}
	return dayjs(new Date(value)).format(dateFormat);
};

export const prepareDatesForGridExport = (start, end) => {
	const date1 = dayjs(start);
	const date2 = dayjs(end);

	return [
		date1.startOf('day').format(dateTimeFormat),
		date2.endOf('day').format(dateTimeFormat),
	];
}

export const gridDate = (value, withTime = false) => {
	if (value == null) return value;

	if (withTime) {
		return dayjs(new Date(value)).format('DD.MM.YYYY, HH:mm:ss');
	}
	return dayjs(new Date(value)).format('DD.MM.YYYY');
}

export const fileDateTextFormat = (value) => {
	return dayjs(new Date(value)).format('DD.MM.YYYY');
}

export const firstDateOfMonth = () => {
	return dayjs(Date.now()).startOf('month');
}

export const lastDateOfMonth = () => {
	return dayjs(Date.now()).endOf('month');
}

/**
 * @typedef {'number' | 'string' | 'date' | 'dateTime' | 'boolean'} FieldType
 */


/**
 * @param {FieldType} type
 * @param value
 */
const valueToAPI = (type, value) => {
	switch (type) {
		case "boolean": {
			return value != null ? +Boolean(value) : value
		}
		case "dateTime":
		case "date": {
			return formatDateToApi(value, type === "dateTime");
		}
		case "number":
		case "string":
		default:
			return value;
	}
};

/**
 * Callback для форматирования значения фильтра.
 *
 * @callback valueFormatterCB
 * @param {*} value - значение фильтра
 * @param {string} name - имя поля фильтра.
 */

/**
 * Кофертирует фильтр DX в апи.
 * реализация только операции and
 * @param {valueFormatterCB} [valueFormatter]
 * @param {[]} filter
 */
export function convertFilterToApi(filter = [], valueFormatter) {
	let result = {};

	const INDEXES = {
		GETTER: 0,
		OPERATION: 1,
		VALUE: 2,
	};

	const formatter = typeof valueFormatter === 'function' ? valueFormatter : (value) => value;

	if (!isArray(filter) || !filter.length)
		return;

	function process(data) {
		if (data[INDEXES.OPERATION] === 'contains') {
			// 78495: Для всех закладок фильтры по строковым полям для Contains должны передавать like
			// Ex. {"Name" : {'like': 'value'}}
			return result[data[INDEXES.GETTER]] = {
				like: formatter(data[INDEXES.VALUE], data[INDEXES.GETTER])
			};
		}

		if (isArray(data)) {
			for (let i = 0; i < data.length; ++i) {
				if (isArray(data[i]) && !isOperator(data[INDEXES.OPERATION])) {
					process(data[i]);
					continue;
				}
				if (data.length === 3 && isOperator(data[INDEXES.OPERATION])) { //example ["Amount", "<=", 2]
					const [getter, operation, value] = data;

					// eslint-disable-next-line default-case
					switch (i) {
						case INDEXES.GETTER: {
							if (!result.hasOwnProperty(getter))
								result[getter] = {};
							break;
						}
						case INDEXES.OPERATION: {
							//если уже есть поле с оператором >= и еще есть оператор <= - значит это between
							if (result[getter].hasOwnProperty('>=') && operation === '<=') {
								const moreThen = result[getter]['>='];
								delete result[getter]['>='];
								result[getter]['between'] = [moreThen];
							} else {
								result[getter][operation] = undefined;
							}
							break;
						}
						case INDEXES.VALUE: {
							if (result[getter].hasOwnProperty('between')) {
								result[getter]['between'].push(formatter(value, getter));
							} else {
								result[getter][operation] = formatter(value, getter);
							}
							break;
						}
					}
				}
			}
		}
	}

	process(filter);

	return result;
}

/**
 * Интерферфейс объекта фильтра
 * @typedef {Object} FilterSettings
 * @property {FieldType} type - тип поля
 */

/**
 * @param filter - dx filter
 * @param {FilterSettings} fieldsSettings -  объект с именами полей и их настройками
 */
export function getFilter(filter = [], fieldsSettings = {}) {
	const formatter = (value, name) => {
		if (!fieldsSettings.hasOwnProperty(name)) return value;
		return valueToAPI(fieldsSettings[name].type, value);
	};
	return convertFilterToApi(filter, formatter);
}
