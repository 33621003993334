import {Route} from 'react-router-dom';

const RouteWithProps = ({component: Component, ...rest}) => (
	<Route
		{...rest}
		render={routeProps => (
			<Component {...routeProps} {...rest} />
		)}
	/>
);

export default RouteWithProps;