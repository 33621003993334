export const vars = {
	APP_CODES: {
		SUCCESS: '000',
	},
	CLIENT_PATH: '/clients/clients',
	CLIENT_DETAIL_PATH: '/clients/clients/detail',
	REFERRER_DETAIL_PATH: '/clients/referrers/detail',
	REFERRER_PATH: '/clients/referrers',
	PATH_KEY: {
		CLIENTS: 'clients',
		CHECKOUT: 'checkout',
		FINANCE: 'finance',
		MARKETING: 'marketing',
		SETTINGS: 'settings',
	},
	NOTIFY: {
		ERROR: 'error',
		SUCCESS: 'success',
	},
	LOGIN: {
		TEXT: {
			AFTER_NEW_PASSWORD: 'Password must contain 8 symbols with uppercase, lowercase and digits',
			NEW_PASSWORD_PLACEHOLDER: 'New password',
			NEW_PASSWORD_REQUIRED_ERROR: 'New password is required',
		},
	},
	TRANSACTION: {
		STATUS_COLOR: {
			GREEN: 'green-status',
			RED: 'red-status',
			BLACK: 'black-status',
			OTHER: 'blue-status',
		},
		RIGHTS: {
			REPLAY: '7',
			ACCEPT: '3',
			ACCEPT_GATEWAY: '58',
			DECLINE: '4',
		},
		SPECIAL_BO_PAYMENT_TYPE_ID: 3,
		RECHECK_PAYMENT_STATUS_ID: 5,
		SPECIAL_PAYMENT_STATUS_ID: 8,
		TEXT: {
			FILTERS: 'Фильтры',
		},
		REPLAY: 'replay',
	},
	MERCHANT: {
		TRANSACTIONS_PATH: '/clients/transactions',
		RIGHTS: {
			INCOME: '6',
			PAYOUT: '5',
			IS_BAN_RESTRICT: '9',
			IS_BAN_REMOVE_RESTRICT: '10',
			IS_ADD_ACCOUNT_RESTRICT: '11',
			IS_ADD_ACCOUNT_REMOVE_RESTRICT: '12',
			ARREST: '13',
			REMOVE_ARREST: '14',
			RESTRICTION_HISTORY: '15',
			CANCEL_ACCOUNT: '18',
			CHANGE_VERIFICATION_VISIBLE: '20',
			CREATE_ALLOWED_ACCOUNT: '24',
			CREATE_ACCOUNT: '25',
		},
		ACCOUNT_STATUS: {
			IS_ACTIVE: 0,
			IS_ARRESTED: 1,
		},
		ARREST_ACCOUNT_OBJECT: 'Account',
		COMMON_RESTRICTION_OBJECT: 'Merchant',
		CANCEL_ACCOUNT_OPERATION: 'WallettoCard/FullBlock',
		ACCOUNTS_PATH: '/clients/accounts',
		PROFILES_PATH: '/checkout/profiles',
		PROFILE_DETAIL_PATH: '/checkout/profiles/detail',
	},
	INTERNAL_ACCOUNT_ID: 0,
	WALLETTO_ACCOUNT_ID: 11,
	SHARPAY_IBAN_ID: 12,
	BANXE_ID: 21,
	BO_PAYMENT_TYPE__INCOME_CHECKOUT: 7,
	ACTIVE_ACCOUNT_STATUS_ID: 1,
	WALLETTO_CARD_TYPE_VIRTUAL: 3,
	STATE_STORING_KEYS: {
		CLIENTS: {
			CLIENTS_PAGE: 'clients-page',
			TRANSACTIONS_PAGE: 'transactions-page',
			ACCOUNTS_PAGE: 'accounts-page',
			CARDS_OPERATIONS_PAGE: 'cards-operations-page',
			IBAN_OPERATIONS_PAGE: 'iban-operations-page',
		},
		MERCHANTS: {
			ORDERS_PAGE: 'orders-page',
			PAYMENTS_PAGE: 'payments-page',
			SETTLEMENT_PAGE: 'settlement-page',
			PROFILES_PAGE: 'profiles-page',
		},
	},
	HIGHLIGHT_COLUMN_CLASS: 'column-with-filter',
	PRECISION: 2,
	PAY_RETAILS: 14,
	CHECKOUT_REFILL_ID: 6,
	SWIFT_PAYMENT_SYSTEM_TYPE_ID: 16,
	PROFILE_DETAIL_STATE_KEY: 'profile-detail-state',
	VALIDATION: {
		REQUIRED_TEXT: 'Поле обязательное',
		ONLY_LATIN_TEXT: 'Только латинские символы',
		ISO_ALPHA3_TEXT: '3-х буквенный код, напр. \'EST\'',
	},
	COMPACT_BOX_WIDTH: 340,
	PAYMENT_SYSTEM_KIND_DICTIONARY: [
		{ID: 0, Name: 'Внутренняя'},
		{ID: 1, Name: 'Карточный процессинг'},
		{ID: 2, Name: 'Электронные кошельки'},
		{ID: 3, Name: 'Walletto'},
		{ID: 4, Name: 'Crypto'},
		{ID: 5, Name: 'Service'},
		{ID: 6, Name: 'Banxe'},
	],
	SHOW_CARD_FORM_DICTIONARY: [
		{ID: 1, Name: 'Web S3'},
		{ID: 2, Name: ' Checkout'},
		{ID: 3, Name: ' Mobile'},
	],
	EDITOR_OPTIONS: {
		disabled: {disabled: true},
		readOnly: {readOnly: true},
	},
	SETTLEMENT_MODE: [
		{ID: 0, Name: 'Auto'},
		{ID: 1, Name: 'Manual'},
	],
	IS_ACTIVE_DICTIONARY: [
		{ID: 0, Name: 'Disabled'},
		{ID: 1, Name: 'Active'},
	],
	SPREAD_DICTIONARY: [
		{ID: 0, Name: 'No'},
		{ID: 1, Name: 'Yes'},
	],
	PAYER_TYPE_DICTIONARY: [
		{ID: 1, Name: 'свой счет'},
		{ID: 2, Name: 'чужой счет'},
	],
	CURRENCY_TYPE_DICTIONARY: [
		{ID: 1, Name: 'Fiat'},
		{ID: 2, Name: 'Crypto'},
	],
	RESTRICTION_DICTIONARY: [
		{ID: 0, Name: 'Denied'},
		{ID: 1, Name: 'Allowed'},
	],
	CRYPTO_INCOME_DICTIONARY: [
		{ID: 1, Name: 'Изменять сумму заказа'},
		{ID: 2, Name: 'Накапливать с изменением суммы заказа'},
		{ID: 3, Name: 'Накапливать без изменения суммы заказа'},
	],
	PAYMENT_TYPE_NAME_DICTIONARY: [
		{ID: 1, Name: 'Payout'},
		{ID: 2, Name: 'Income'},
		{ID: 3, Name: 'Technical Payout'},
		{ID: 4, Name: 'Technical Income'},
		{ID: 5, Name: 'Checkout Payout'},
		{ID: 6, Name: 'Checkout Income'},
		{ID: 7, Name: 'Settlement payout'},
		{ID: 8, Name: 'Settlement income'},
		{ID: 9, Name: 'Rolling payout'},
		{ID: 10, Name: 'Rolling income'},
		{ID: 11, Name: 'Crypto exchange'},
	],
	MERCHANT_COLUMNS: [
		'ID', 'InsDate', 'Name', 'Country', 'Host', 'UserTypeID', 'UserTypeName', 'UserStatusName',
		'UserStatusID', 'VmVerificationStatusName', 'VmVerificationStatusID', 'VmVerificationLevelName',
		'VmVerificationLevelID', 'LastActivityDate', 'TotalBalance', 'UserID', 'UserIsBan', 'MerchantCategoryName',
		'IsRestrictAddAccount', 'IsRestrictAddExternalAccount',
	],
	ROLLING_COLUMNS: [
		'RollingPaymentID', 'InsDate', 'MerchantID', 'MerchantName', 'MerchantApiProfileID', 'MerchantApiProfileName',
		'RollingReleaseDate', 'RollingStatusName', 'Amount', 'CurrencyID', 'CurrencySymbol', 'CurrencyCode',
		'MerchantOrderID', 'PaymentID', 'IsManualPay', 'RollingStatus', 'AccountID', 'AccountCode', 'Remark',
		'BoUserID', 'BoUserName',
	],
	CRYPTO_ADDR_COLUMNS: [
		'Urlid', 'InsDate', 'MerchantID', 'AccountCode', 'AccountCurrencyCode', 'MerchantOrderID', 'PaymentCount',
		'CurrencyCode', 'Address', 'CurrencyCode', 'BlockchainCode', 'Memo', 'ExternalID', 'AddressStatus', 'ExpiryDate',
	],
	PAYMENT_SYSTEM_TYPE_COLUMNS: [
		'ID', 'Name', 'InternalName', 'PaymentSystemKindID', 'PaymentSystemTypeGroupID', 'PaymentSystemTypeGroupName',
		'PayGateID', 'IsMaskForeignAccountCode', 'IsAllowConnectExternalAccount', 'IsRemoveSpaceForeignAccount',
		'RollingReserveRate', 'RollingReserveDays', 'ShowCardForm', 'CheckoutUrl', 'ImageUrl', 'NameCode',
	],
	PAYMENT_SCHEME_COLUMNS: [
		'ID', 'PaymentSystemTypeID', 'PaymentSystemTypeName', 'ForeignPaymentSystemTypeID', 'ForeignPaymentSystemTypeName',
		'PaymentTypeID', 'PaymentTypeName', 'CurrencyID', 'CurrencyCode', 'ForeignCurrencyID', 'ForeignCurrencyCode',
		'PayGateTokenID', 'PayGateTokenDisplayName', 'ForeignPayGateTokenID', 'ForeignPayGateTokenDisplayName', 'MinAmount',
		'MaxAmount', 'IsActive', 'VmVerificationLevels', 'DeviceTypeID', 'DeviceTypeName',
	],
	EXCHANGE_RATES_COLUMNS: [
		'ID', 'FirstCurrencyID', 'FirstCurrencyCode', 'SecondCurrencyID', 'SecondCurrencyCode', 'OriginalRate',
		'ReverseOriginalRate', 'Ask', 'ReverseAsk', 'Bid', 'ReverseBid', 'AskSpread', 'BidSpread',
		'IsActiveSpreadPerc', 'AskSpreadPerc', 'BidSpreadPerc', 'isActive', 'Rate', 'ChainCodes',
	],
	CURRENCY_COLUMNS: [
		'ID', 'Code', 'Symbol', 'Name', 'Precision', 'IsAllowCreateAccount', 'ViewPrecision', 'CurrencyTypeID',
		'ImageUrl', 'IsAllowSystemPayment',
	],
	CURRENCY_BLOCKCHAIN_COLUMNS: [
		'ID', 'CurrencyID', 'CurrencyCode', 'BlockchainID', 'BlockchainName', 'BlockchainCode', 'AddressValidationRegexp',
		'MinDeposit', 'MinWithdraw', 'IsHasMemo', 'WithdrawFeeAmountConst',
	],
	BLOCKCHAIN_COLUMNS: ['ID', 'Code', 'Name', 'IsAddressCaseInsensitive'],
	SERVICES_COLUMNS: [
		'ID', 'Name', 'ServiceCategoryID', 'ServiceCategoryName', 'PaymentSystemTypeID', 'PaymentSystemTypeName',
		'ExternalServiceID', 'LimitCurrencyID', 'LimitCurrencyCode', 'LimitMinAmount', 'LimitMaxAmount', 'ExampleAccount',
		'IsActive', 'IsDeleted', 'InsDate', 'LastUpdate', 'Description',
	],
};
