import React, {useRef, useState} from 'react';
import {calcColumnWidth, getAppliedFilters} from 'utils/functions';
import DataGrid, {
	Button as GridButton,
	Column,
	FilterRow,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {exportCryptoAddress, exportCryptoAddressColumnWidth, getCryptoAddress,} from 'services/requestConsts';
import {useHistory} from 'react-router-dom';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {vars} from 'utils/variables';
import Button from 'devextreme-react/button';

const {MERCHANT, TRANSACTION, APP_CODES, NOTIFY} = vars;
const cryptoAddrDatasource = getDataSource(getCryptoAddress());

const CryptoAddress = () => {
	const history = useHistory();
	const gridRef = useRef(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}

	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					id={'grid-acc-statement'}
					ref={gridRef}
					alignment={'center'}
					dataSource={cryptoAddrDatasource}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								icon={'xlsxfile'}
								onClick={exportGrid}
							/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					
					<Column
						hidingPriority={12}
						caption={exportCryptoAddress.InsDate}
						dataField={'InsDate'}
						format={'dd.MM.yyyy, HH:mm'}
						dataType={'datetime'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.InsDate)}
					/>
					<Column
						hidingPriority={11}
						caption={exportCryptoAddress.MerchantID}
						dataField={'MerchantID'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.MerchantID)}
					/>
					<Column
						hidingPriority={10}
						caption={exportCryptoAddress.AccountCode}
						dataField={'AccountCode'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.AccountCode)}
					/>
					<Column
						hidingPriority={9}
						caption={exportCryptoAddress.AccountCurrencyCode}
						dataField={'AccountCurrencyCode'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.AccountCurrencyCode)}
					/>
					<Column
						hidingPriority={8}
						caption={exportCryptoAddress.MerchantOrderID}
						dataField={'MerchantOrderID'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.MerchantOrderID)}
					/>
					<Column
						hidingPriority={7}
						caption={exportCryptoAddress.PaymentCount}
						dataField={'PaymentCount'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.PaymentCount)}
					/>
					<Column
						hidingPriority={6}
						caption={exportCryptoAddress.CurrencyCode}
						dataField={'CurrencyCode'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.CurrencyCode)}
					/>
					<Column
						hidingPriority={5}
						caption={exportCryptoAddress.Address}
						dataField={'Address'}
					/>
					<Column
						hidingPriority={4}
						caption={exportCryptoAddress.BlockchainCode}
						dataField={'BlockchainCode'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.BlockchainCode)}
					/>
					<Column
						hidingPriority={3}
						caption={exportCryptoAddress.Memo}
						dataField={'Memo'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.Memo)}
					/>
					<Column
						hidingPriority={2}
						caption={exportCryptoAddress.ExternalID}
						dataField={'ExternalID'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.ExternalID)}
					/>
					<Column
						hidingPriority={1}
						caption={exportCryptoAddress.AddressStatus}
						dataField={'AddressStatus'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.AddressStatus)}
					/>
					<Column
						hidingPriority={0}
						caption={exportCryptoAddress.ExpiryDate}
						dataField={'ExpiryDate'}
						format={'dd.MM.yyyy, HH:mm'}
						dataType={'datetime'}
						width={calcColumnWidth(exportCryptoAddressColumnWidth.ExpiryDate)}
					/>
					
					<Column type={'buttons'} width={50}>
						<GridButton icon={'mdi mdi-playlist-check'} hint={'Транзакции'} onClick={({row: {data}}) => {
							window.sessionStorage.setItem('filter', JSON.stringify({
								merchantSenderAccountFilter: data.Address,
							}));
							history.push(MERCHANT.TRANSACTIONS_PATH);
						}}/>
					</Column>
				</DataGrid>
			</div>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'CryptoAddressExport'}
				getGridParams={getCryptoAddress}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
				exportCaptions={exportCryptoAddress}
			/>
		</div>
	);
}

export default CryptoAddress;