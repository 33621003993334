import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow, Item,
	Paging,
	RemoteOperations,
	Scrolling, Toolbar,
} from 'devextreme-react/data-grid';
import {notifyApp} from 'utils/notifyWrapper';
import {getDataSource} from 'services/dataSource';
import {exportCryptoExchangeOrder, getCryptoExchangeOrder,} from 'services/requestConsts';
import {getFromLocalStorage} from 'utils/functions';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {vars} from 'utils/variables';
import {apiRequest} from 'services/async';
import ShowComment from '../clients-page/clientsPopupFields/show-comment';
import AdminPopup from 'components/popup/admin-popup';
import Button from 'devextreme-react/button';

const {TRANSACTION, APP_CODES, NOTIFY} = vars;

const initPopupState = {
	title: 'Комментарий',
	visible: false,
	actionType: {
		subtype: null,
		remark: '',
	},
	rowData: null,
};

const cryptoExchangeDatasource = getDataSource(getCryptoExchangeOrder());

const Market = (props) => {
	const {rights} = props;
	const gridRef = useRef(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	const [popupState, setPopupState] = useState(initPopupState);

	const closePopup = () => {
		setPopupState(initPopupState);
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}

	return (
		<div className={'page-component-wrapper-custom'}>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closePopup}
				visible={popupState.visible}
				title={popupState.title}
			>
				<ShowComment
					key={popupState.rowData}
					actionType={popupState.actionType}
					rowData={popupState.rowData}
					closePopup={closePopup}
					operation={'CryptoExchangeOrder/ModRemark'}
					fieldName={'CryptoExchangeOrderID'}
				/>
			</AdminPopup>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					id={'grid-acc-statement'}
					dataSource={cryptoExchangeDatasource}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								icon={'xlsxfile'}
								onClick={exportGrid}
							/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						ref={gridRef}
						dataField={'InsDate'}
						caption={exportCryptoExchangeOrder['InsDate']}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={150}
					/>
					<Column dataField={'ID'} caption={exportCryptoExchangeOrder['ID']}/>
					<Column dataField={'MerchantID'} caption={exportCryptoExchangeOrder['MerchantID']}/>
					<Column dataField={'CryptoExchangeTypeName'} caption={exportCryptoExchangeOrder['CryptoExchangeTypeName']}/>
					<Column dataField={'OrderType'} caption={exportCryptoExchangeOrder['OrderType']}/>
					<Column dataField={'Actives'} caption={exportCryptoExchangeOrder['Actives']}/>
					<Column dataField={'CurrencyRate'} caption={exportCryptoExchangeOrder['CurrencyRate']}/>
					<Column dataField={'AvgCurrencyRate'} caption={exportCryptoExchangeOrder['AvgCurrencyRate']}/>
					<Column dataField={'Amount'} caption={exportCryptoExchangeOrder['Amount']}/>
					<Column dataField={'ExecutedAmount'} caption={exportCryptoExchangeOrder['ExecutedAmount']}/>
					<Column dataField={'TotalAmount'} caption={exportCryptoExchangeOrder['TotalAmount']}/>
					<Column dataField={'StatusName'} caption={exportCryptoExchangeOrder['StatusName']}/>
					<Column dataField={'Remark'} caption={exportCryptoExchangeOrder['Remark']}/>
					<Column
						type={'buttons'}
						caption={'Действие'}
						buttons={[
							{
								hint: 'Decline',
								icon: 'close',
								visible: ({row: {data: {Status}}}) => {
									return Status === 0 && rights.includes('36');
								},
								onClick: async ({row: {key}}) => {
									const request = await apiRequest({
										operation: 'CryptoExchangeOrder/Cancel',
										data: {
											Params: {
												CryptoExchangeOrderID: key,
											}
										},
									});
									
									if (request.data.ResponseCode === APP_CODES.SUCCESS) {
										notifyApp(`Ордер ${key} отменен`, NOTIFY.SUCCESS);
										await new Promise(resolve => setTimeout(resolve, 2000));
										if (gridRef.current) {
											gridRef.current.instance.refresh();
										}
									} else {
										notifyApp(request.data.ResponseText);
									}
								}
							},
							{
								hint: 'Info',
								icon: 'edit',
								visible: true,
								onClick: ({row}) => {
									setPopupState({
										title: 'Комментарий',
										visible: true,
										actionType: {
											subtype: 'add-comment',
											remark: row.data.Remark,
											updateComments: () => {
												if (gridRef.current) {
													gridRef.current.instance.refresh();
												}
											}
										},
										rowData: row.data,
									});
								}
							},
						]}
					/>
				</DataGrid>
			</div>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'CryptoExchangeOrderExport'}
				getGridParams={getCryptoExchangeOrder}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
				exportCaptions={exportCryptoExchangeOrder}
			/>
		</div>
	);
}

export default Market;