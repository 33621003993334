import React, {useEffect, useRef, useState} from 'react';
import Form, {SimpleItem} from 'devextreme-react/form';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {Button} from 'devextreme-react';
import {vars} from 'utils/variables';

const {APP_CODES, PAYMENT_SYSTEM_KIND_DICTIONARY, SHOW_CARD_FORM_DICTIONARY, NOTIFY} = vars;

const numberEditorOptions = {
	step: 0,
};

const PaymentSystemsEditForm = ({rowData, closePopup}) => {
	const formRef = useRef();
	const [paymentSystemTypeGroupID, setPaymentSystemTypeGroupID] = useState(null);

	const [showCardForm] = useState(() => {
		let showCardFormValue = null;

		if (Array.isArray(rowData.ShowCardForm)) {
			showCardFormValue = rowData.ShowCardForm;
		} else if (typeof rowData.ShowCardForm === 'string' || rowData.ShowCardForm instanceof String) {
			showCardFormValue = rowData.ShowCardForm.split(',').map(Number);
		}

		return showCardFormValue;
	});

	const loadPaymentSystemTypeGroupID = async () => {
		const request = await apiRequest({
			operation: 'PaymentSystemTypeGroup/List',
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response['PaymentSystemTypeGroup'];
		} else {
			throw new Error(request.data.ResponseText);
		}
	}

	useEffect(() => {
		loadPaymentSystemTypeGroupID().then((loadResult) => {
			setPaymentSystemTypeGroupID(loadResult);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
	}, []);

	return (
		<div>
			<Form
				ref={formRef}
				id={'client-create-form'}
				showColonAfterLabel={true}
				labelLocation={'left'}
				formData={rowData}
				validationGroup={'common'}
			>
				<SimpleItem
					dataField={'PaymentSystemKindID'}
					label={{text: 'Вид системы'}}
					editorType={'dxSelectBox'}
					editorOptions={{
						dataSource: PAYMENT_SYSTEM_KIND_DICTIONARY,
						displayExpr: 'Name',
						valueExpr: 'ID',
					}}
				/>
				<SimpleItem dataField={'ID'} label={{text: 'ID системы'}} editorOptions={{disabled: true}}/>
				<SimpleItem dataField={'InternalName'} label={{text: 'Внутреннее название системы'}}/>
				<SimpleItem dataField={'Name'} label={{text: 'Отображаемое название системы'}}/>
				<SimpleItem
					dataField={'PaymentSystemTypeGroupID'}
					label={{text: 'Группа типа'}}
					editorType={'dxSelectBox'}
					editorOptions={{
						dataSource: paymentSystemTypeGroupID,
						displayExpr: 'Name',
						valueExpr: 'ID',
					}}
				/>
				<SimpleItem
					dataField={'PayGateID'}
					label={{text: 'ID в платежном шлюзе'}}
					editorType={'dxNumberBox'}
					editorOptions={numberEditorOptions}
				/>
				<SimpleItem
					dataField={'IsMaskForeignAccountCode'}
					label={{text: 'Маскировать номер счета'}}
					editorType={'dxCheckBox'}
				/>
				<SimpleItem
					dataField={'IsRemoveSpaceForeignAccount'}
					label={{text: 'Убирать пробелы номера счета'}}
					editorType={'dxCheckBox'}
				/>
				<SimpleItem
					dataField={'IsAllowConnectExternalAccount'}
					label={{text: 'Разрешено подключать внешние счета'}}
					editorType={'dxCheckBox'}
				/>
				<SimpleItem
					dataField={'RollingReserveRate'}
					label={{text: 'Резервирование (RRR) по умолчанию, %'}}
					editorType={'dxNumberBox'}
					editorOptions={numberEditorOptions}
				/>
				<SimpleItem
					dataField={'RollingReserveDays'}
					label={{text: 'Резервирование (RRD) по умолчанию, дней'}}
					editorType={'dxNumberBox'}
					editorOptions={numberEditorOptions}
				/>
				<SimpleItem
					dataField={'ShowCardForm'}
					label={{text: 'Активная карточная форма'}}
					editorType={'dxTagBox'}
					editorOptions={{
						stylingMode: 'underlined',
						showSelectionControls: true,
						items: SHOW_CARD_FORM_DICTIONARY,
						valueExpr: 'ID',
						displayExpr: 'Name',
						value: showCardForm,
						applyValueMode: 'useButtons',
					}}
				/>
				<SimpleItem dataField={'CheckoutUrl'} label={{text: 'Checkout URL'}}/>
				<SimpleItem dataField={'ImageUrl'} label={{text: 'Logo Url'}}/>
				<SimpleItem dataField={'NameCode'} label={{text: 'Код фразы для названия системы'}} editorOptions={{disabled: true}}/>
			</Form>
			<div className={'button-wrapper'}>
				<Button
					onClick={async () => {
						const formData = formRef.current.instance.option('formData');
						let showCardFormValue = null;

						if (Array.isArray(formData.ShowCardForm) && formData.ShowCardForm.length > 0) {
							showCardFormValue = formData.ShowCardForm.join(',');
						}

						const params = {
							...formData,
							IsMaskForeignAccountCode: +formData.IsMaskForeignAccountCode,
							IsAllowConnectExternalAccount: +formData.IsAllowConnectExternalAccount,
							IsRemoveSpaceForeignAccount: +formData.IsRemoveSpaceForeignAccount,
							ShowCardForm: showCardFormValue,
						}

						const request = await apiRequest({
							operation: 'PaymentSystemType/Mod',
							data: {
								Params: params,
							}
						});

						if (request.data.ResponseCode === APP_CODES.SUCCESS) {
							notifyApp('Success', NOTIFY.SUCCESS);
							closePopup();
						} else {
							notifyApp(request.data.ResponseText);
						}
					}}
					text={'Сохранить'}
				/>
				<Button
					text={'Закрыть'}
					onClick={closePopup}
				/>
			</div>
		</div>
	);
}

export default PaymentSystemsEditForm;