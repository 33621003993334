import React, {useState} from 'react';
import AdminPopup from 'components/popup/admin-popup';
import {Button, SelectBox, TextArea, TextBox} from 'devextreme-react';
import {getDictionaryDataSource} from 'services/dataSource';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import {CheckBox} from 'devextreme-react/check-box';

const {
	APP_CODES: {SUCCESS},
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {ORDERS_PAGE}},
	PRECISION,
	NOTIFY,
} = vars;

const amountRecalcTypes = [
	{'Name': 'без обновления суммы', ID: 0},
	{'Name': 'с обновлением суммы заказа', ID: 1},
];

const OrderActionPopup = ({
														rowData,
														visible,
														handleClose,
													}) => {
	const [remark, setRemark] = React.useState('');
	const [paymentSystemTypeID, setPaymentSystemTypeID] = useState(null);
	const [currencyID, setCurrencyID] = useState(null);
	const [amountRecalcType, setAmountRecalcType] = useState(null);
	const [isClose, setIsClose] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState('');
	
	const [paymentSystemTypeIDs] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentSystemType',
			filter: {
				PaymentSystemKindID: 7,
			},
		}));
	const [currencyDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Currency',
		}),
	);
	
	const getTitle = () => {
		if (!rowData) return '';
		
		return rowData.type === 'close' ?
			`Закрыть заказ ID: ${rowData.ID}` :
			`Создать оплату по заказу ID: ${rowData.ID}`;
	}
	
	const getPopupBody = () => {
		if (!rowData) return null;
		
		return rowData.type === 'close' ? (
			<div>
				<div className="field-info">
					<div>{`Сумма заказа: ${rowData.OrigAmount} ${rowData.CurrencyCode}`}</div>
					<div>{`Оплачено: ${rowData.PaymentAmount} ${rowData.CurrencyCode}`}</div>
				</div>
				<div className={'field-info'}>
					<div className="field-label">{'Тип обновления суммы заказа:'}</div>
					<SelectBox
						displayExpr={'Name'}
						valueExpr={'ID'}
						placeholder={'Выбрать'}
						items={amountRecalcTypes}
						onValueChanged={({value}) => {
							setAmountRecalcType(value);
						}}
					/>
				</div>
				<br/>
				<div className={'field-info'}>
					<div className="field-label">{'Системный комментарий:'}</div>
					<TextArea
						onValueChanged={({value}) => {
							setRemark(value);
						}}
					/>
				</div>
				<div className={'button-wrapper'}>
					<Button
						text={'Подтвердить'}
						onClick={async () => {
							const request = await apiRequest({
								operation: 'MerchantOrder/Close',
								data: {
									Params: {
										MerchantOrderID: rowData.ID,
										AmountRecalcType: amountRecalcType,
										Remark: remark,
									},
								},
							});
							
							if (request.data.ResponseCode === SUCCESS) {
								notifyApp('Success', NOTIFY.SUCCESS);
								handleClose();
							} else {
								notifyApp(request.data.ResponseText);
							}
						}}
					/>
					<Button
						text={'Отмена'}
						onClick={handleClose}
					/>
				</div>
			</div>
		) : (
			<div>
				<div className={'field-info'}>
					<div className="field-label">{'Платежная система:'}</div>
					<SelectBox
						displayExpr={'Name'}
						valueExpr={'ID'}
						placeholder={'Выбрать'}
						dataSource={paymentSystemTypeIDs}
						onValueChanged={({value}) => {
							setPaymentSystemTypeID(value);
						}}
					/>
				</div>
				<br/>
				<div className={'field-info'}>
					<div className="field-label">{'Валюта оплаты:'}</div>
					<SelectBox
						displayExpr={'Code'}
						valueExpr={'ID'}
						placeholder={'Выбрать'}
						dataSource={currencyDictionary}
						onValueChanged={({value}) => {
							setCurrencyID(value);
						}}
					/>
				</div>
				<br/>
				<div className={'field-info'}>
					<div className="field-label">{'Сумма оплаты:'}</div>
					<TextBox
						onValueChanged={({value}) => {
							setPaymentAmount(value);
						}}
					/>
				</div>
				<br/>
				<div className={'field-info'}>
					<div className="field-label">{'Закрыть после оплаты:'}</div>
					<div className={'field-value'}>
						<CheckBox
							value={isClose}
							onValueChanged={({value}) => {
								setIsClose(value);
							}}
						/>
					</div>
				</div>
				{isClose ? (
					<div>
						<br/>
						<div className={'field-info'}>
							<div className="field-label">{'Тип обновления суммы заказа:'}</div>
							<SelectBox
								displayExpr={'Name'}
								valueExpr={'ID'}
								placeholder={'Выбрать'}
								items={amountRecalcTypes}
								onValueChanged={({value}) => {
									setAmountRecalcType(value);
								}}
							/>
						</div>
					</div>
				) : null}
				<br/>
				<div className={'field-info'}>
					<div className="field-label">{'Системный комментарий:'}</div>
					<TextArea
						onValueChanged={({value}) => {
							setRemark(value);
						}}
					/>
				</div>
				<div className={'button-wrapper'}>
					<Button
						text={'Подтвердить'}
						onClick={async () => {
							const request = await apiRequest({
								operation: 'MerchantOrder/IncomePayment',
								data: {
									Params: {
										MerchantOrderID: rowData.ID,
										PaymentSystemTypeID: paymentSystemTypeID,
										PaymentAmount: paymentAmount,
										PaymentCurrencyID: currencyID,
										IsClose: +isClose,
										AmountRecalcType: amountRecalcType,
										Remark: remark,
									},
								},
							});
							
							if (request.data.ResponseCode === SUCCESS) {
								notifyApp('Success', NOTIFY.SUCCESS);
								handleClose(true);
							} else {
								notifyApp(request.data.ResponseText);
							}
						}}
					/>
					<Button
						text={'Отмена'}
						onClick={handleClose}
					/>
				</div>
			</div>
		);
	}
	
	return (
		<AdminPopup
			logo={false}
			visible={visible}
			handleClose={handleClose}
			title={getTitle()}
		>
			{getPopupBody()}
		</AdminPopup>
	);
}

export default OrderActionPopup;