import React, {useEffect, useState} from 'react';
import {Button as ToolbarButton, Form, SelectBox, Tabs} from 'devextreme-react';
import {GroupItem, SimpleItem} from 'devextreme-react/form';
import DataGrid, {Button, Column} from 'devextreme-react/data-grid';
import PartnersComponent from '../clientsPopupFields/partners';
import ShowComment from '../clientsPopupFields/show-comment';
import {Template} from 'devextreme-react/core/template';
import {isConfirmedValue} from 'utils/functions';
import {notifyApp} from 'utils/notifyWrapper';
import {gridDate} from 'services/dataSourceFilterUtils';
import {apiRequest, getDocuments, getMerchantHistory} from 'services/async';
import ClientDetailInfo from './client-detail-info';
import {vars} from 'utils/variables';
import DocumentsGrid from '../common/documents-grid';
import AdminPopup from 'components/popup/admin-popup';
import DownloadDocument from '../common/download-document';
import CreateLegalClient from '../clientsPopupFields/banxe/create-legal-client';
import GetBanxeData from '../clientsPopupFields/banxe/get-banxe-data';

const {APP_CODES} = vars;

const formProps = {
	readOnly: true,
	showColonAfterLabel: true,
	labelLocation: 'left',
};

const gridProps = {
	showBorders: true,
	showColumnLines: true,
};

const tabs = [
	{id: 0, text: 'Основное', icon: 'mdi mdi-folder'},
	{id: 1, text: 'Персональные данные', icon: 'mdi mdi-account'},
	{id: 2, text: 'Бизнес данные', icon: 'mdi mdi-bank'},
	{id: 3, text: 'Верификация. Метаданные', icon: 'mdi mdi-shield-account-outline'},
	{id: 4, text: 'Партнеры', icon: 'mdi mdi-account-multiple'},
	{id: 5, text: 'Документы', icon: 'mdi mdi-file-document'},
	{id: 6, text: 'Комментарии', icon: 'mdi mdi-comment-processing-outline'},
];

const ClientDetailAccordion = (props) => {
	const {rowData, setInnerPopupFields, rights} = props;
	const [formData, setFormData] = useState(rowData);
	const [metaData, setMetaData] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [merchantHistory, setMerchantHistory] = useState([]);
	const [isShowAddPopup, setIsShowAddPopup] = useState(false);
	const [documentTypes, setDocumentTypes] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	
	useEffect(() => {
		if (isShowAddPopup) {
			closeAddPopup();
		}
	}, [documents]);
	
	useEffect(() => {
		getVerificationData().then((response) => {
			setFormData({...rowData, ...response});
		}).catch((errorText) => {
			notifyApp(errorText);
		});
		
		getVerificationMetaData().then((response) => {
			setMetaData(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
		
		getDocuments(rowData.MerchantID).then((response) => {
			setDocuments(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
		
		updateComments().catch((e) => {
			notifyApp(e);
		});
	}, []);
	
	const updateComments = () => {
		return getMerchantHistory(rowData.MerchantID).then((response) => {
			setMerchantHistory(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
	}
	
	const getVerificationData = async () => {
		const request = await apiRequest({
			operation: 'Merchant/VerificationData',
			data: {
				Params: {MerchantID: rowData.MerchantID},
			},
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	};
	
	const getVerificationMetaData = async () => {
		const request = await apiRequest({
			operation: 'Merchant/VerificationMetaData',
			data: {
				Params: {MerchantID: rowData.MerchantID},
			},
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response['VerificationMetaData'];
		} else {
			throw new Error(request.data.ResponseText);
		}
	};
	
	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.unshift(
			{
				location: 'before',
				template: 'customToolbar',
			},
		);
	};
	
	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<ToolbarButton
					text={'Добавить комментарий'}
					icon={'plus'}
					onClick={() => {
						setInnerPopupFields({
							component: ShowComment,
							type: {
								subtype: 'add-comment',
								remark: '',
								updateComments: updateComments,
							},
						});
					}}
				/>
			</div>
		);
	};
	
	const closeAddPopup = () => {
		setIsShowAddPopup(false);
	}
	
	useEffect(() => {
		if (isShowAddPopup) {
			fetchDocumentTypes().then((result) => {
				setDocumentTypes(result);
			}).catch((error) => {
				notifyApp(error);
			})
		}
	}, [isShowAddPopup]);
	
	useEffect(() => {
		if (Array.isArray(documentTypes) && documentTypes.length > 0) {
			setSelectedType(documentTypes[0].ID);
		}
	}, [documentTypes]);
	
	const fetchDocumentTypes = async () => {
		const request = await apiRequest({
			operation: 'DocumentType/List',
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			if (request.data.Response.DocumentType.length > 0) {
				return request.data.Response.DocumentType;
			} else {
				throw new Error('Ответ DocumentType/List не содержит ни одного элемента');
			}
		} else {
			throw new Error(request.data.ResponseText);
		}
	};
	
	const [selectedIndex, setSelectedIndex] = useState(0);
	
	useEffect(() => {
		if (selectedIndex === 4) {
			getClientConfigurationMatrix().then((config) => {
				setWallettoConfigurationList(config);
			}).catch((error) => {
				notifyApp(error);
			});
		}
	}, [selectedIndex]);
	
	const onTabChange = ({name, value}) => {
		if (name === 'selectedIndex') {
			setSelectedIndex(value);
		}
	}
	
	const [wallettoConfigurationList, setWallettoConfigurationList] = useState([]);
	
	const getClientConfigurationMatrix = async () => {
		const request = await apiRequest({
			operation: 'WallettoClient/GetClientConfigurationMatrix',
			data: {Params: {MerchantID: rowData.MerchantID}}
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response.ClientConfigurationMatrix;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}
	
	const getTabContent = () => {
		switch (selectedIndex) {
			case 0:
				return (
					<ClientDetailInfo rowData={rowData}/>
				);
			case 1:
				return (
					<Form
						formData={formData}
						{...formProps}
					>
						<SimpleItem label={{text: 'Email'}} render={() => {
							return (
								<div className={'dx-texteditor dx-editor-underlined dx-state-readonly'}>
									<span className={'dx-texteditor-input'}>{formData.UserEmail}</span>
									<i className={`field-value dx-icon-${isConfirmedValue(formData.UserIsEmailConfirmed)}`}/>
								</div>
							);
						}}/>
						<SimpleItem label={{text: 'Телефон'}} render={() => {
							return (
								<div className={'dx-texteditor dx-editor-underlined dx-state-readonly'}>
									<span className={'dx-texteditor-input'}>{formData.UserPhone}</span>
									<i className={`field-value dx-icon-${isConfirmedValue(formData.UserIsPhoneConfirmed)}`}/>
								</div>
							);
						}}/>
						<SimpleItem dataField={'Name'} label={{text: 'Имя'}}/>
						<SimpleItem label={{text: 'Имя (оригинал)'}} editorType={'dxTextBox'}
												editorOptions={{value: `${formData.FirstNameOrig}  ${formData.LastNameOrig}`}}/>
						<SimpleItem label={{text: 'Дата рождения клиента'}} editorType={'dxTextBox'}
												editorOptions={{value: gridDate(rowData['DateOfBirth'])}}/>
						<SimpleItem dataField={'CountryOfBirth'} label={{text: 'Страна рождения'}}/>
						<SimpleItem dataField={'PersonalCode'} label={{text: 'Персональный код (TIN)'}}/>
						<SimpleItem dataField={'Country'} label={{text: 'Страна клиента'}}/>
						<SimpleItem dataField={'Town'} label={{text: 'Город'}}/>
						<SimpleItem dataField={'TownOrig'} label={{text: 'Город (введенный)'}}/>
						<SimpleItem label={{text: 'Адрес'}} editorType={'dxTextBox'}
												editorOptions={{value: `${formData.Street} ${formData.PostCode}`}}/>
						<SimpleItem dataField={'StreetOrig'} label={{text: 'Адрес (введенный)'}}/>
					</Form>
				);
			case 2:
				return (
					<Form
						formData={formData}
						{...formProps}
					>
						<SimpleItem dataField={'BUcompanyName'} label={{text: 'Название'}}/>
						<SimpleItem dataField={'BUregNumber'} label={{text: 'Регистрационный номер'}}/>
						<SimpleItem dataField={'BUregCountry'} label={{text: 'Страна регистрации'}}/>
						<SimpleItem label={{text: 'Дата регистрации'}} editorType={'dxTextBox'}
												editorOptions={{value: gridDate(rowData['BUdateOfReg'], true)}}/>
						<SimpleItem dataField={'BUcategory'} label={{text: 'Тип бизнеса'}}/>
						
						<GroupItem caption={'Юридический адрес'}>
							<SimpleItem dataField={'BUemail'} label={{text: 'Email'}}/>
							<SimpleItem dataField={'BUcountry'} label={{text: 'Страна'}}/>
							<SimpleItem dataField={'BUtown'} label={{text: 'Город'}}/>
							<SimpleItem dataField={'BUstreet'} label={{text: 'Улица'}}/>
							<SimpleItem dataField={'BUpostCode'} label={{text: 'Почтовый индекс'}}/>
						</GroupItem>
						
						<SimpleItem dataField={'BUlegaladdress'} label={{text: 'Юридический адрес'}}/>
						<SimpleItem dataField={'BUpostaladdress'} label={{text: 'Почтовый адрес'}}/>
						<SimpleItem dataField={'BUphone'} label={{text: 'Телефон 1'}}/>
						<SimpleItem dataField={'BUphone2'} label={{text: 'Телефон 2'}}/>
						<SimpleItem dataField={'BUHEADfirstName'} label={{text: 'Имя руководителя'}}/>
						<SimpleItem dataField={'BUHEADlastName'} label={{text: 'Фамилия руководителя'}}/>
						<SimpleItem label={{text: 'Дата рождения руководителя'}} editorType={'dxTextBox'}
												editorOptions={{value: gridDate(rowData['BUHEADdateOfBirth'])}}/>
						<SimpleItem dataField={'BUHEADcountryOfBirth'} label={{text: 'Страна рождения руководителя'}}/>
						<SimpleItem dataField={'BUHEADemail'} label={{text: 'Email руководителя'}}/>
						<SimpleItem dataField={'BUHEADmessenger'} label={{text: 'Мессенджер'}}/>
						<SimpleItem dataField={'BUHEADcountry'} label={{text: 'Страна'}}/>
						<SimpleItem dataField={'BUHEADtown'} label={{text: 'Город'}}/>
						<SimpleItem dataField={'BUHEADstreet'} label={{text: 'Улица'}}/>
						<SimpleItem dataField={'BUHEADpostCode'} label={{text: 'Почтовый индекс'}}/>
						<SimpleItem dataField={'BUHEADphone'} label={{text: 'Телефон 1'}}/>
						<SimpleItem dataField={'BUHEADphone2'} label={{text: 'Телефон 2'}}/>
					</Form>
				);
			case 3:
				return (
					<DataGrid
						dataSource={metaData}
						{...gridProps}
					>
						<Column dataField={'ParamName'} caption={'Название'}/>
						<Column dataField={'ParamValue'} caption={'Значение'}/>
						<Column dataField={'InsDate'} caption={'Дата добавления'} format={'dd.MM.yy, HH:mm:ss'}
										dataType={'datetime'}/>
						<Column dataField={'LastUpdate'} caption={'Дата обновления'} format={'dd.MM.yy, HH:mm:ss'}
										dataType={'datetime'}/>
					</DataGrid>
				);
			case 4:
				const isActive = rights.includes('69') &&
					!rowData.BanxeClientExternalID &&
					rowData.UserTypeID === 2;
				
				const isActiveGetData = rights.includes('70') && rowData.BanxeClientExternalID;
				
				return (
					<div>
						<DataGrid
							showColumnHeaders={false}
							dataSource={wallettoConfigurationList}
							{...gridProps}
						>
							<Column dataField={'WallettoConfigurationName'} caption={''}/>
							<Column type={'buttons'}>
								<Button
									disabled={({row: {data}}) => {
										return !!data.WallettoClientID;
									}}
									text={'Создать'}
									onClick={({row: {data}}) => {
										setInnerPopupFields({
											component: PartnersComponent,
											type: 'CreateClient',
											callback: setWallettoConfigurationList,
											params: {
												MerchantID: rowData.MerchantID,
												WallettoConfigurationID: data.WallettoConfigurationID,
											},
										});
									}}
								/>
								<Button
									disabled={({row: {data}}) => {
										return !data.WallettoClientID;
									}}
									text={'Просмотреть'}
									onClick={({row: {data}}) => {
										setInnerPopupFields({
											component: PartnersComponent,
											type: 'GetClient',
											params: {
												WallettoClientID: data.WallettoClientID,
											},
										});
									}}
								/>
								<Button
									disabled={({row: {data}}) => {
										return !data.WallettoClientID;
									}}
									text={'Обновить'}
									onClick={({row: {data}}) => {
										setInnerPopupFields({
											component: PartnersComponent,
											type: 'SetClient',
											params: {
												WallettoClientID: data.WallettoClientID,
											},
										});
									}}
								/>
							</Column>
						</DataGrid>
						
						<DataGrid
							showColumnHeaders={false}
							dataSource={[{'Banxe': 'Banxe'}]}
							{...gridProps}
						>
							<Column dataField={'Banxe'} customizeText={() => 'Banxe'}/>
							<Column type={'buttons'}>
								<Button
									disabled={!isActive}
									text={'Создать'}
									onClick={() => {
										setInnerPopupFields({
											component: CreateLegalClient,
											type: 'banxe',
										});
									}}
								/>
								<Button
									disabled={!isActiveGetData}
									text={'Просмотреть'}
									onClick={() => {
										setInnerPopupFields({
											component: GetBanxeData,
											type: 'getBanxeData',
										});
									}}
								/>
								<Button
									disabled={true}
									text={'Обновить'}
								/>
							</Column>
						</DataGrid>
						
					</div>
				);
			case 5:
				return (
					<div>
						<AdminPopup
							container={'.app'}
							logo={false}
							handleClose={closeAddPopup}
							visible={isShowAddPopup}
							title={'Загрузка файлов'}
						>
							<SelectBox
								dataSource={documentTypes}
								displayExpr={'Name'}
								valueExpr={'ID'}
								value={selectedType}
								onValueChanged={({value}) => {
									setSelectedType(value)
								}}
							/>
							<DownloadDocument
								key={isShowAddPopup.toString()}
								merchantId={rowData.MerchantID}
								documentType={{selectedType: selectedType}}
								setDocuments={setDocuments}
							/>
						</AdminPopup>
						<ToolbarButton
							className={'gap-button'}
							type={'success'}
							disabled={!(rowData.VmVerificationSystemID === 2 && rights.includes('34'))}
							onClick={() => {
								setIsShowAddPopup(true);
							}}
							text={'Добавить'}
						/>
						<DocumentsGrid documents={documents}/>
					</div>
				);
			case 6:
				return (
					<DataGrid
						dataSource={merchantHistory}
						onToolbarPreparing={onToolbarPreparing}
						{...gridProps}
					>
						<Column dataField={'InsDate'} caption={'Дата'} format={'dd.MM.yy, HH:mm:ss'} dataType={'datetime'}/>
						<Column dataField={'BoMerchantHistoryTypeName'} caption={'Параметр'}/>
						<Column dataField={'Remark'} caption={'Комментарий'}/>
						<Column type={'buttons'}>
							<Button
								text={'Подробнее'}
								onClick={({row}) => {
									setInnerPopupFields({
										component: ShowComment,
										type: {
											subtype: 'show-comment',
											remark: row.data.Remark,
										},
									});
								}}
							/>
						</Column>
						<Template name={'customToolbar'} render={toolbarItemRender}/>
					</DataGrid>
				);
			default:
				return null;
		}
	}
	
	return (
		<div className={'accordion-items-wrapper'}>
			<div className={'tabs-wrapper-vertical'}>
				<div className={'tabs-container'}>
					<Tabs
						id={'client-detail-tabs'}
						width={'auto'}
						iconPosition={'start'}
						dataSource={tabs}
						selectedIndex={selectedIndex}
						onOptionChanged={onTabChange}
						orientation={'vertical'}
						stylingMode={'secondary'}
						scrollByContent={true}
						scrollingEnabled={true}
					/>
				</div>
				<div className={'tab-content'}>
					{getTabContent()}
				</div>
			</div>
		</div>
	);
};

export default ClientDetailAccordion;