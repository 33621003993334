import React from 'react';
import Form, {SimpleItem} from 'devextreme-react/form';
import {getRestrictionsValue, combineValues} from 'utils/functions';
import {gridDate} from 'services/dataSourceFilterUtils';
import {TextBox} from 'devextreme-react';

const ClientDetailInfo = (props) => {
	const {rowData} = props;
	
	return (
		<Form
			id='client-detail-info'
			formData={rowData}
			readOnly={true}
			showColonAfterLabel={true}
			labelLocation={'left'}
		>
			<SimpleItem dataField={'UserTypeName'} label={{text: 'Тип клиента'}}/>
			<SimpleItem dataField={'UserStatusName'} label={{text: 'Регистрация'}}/>
			<SimpleItem dataField={'InsDate'} label={{text: 'Дата регистрации'}} editorType={'dxTextBox'}
									editorOptions={{value: gridDate(rowData['InsDate'], true)}}/>
			<SimpleItem dataField={'Host'} label={{text: 'Источник'}}/>
			<SimpleItem dataField={'MerchantCategoryName'} label={{text: 'Категория'}}/>
			<SimpleItem
				label={{text: 'Блокировки'}}
				render={() => {
					const values = combineValues(rowData, true);
					
					return (
						<div className={'dx-show-invalid-badge dx-textarea dx-textbox dx-texteditor dx-editor-filled dx-state-readonly dx-widget dx-visibility-change-handler'}>
							<div className={'dx-texteditor-input'}>
								{values.map((value, index) => {
									return (
										<p className={'text-area-value'} key={index}>{value}</p>
									);
								})}
							</div>
						</div>
					);
				}}
			/>
			<SimpleItem
				render={() => {
					return (
						<div className={'verification-item'}>
							{rowData.VmApplicantUrl ? (
								<div className={'icon-wrapper'}>
									<a target={'_blank'} href={rowData.VmApplicantUrl}>
										<img src={'/images/person_search.svg'} alt='VmApplicantUrl'/>
									</a>
								</div>
							) : null}
							<TextBox
								defaultValue={rowData.VmVerificationStatusName}
								readOnly
							/>
						</div>
					);
				}}
				label={{text: 'Верификация'}}
			/>
			<SimpleItem dataField={'VmVerificationLevelName'} label={{text: 'Уровень'}}/>
			<SimpleItem dataField={'VmLastUpdate'} label={{text: 'Дата верификации'}} editorType={'dxTextBox'}
									editorOptions={{value: gridDate(rowData['VmLastUpdate'], true)}}/>
			<SimpleItem label={{text: 'Ограничения'}} editorType={'dxTextBox'}
									editorOptions={{value: getRestrictionsValue(rowData)}}/>
			<SimpleItem dataField={'ReferralCode'} label={{text: 'Код для реферальной программы'}}/>
		</Form>
	);
};

export default ClientDetailInfo;