import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	HeaderFilter,
	Item,
	Lookup,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource, getDictionaryDataSource} from 'services/dataSource';
import {getCommissions} from 'services/requestConsts';
import {vars} from 'utils/variables';
import {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';

const {TRANSACTION, PAYER_TYPE_DICTIONARY} = vars;

const Commissions = ({rights}) => {
	const gridActionRight = rights.includes('66');
	const [commissionsDs] = useState(getDataSource(getCommissions()));
	const [isFilterShow, setIsFilterShow] = useState(false);
	const gridRef = useRef(null);
	
	const [payGateTokenDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PayGateToken',
			withEmptyValue: true,
			names: ['ID', 'DisplayName'],
		}));
	
	const [currencyDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'Currency',
		}),
	);
	
	const [paymentTypeDictionary] = useState(
		getDictionaryDataSource({
			objectName: 'PaymentType',
		}));
	
	const [paymentSystemTypeDictionary] = useState(
		getDictionaryDataSource({
				objectName: 'PaymentSystemType',
			},
		));
	
	const [feePayerTypeDictionary] = useState(
		getDictionaryDataSource({
				objectName: 'FeePayerType',
				names: ['ID', 'Code'],
			},
		));
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const calculateCellValue = ({AmountPerc}) => {
		let value = AmountPerc;
		
		if (value === undefined) {
			value = 0;
		}
		
		return (value * 100).toPrecision(2);
	}
	
	const onRowUpdating = (e) => {
		if (e.newData.AmountPerc !== undefined) {
			e.newData.AmountPerc = Number((e.newData.AmountPerc / 100).toFixed(4));
		}
	}
	
	const onRowInserting = (e) => {
		if (e.data.AmountPerc !== undefined) {
			e.data.AmountPerc = Number((e.data.AmountPerc / 100).toFixed(4));
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					dataSource={commissionsDs}
					hoverStateEnabled
					onRowUpdating={onRowUpdating}
					onRowInserting={onRowInserting}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item name={'addRowButton'} options={{
							text: 'Добавить',
							stylingMode: 'outlined',
							elementAttr: {class: 'round-toolbar-button'},
						}} showText="always"/>
					</Toolbar>
					<RemoteOperations
						filtering
					/>
					{gridActionRight ? (
						<Editing
							allowUpdating
							allowAdding
							allowDeleting
						/>
					) : null}
					<Scrolling
						mode="infinite"
						showScrollbar="onHover"
					/>
					<HeaderFilter visible={true}/>
					<FilterRow visible={isFilterShow}/>
					
					<Column dataField={'PayGateTokenID'} caption={'Счет.Партнер'}>
						<Lookup
							dataSource={payGateTokenDictionary}
							displayExpr={'DisplayName'}
							valueExpr={'ID'}
						/>
					</Column>
					<Column dataField={'CurrencyID'} caption={'Счет.Валюта'}>
						<Lookup
							dataSource={currencyDictionary}
							displayExpr={'Code'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column dataField={'PaymentTypeID'} caption={'Тип операции'} width={200}>
						<Lookup
							dataSource={paymentTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column dataField={'PaymentSystemTypeID'} caption={'Платежная система'} width={200}>
						<Lookup
							dataSource={paymentSystemTypeDictionary}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column dataField={'FeePayerTypeID'} caption={'Тип плательщика'}>
						<Lookup
							dataSource={feePayerTypeDictionary}
							displayExpr={'Code'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					<Column dataField={'AccountOwnerType'} caption={'AccountOwnerType'}>
						<Lookup
							dataSource={PAYER_TYPE_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
					
					<Column dataField={'MerchantID'} caption={'ID мерчанта'}/>
					<Column dataField={'AmountMin'} caption={'Комиссия.Мин'}>
						<RequiredRule/>
					</Column>
					<Column dataField={'AmountMax'} caption={'Комиссия.Макс'}>
						<RequiredRule/>
					</Column>
					<Column dataField={'AmountConst'} caption={'Комиссия.Фикс'}>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'AmountPerc'}
						caption={'Комиссия.%'}
						calculateCellValue={calculateCellValue}
					>
						<RequiredRule/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default Commissions;