import React, {useEffect, useMemo, useState} from 'react';
import DataGrid, {Button as GridButton, Column, Item, Selection, Toolbar} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {vars} from 'utils/variables';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {getCommonMerchantRestrictionData, haveAnyOf} from 'utils/functions';
import RestrictPopup from 'pages/clients/clients-page/clientsPopupFields/restrict/restrict-popup';
import ChangeVerification from 'pages/clients/clients-page/clientsPopupFields/change-verification';
import ChangeCategory from 'pages/clients/clients-page/clientsPopupFields/change-category';
import AdminPopup from 'components/popup/admin-popup';
import CreateNewAccount from './create-new-account';
import CreateNewCard from './create-new-card';
import CloseCard from './close-card';

const {
	APP_CODES: {SUCCESS},
	MERCHANT: {RIGHTS, ACCOUNT_STATUS, CANCEL_ACCOUNT_OPERATION},
} = vars;


const ChangeMerchantParameters = (props) => {
	const {rowData: {MerchantID}, updateSelectedMerchant, rights} = props;
	const [merchant, setMerchant] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [cards, setCards] = useState([]);
	const [selectedAccounts, setSelectedAccounts] = useState([]);
	const [selectedCards, setSelectedCards] = useState([]);
	const [restrictHistory, setRestrictHistory] = useState([]);
	const [restrictData, setRestrictData] = useState({
		confirmOperation: '',
		remarkText: '',
		isCancelAccountOperation: false,
	});
	const [reasonsList, setReasonsList] = useState([]);
	const [isVisibleCategory, setIsVisibleCategory] = useState(false);
	const [isVisibleVerification, setIsVisibleVerification] = useState(false);
	const [isVisibleCreateNew, setIsVisibleCreateNew] = useState(false);
	const [commonMerchantRestrictionData, setCommonMerchantRestrictionData] = useState(null);
	
	const updateMerchantVariables = async (id) => {
		const merchant = await updateSelectedMerchant(id);
		setMerchant(merchant);
		setCommonMerchantRestrictionData(getCommonMerchantRestrictionData(merchant));
	};
	
	const updateAccountsVariable = async (id) => {
		const requestAccount = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: id,
				},
				Page: -1,
			},
		});
		
		const requestRestrictHistory = await apiRequest({
			operation: 'BoMerchantHistory/List',
			data: {
				Sorts: ['-InsDate'],
				Filters: {
					MerchantID: id,
				},
			},
		});
		
		if (requestRestrictHistory.data.ResponseCode === SUCCESS) {
			setRestrictHistory(requestRestrictHistory.data.Response.BoMerchantHistory);
		} else {
			notifyApp(requestRestrictHistory.data.ResponseText);
		}
		
		if (requestAccount.data.ResponseCode === SUCCESS) {
			setAccounts(requestAccount.data.Response.Account);
		} else {
			notifyApp(requestAccount.data.ResponseText);
		}
	};
	
	const updateMerchantEffect = () => {
		updateMerchantVariables(MerchantID).catch((e) => {
			console.error(e);
		});
		updateAccountsVariable(MerchantID).catch((e) => {
			console.error(e);
		});
		fetchReasonsList().catch((e) => {
			console.error(e);
		});
	};
	
	const fetchCards = async () => {
		const request = await apiRequest({
			operation: 'WallettoCard/List',
			data: {
				Filters: {
					MerchantID: MerchantID,
				},
				Page: -1,
			},
		});
		
		if (request.data.ResponseCode === SUCCESS) {
			setCards(request.data.Response.WallettoCard);
		} else {
			throw Error(request.data.ResponseText);
		}
	}
	
	useEffect(() => {
		updateMerchantEffect();
		fetchCards().catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	const restrictButton = ({data: {ID}}) => {
		const {UserIsBan, IsRestrictAddAccount, IsRestrictAddExternalAccount, UserRights} = merchant;
		let buttonProps;
		
		switch (ID) {
			case 1:
				if (UserIsBan) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения userIsBan');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictUser',
							});
						},
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить userIsBan');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictUser',
							});
						},
					};
				}
				break;
			case 5:
				buttonProps = {
					hint: 'Смена категории',
					text: 'Смена категории',
					width: 200,
					onClick: () => {
						showCategoryPopup();
					},
				};
				break;
			case 4:
				buttonProps = {
					hint: 'Смена верификации',
					text: 'Смена верификации',
					width: 200,
					disabled: !UserRights.includes(RIGHTS.CHANGE_VERIFICATION_VISIBLE),
					onClick: () => {
						showVerificationPopup();
					},
				};
				break;
			case 3:
				if (IsRestrictAddExternalAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddExternalAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictAddExternalAccount',
							});
						},
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictAddExternalAccount',
							});
						},
					};
				}
				break;
			case 2:
			default:
				if (IsRestrictAddAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictAddAccount',
							});
						},
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictAddAccount',
							});
						},
					};
				}
		}
		
		return (
			<Button
				{...buttonProps}
			/>
		);
	};
	
	const showAllRemarkOnDblClick = ({data}) => {
		setRestrictData({
			...restrictData,
			confirmOperation: 'showAllRemarkOnDblClick',
			remarkText: data.Remark,
		});
	};
	
	const showAllRemark = ({row}) => {
		setRestrictData({
			...restrictData,
			confirmOperation: 'showAllRemark',
			remarkText: row.data.Remark,
		});
	};
	
	const getNewArrestStatus = (rowData) => {
		const {value, data} = rowData;
		
		let status = 'undefined';
		
		if (value === ACCOUNT_STATUS.IS_ACTIVE) {
			status = data.AccountStatusName;
		} else if (value === ACCOUNT_STATUS.IS_ARRESTED) {
			status = 'Arrested';
		}
		
		return (
			<span>{status}</span>
		);
	};
	
	const fetchReasonsList = async () => {
		const reason = await apiRequest({
			operation: 'WallettoCardFullBlockReason/List',
		});
		
		if (reason.data.ResponseCode === SUCCESS) {
			setReasonsList(reason.data.Response.WallettoCardFullBlockReason);
		} else {
			notifyApp(reason.data.ResponseText);
		}
	};
	
	const closeRestrictPopup = () => {
		setRestrictData({
			...restrictData,
			confirmOperation: '',
			remarkText: '',
		});
	};
	
	const showCategoryPopup = () => {
		setIsVisibleCategory(true);
	};
	
	const closeCategoryPopup = () => {
		setIsVisibleCategory(false);
	};
	
	const showVerificationPopup = () => {
		setIsVisibleVerification(true);
	};
	
	const closeVerificationPopup = () => {
		setIsVisibleVerification(false);
	};
	
	const showCreateNewAllowedPopup = () => {
		setIsVisibleCreateNew(true);
	};
	
	const closeCreateNewAllowedPopup = () => {
		setIsVisibleCreateNew(false);
	};
	
	const [matrix, setMatrix] = useState(null);
	
	const closeCreateNewCardPopup = () => {
		setMatrix(null);
	}
	
	const createCard = async ({component}) => {
		const operationName = 'AllowedCreateMatrix';
		component.option('disabled', true);
		
		try {
			const request = await apiRequest({
				operation: `Account/${operationName}`,
				data: {
					Params: {
						MerchantID: MerchantID,
					}
				},
			});
			
			if (request.data.ResponseCode === SUCCESS) {
				console.log('request.data.Response[operationName]');
				console.log(request.data.Response[operationName]);
				setMatrix(request.data.Response[operationName]);
			} else {
				throw new Error(request.data.ResponseText);
			}
		} catch (error) {
			notifyApp(error);
		} finally {
			component.option('disabled', false);
		}
	}
	
	const [isClosePopupVisible, setIsClosePopupVisible] = useState(false);
	
	const cancelCard = () => {
		setIsClosePopupVisible(true);
	}
	
	const closeCancelCardPopup = () => {
		setIsClosePopupVisible(false);
	}
	
	const buttonStates = useMemo(() => {
		const isSelectedAccount = selectedAccounts.length > 0;
		const isSelectedCard = selectedCards.length > 0;
		const haveArrestRight = rights.includes(RIGHTS.ARREST);
		const haveRemoveRight = rights.includes(RIGHTS.CANCEL_ACCOUNT);
		const accounts = haveAnyOf(selectedAccounts);
		
		return {
			isArrestButtonDisabled: !(isSelectedAccount && haveArrestRight && accounts.haveActive),
			isRemoveArrestButtonDisabled: !(isSelectedAccount && haveArrestRight && accounts.haveArrested),
			isRemoveCardButtonDisabled: !(isSelectedCard && haveRemoveRight),
		};
	}, [selectedAccounts, selectedCards, rights, haveAnyOf]);
	
	return (
		<div className={'restriction-field-wrap'}>
			<AdminPopup
				key={`changeCategory-${isVisibleCategory}`}
				container={'.app'}
				logo={false}
				handleClose={closeCategoryPopup}
				visible={isVisibleCategory}
				title={''}
				maxWidth={700}
			>
				<ChangeCategory
					rowData={merchant}
					closePopup={closeCategoryPopup}
					updateMerchant={updateMerchantEffect}
				/>
			</AdminPopup>
			<AdminPopup
				key={`changeVerification-${isVisibleVerification}`}
				container={'.app'}
				logo={false}
				handleClose={closeVerificationPopup}
				visible={isVisibleVerification}
				title={''}
				maxWidth={700}
			>
				<ChangeVerification
					rowData={merchant}
					closePopup={closeVerificationPopup}
					updateMerchant={updateMerchantEffect}
				/>
			</AdminPopup>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeCreateNewAllowedPopup}
				visible={isVisibleCreateNew}
				title={'Создание счета'}
				maxWidth={700}
			>
				{isVisibleCreateNew ? (
					<CreateNewAccount
						key={isVisibleCreateNew}
						rowData={merchant}
						closePopup={closeCreateNewAllowedPopup}
						updateMerchant={updateMerchantEffect}
					/>
				) : null}
			</AdminPopup>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeCreateNewCardPopup}
				visible={!!matrix}
				title={'Создать карту'}
				maxWidth={700}
			>
				{matrix ? (
					<CreateNewCard
						merchantID={MerchantID}
						accounts={accounts}
						matrix={matrix}
						closePopup={closeCreateNewCardPopup}
						updateCardList={fetchCards}
					/>
				) : null}
			</AdminPopup>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeCancelCardPopup}
				visible={isClosePopupVisible}
				title={'Аннулировать'}
				maxWidth={700}
			>
				{selectedCards.length > 0 ? (
					<CloseCard
						selectedCards={selectedCards}
						closePopup={closeCancelCardPopup}
					/>
				) : null}
			</AdminPopup>
			<RestrictPopup
				rowData={merchant}
				confirmOperation={restrictData.confirmOperation}
				selectedAccounts={selectedAccounts}
				remarkText={restrictData.remarkText}
				isCancelAccount={restrictData.isCancelAccountOperation}
				reasonsList={reasonsList}
				updateCallback={updateMerchantEffect}
				closePopup={closeRestrictPopup}
			/>
			<div className={'dx-fieldset restriction-fieldset'}>
				<span className={'dx-fieldset-header'}>Общие параметры</span>
				<DataGrid
					dataSource={commonMerchantRestrictionData}
				>
					<Column
						dataField={'ID'}
						caption={'№пп'}
						width={70}
					/>
					<Column
						dataField={'Type'}
						caption={'Тип ограничения (операция)'}
					/>
					<Column
						dataField={'Status'}
						caption={'Текущий статус ограничения'}
					/>
					<Column type={'buttons'}>
						<GridButton
							render={restrictButton}
						/>
					</Column>
				</DataGrid>
			</div>
			<div className={'dx-fieldset restriction-fieldset'}>
				<span className={'dx-fieldset-header'}>Счета</span>
				<DataGrid
					dataSource={accounts}
					noDataText={'Нет счетов'}
					onSelectionChanged={({selectedRowKeys}) => {
						setSelectedAccounts(selectedRowKeys);
					}}
				>
					<Toolbar>
						<Item location={'before'}>
							<Button
								text={'Арест'}
								disabled={buttonStates.isArrestButtonDisabled}
								onClick={() => {
									setRestrictData({
										confirmOperation: 'Account/Arrest',
										isCancelAccountOperation: false,
									});
								}}
							/>
						</Item>
						<Item location={'before'}>
							<Button
								text={'Снять арест'}
								disabled={buttonStates.isRemoveArrestButtonDisabled}
								onClick={() => {
									setRestrictData({
										confirmOperation: 'Account/Unarrest',
										isCancelAccountOperation: false,
									});
								}}
							/>
						</Item>
						<Item location={'before'}>
							<Button
								text={'Создать счет'}
								disabled={!rights.includes(RIGHTS.CREATE_ALLOWED_ACCOUNT)}
								onClick={showCreateNewAllowedPopup}
							/>
						</Item>
					</Toolbar>
					
					<Selection
						mode='multiple'
						selectAllMode={'allPages'}
						showCheckBoxesMode={'onClick'}
					/>
					
					<Column
						dataField='ID'
						caption='№пп'
						width={70}
					/>
					<Column
						dataField='PaymentSystemTypeName'
						caption='Платежная система'
					/>
					<Column
						dataField='PaymentSystemTypeInternalName'
						caption='Внутреннее название ПС'
						width={165}
					/>
					<Column
						dataField='Code'
						caption='Внутренний счет'
					/>
					<Column
						caption='Внешний счет'
						cellRender={({data}) => {
							const {WallettoParams, BanxeParams} = data;
							return WallettoParams ? WallettoParams?.ExternalAccountID : BanxeParams?.ExternalAccountID;
						}}
					/>
					<Column
						dataField='CurrencyCode'
						caption='Валюта'
					/>
					<Column
						dataField='IsArrest'
						caption='Текущий статус'
						cellRender={getNewArrestStatus}/>
				</DataGrid>
			</div>
			<div className={'dx-fieldset restriction-fieldset'}>
				<span className={'dx-fieldset-header'}>Карты</span>
				<DataGrid
					dataSource={cards}
					noDataText={'Нет карт'}
					onSelectionChanged={(props) => {
						const {selectedRowKeys}=props;
						setSelectedCards(selectedRowKeys);
					}}
				>
					<Toolbar>
						<Item location={'before'}>
							<Button text={'Создать карту'} onClick={createCard} disabled={!rights.includes(RIGHTS.CREATE_ACCOUNT)}/>
						</Item>
						<Item location={'before'}>
							<Button
								disabled={buttonStates.isRemoveCardButtonDisabled}
								text={'Аннулировать'}
								onClick={cancelCard}
							/>
						</Item>
					</Toolbar>
					<Selection
						mode='multiple'
						selectAllMode={'allPages'}
						showCheckBoxesMode={'onClick'}
					/>
					
					<Column
						dataField='ID'
						caption='№пп'
						width={70}
					/>
					<Column
						dataField='PaymentSystemTypeName'
						caption='Платежная система'
					/>
					<Column
						dataField='PaymentSystemTypeInternalName'
						caption='Внутреннее название ПС'
						width={165}
					/>
					<Column
						dataField='Iban'
						caption='Внутренний счет'
					/>
					<Column
						dataField='ExternalID'
						caption='Внешний счет'
					/>
					<Column
						dataField='CardNumber'
						caption='Карта'
					/>
					<Column
						dataField='CurrencyCode'
						caption='Валюта'
					/>
					<Column
						dataField='WallettoCardStatusName'
						caption='Текущий статус'
					/>
				</DataGrid>
			</div>
			<div className={'dx-fieldset'}>
					<span className={'dx-fieldset-header'}>
						История действий по мерчанту
					</span>
				{merchant && merchant.UserRights.includes(RIGHTS.RESTRICTION_HISTORY) ? (
					<DataGrid
						dataSource={restrictHistory}
						onRowDblClick={showAllRemarkOnDblClick}
					>
						<Column
							dataField={'InsDate'}
							caption={'Дата'}
						/>
						<Column
							dataField={'MerchantName'}
							caption={'Мерчант'}
						/>
						<Column
							dataField={'BoMerchantHistoryTypeName'}
							caption={'Параметр'}
						/>
						<Column
							dataField={'BoUserName'}
							caption={'Пользователь Back Office'}
						/>
						<Column
							dataField={'AccountCode'}
							caption={'Название счета'}
						/>
						<Column
							dataField={'Remark'}
							caption={'Комментарий'}
						/>
						<Column
							type={'buttons'}
							buttons={[
								{
									hint: 'Подробнее',
									text: 'Подробнее',
									onClick: showAllRemark,
								},
							]}
						/>
					</DataGrid>
				) : (
					<span>Нет прав для просмотра истории действий по ограничениям мерчанта</span>
				)}
			</div>
		</div>
	);
};

export default ChangeMerchantParameters;
