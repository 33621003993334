export function makeCalculateFilterExpression(field, operation = '=') {
	return (filterValue) => {
		if (Array.isArray(filterValue) && !filterValue.length)
			return;

		return [field, operation, filterValue];
	}
}

export function makeBooleanFilterExpression(filterValue, selectedFilterOperation) {
	let valueToApi;
	if (filterValue === true) {
		valueToApi = 1;
	} else if (filterValue === false) {
		valueToApi = 0;
	} else {
		return;
	}
	return [this.dataField, selectedFilterOperation, valueToApi];
}
