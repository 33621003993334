import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getMerchantOrder} from 'services/requestConsts';
import {getAppliedFilters, getFromLocalStorage, getFromSessionStorage} from 'utils/functions';
import {menuItems} from 'app-routes';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import OrderActionPopup from './actionPopup/order-action-popup';
import {Button} from 'devextreme-react';
import {formatNumber} from 'devextreme/localization';
import {withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

const {
	APP_CODES: {SUCCESS},
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {ORDERS_PAGE}},
	PRECISION,
	NOTIFY,
} = vars;

const Orders = (props) => {
	const {history, rights} = props;
	const merchantOrderID = getFromSessionStorage('filter', 'merchantOrderID');
	const isClosePaymentButtonVisible = ({row: {data}}) => {
		// https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=43384924
		return rights.includes('45') && data.MerchantOrderStatusID === 4;
	}
	const isCreatePaymentButtonVisible = ({row: {data}}) => {
		return rights.includes('71') && data.MerchantOrderStatusID === 4;
	}
	const gridRef = useRef(null);
	const [merchantOrder, setMerchantOrder] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(() => {
		return !!merchantOrderID;
	});
	
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	
	useEffect(() => {
		const dataSource = getDataSource(getMerchantOrder(merchantOrderID));
		setMerchantOrder(dataSource);
		window.sessionStorage.removeItem('filter');
	}, []);
	
	const [actionRowData, setActionRowData] = useState(null);
	
	const closePopup = (isGridNeedRefresh = false) => {
		setActionRowData(null);
		
		if (isGridNeedRefresh && gridRef.current) {
			gridRef.current.instance.refresh();
		}
	}
	
	const toggleFilter = () => {
		setShowFilter(!showFilter);
	}
	
	const clearFilters = () => {
		if (gridRef.current) {
			gridRef.current.instance.clearFilter();
		}
	}
	
	const exportGrid = () => {
		setIsShowExportDatePopup(!isShowExportDatePopup);
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<OrderActionPopup
				visible={!!actionRowData}
				handleClose={closePopup}
				rowData={actionRowData}
			/>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={gridRef}
					id={'grid-acc-statement'}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
					dataSource={merchantOrder}
					defaultFilterValue={merchantOrderID ? ['ID', '=', merchantOrderID] : null}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button icon={'xlsxfile'} onClick={exportGrid}/>
						</Item>
					</Toolbar>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar="onHover"
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						hidingPriority={14}
					/>
					<Column
						dataField={'ID'}
						caption={'ID'}
						hidingPriority={13}
					/>
					<Column
						dataField={'MerchantID'}
						caption={'Мерчант'}
						hidingPriority={12}
					/>
					<Column
						dataField={'MerchantApiProfileID'}
						caption={'ID профиля'}
						hidingPriority={11}
					/>
					<Column
						dataField={'ExternalOrderID'}
						caption={'Внешний ID'}
						hidingPriority={10}
					/>
					<Column
						dataField={'MerchantOrderTypeName'}
						caption={'Тип заказа'}
						hidingPriority={9}
					/>
					<Column
						dataField={'CurrencyCode'}
						caption={'Валюта заказа'}
						hidingPriority={8}
					/>
					<Column
						dataField={'OrigAmount'}
						caption={'Сумма заказа'}
						hidingPriority={7}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						// minWidth={130}
						alignment={'right'}
						calculateCellValue={(row) => {
							const paymentAmount = formatNumber(row.PaymentAmount, {
								type: 'fixedPoint',
								precision: PRECISION,
							});
							const amount = formatNumber(row.Amount, {
								type: 'fixedPoint',
								precision: PRECISION,
							});
							return `${paymentAmount} | ${amount}`;
						}}
						caption={'Оплачено'}
						hidingPriority={6}
					/>
					<Column
						dataField={'FeeAmount'}
						caption={'Комиссия'}
						hidingPriority={5}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'MerchantOrderStatusName'}
						caption={'Статус'}
						hidingPriority={4}
					/>
					<Column
						dataField={'PaymentID'}
						caption={'ID Платежа'}
						hidingPriority={3}
					/>
					<Column
						dataField={'CustomerID'}
						caption={'Customer ID'}
						hidingPriority={2}
					/>
					<Column
						dataField={'CustomerEmail'}
						caption={'Email'}
						hidingPriority={1}
					/>
					<Column
						dataField={'CustomerPhone'}
						caption={'Phone'}
						hidingPriority={0}
					/>
					<Column
						type={'buttons'}
						buttons={[
							{
								hint: 'Платежи',
								icon: 'mdi mdi-format-align-justify',
								onClick: ({row}) => {
									window.sessionStorage.setItem('filter', JSON.stringify({
										merchantOrderID: row.data.ID,
									}));
									history.push(menuItems.checkout[1].url);
								},
							},
							{
								visible: isClosePaymentButtonVisible,
								hint: 'Закрыть заказ',
								icon: 'mdi mdi-package-variant-closed',
								onClick: ({row}) => {
									setActionRowData({...row.data, type: 'close'});
								},
							},
							{
								visible: isCreatePaymentButtonVisible,
								hint: 'Создать оплату по заказу',
								icon: 'mdi mdi-credit-card',
								onClick: ({row}) => {
									setActionRowData({...row.data, type: 'create'});
								},
							}
						]}
					/>
				</DataGrid>
			</div>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'OrdersExport'}
				getGridParams={getMerchantOrder}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
			/>
		</div>
	);
};

export default withRouter(Orders);
