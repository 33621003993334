import React from 'react';
import {withRouter} from 'react-router';
import {Header, Footer} from 'components';
import {sizes, subscribe, unsubscribe} from 'utils/media-query';
import {menuPreInitPatch} from 'utils/patches';
import './admin-layout.scss';

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: sizes()['screen-large'],
      temporaryMenuOpened: false,
      ...this.drawerConfig
    };

    this.scrollViewRef = React.createRef();
    this.menuPatch = menuPreInitPatch(this);
  }

  render() {
    const {allowedRoutes, title, userMenuItems} = this.props;

    return (
      <div className={'side-nav-outer-toolbar'}>
        <div id={'ctx-wrapper'} onClick={() => {
          const ctxWrapper = document.getElementById('ctx-wrapper');
          const ctxMenu = document.getElementById('ctx-menu');
          ctxWrapper.classList.toggle('visible');
          ctxMenu.classList.toggle('visible');
        }}></div>
        <Header
          allowedRoutes={allowedRoutes}
          className={'layout-header'}
          menuToggleEnabled
          userMenuItems={userMenuItems}
          toggleMenu={() =>
            this.setState({menuOpened: !this.state.menuOpened})
          }
          title={title}
        />
        
        <div className={'layout-body' + this.menuPatch.cssClass}>
          <div className={'content'}>
            {React.Children.map(this.props.children, item => {
              return item.type !== Footer && item;
            })}
          </div>
        </div>
      </div>
    );
  }
  
  componentDidMount() {
    subscribe(this.updateDrawer);
  }
  
  componentWillUnmount() {
    unsubscribe(this.updateDrawer);
  }
  
  updateDrawer = () => {
    this.setState({...this.drawerConfig});
  };
  
  get drawerConfig() {
    const isXSmall = sizes()['screen-x-small'];
    const isLarge = sizes()['screen-large'];

    return {
      menuMode: isLarge ? 'shrink' : 'overlap',
      menuRevealMode: isXSmall ? 'slide' : 'expand',
      minMenuSize: isXSmall ? 0 : 60,
      shaderEnabled: !isLarge
    };
  }
}

export default withRouter(AdminLayout);
