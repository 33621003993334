import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	Editing,
	FilterRow,
	Item,
	Lookup,
	Paging,
	RemoteOperations,
	Toolbar,
} from 'devextreme-react/data-grid';
import {vars} from 'utils/variables';
import {getDataSource} from 'services/dataSource';
import {getBlockchain} from 'services/requestConsts';
import {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';

const {TRANSACTION, SPREAD_DICTIONARY} = vars;

const Blockchain = (props) => {
	const {rights} = props;
	const gridActionRight = rights.includes('56');
	const [isFilterShow, setIsFilterShow] = useState(false);
	const blockchainRef = useRef(null);
	const [currencyDs] = useState(getDataSource(getBlockchain()));
	
	const toggleFilter = () => {
		setIsFilterShow(!isFilterShow);
	}
	
	const clearFilters = () => {
		if (blockchainRef.current) {
			blockchainRef.current.instance.clearFilter();
		}
	}
	
	const addRow = () => {
		if (blockchainRef.current) {
			blockchainRef.current.instance.addRow();
		}
	}
	
	return (
		<div className={'page-component-wrapper-custom'}>
			<div className={'grid-wrapper'}>
				<DataGrid
					ref={blockchainRef}
					dataSource={currencyDs}
					height={'100%'}
				>
					<Toolbar>
						<Item location={'before'}>
							<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
							</div>
						</Item>
						<Item location={'before'}>
							<Button icon={'filter'} onClick={toggleFilter}/>
						</Item>
						<Item location={'before'}>
							<Button icon={'clearsquare'} onClick={clearFilters}/>
						</Item>
						<Item location={'after'}>
							<Button
								visible={gridActionRight}
								name={'addRowButton'}
								icon={'add'}
								elementAttr={{class: 'round-toolbar-button'}}
								stylingMode={'outlined'}
								text={'Добавить'}
								onClick={addRow}
							/>
						</Item>
					</Toolbar>
					<Paging
						enabled={false}
					/>
					<RemoteOperations
						filtering
					/>
					{gridActionRight ? (
						<Editing
							allowAdding
							allowUpdating
						/>
					) : null}
					<FilterRow visible={isFilterShow}/>
					<Column
						allowEditing={false}
						dataField={'ID'}
						caption={'ID'}
					/>
					<Column
						dataField={'Name'}
						caption={'Название'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'Code'}
						caption={'Код'}
					>
						<RequiredRule/>
					</Column>
					<Column
						dataField={'IsAddressCaseInsensitive'}
						caption={'Регистрозависимость'}
					>
						<Lookup
							dataSource={SPREAD_DICTIONARY}
							displayExpr={'Name'}
							valueExpr={'ID'}
						/>
						<RequiredRule/>
					</Column>
				</DataGrid>
			</div>
		</div>
	);
}

export default Blockchain;