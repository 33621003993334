import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
	Toolbar,
} from 'devextreme-react/data-grid';
import {
	capitalizeFirstLetter,
	getAppliedFilters,
	combineValues,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import Button from 'devextreme-react/button';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {getMerchantsList} from 'services/requestConsts';
import AdminPopup from 'components/popup/admin-popup';
import {getDataSource} from 'services/dataSource';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, updateSelectedMerchantDetails} from 'services/async';
import {withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

import './clients-page.scss';

const {
	TRANSACTION,
	STATE_STORING_KEYS: {CLIENTS: {CLIENTS_PAGE}},
	PRECISION,
	CLIENT_DETAIL_PATH,
} = vars;

const getTitle = (actionType, {ID = null, Name = null}) => {
	return `Детальная информация о клиенте ID ${ID} ${Name}`;
};

class ClientsPage extends React.Component {
	constructor(props) {
		super(props);
		this.userRights = props.rights;
		const accountFilterID = getFromSessionStorage('filter', 'accountFilterID');
		window.sessionStorage.removeItem('filter');
		this.gridRef = React.createRef();
		this.state = {
			merchants: [],
			dictionariesData: null,
			showFilter: !!accountFilterID,
			popupFields: null,
			rowData: {
				ID: null,
				Name: null,
				UserName: null,
			},
			actionType: null,
			accountFilterID: accountFilterID,
			isShowExportDatePopup: false,
		};
		
		this.filterOptions = {
			UserTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserTypeID'),
				options: {
					object: 'UserType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			VmVerificationLevelName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('VmVerificationLevelID'),
				options: {
					object: 'VmVerificationLevel',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			UserStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserStatusID'),
				options: {
					object: 'UserStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			VmVerificationStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('VmVerificationStatusID'),
				options: {
					object: 'VmVerificationStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
		}
	}
	
	componentDidMount() {
		this.getMerchantList().catch((e) => {
			console.warn(e);
		});
	}
	
	getMerchantList = async () => {
		const paramObj = getMerchantsList();
		const merchants = getDataSource(paramObj);
		const dictionariesData = await this.getDictionariesData();
		this.setState({
			merchants,
			dictionariesData,
		});
	};
	
	
	getDictionariesData = async () => {
		const userTypes = await apiRequest({
			operation: 'UserType/List',
		});
		
		const userStatus = await apiRequest({
			operation: 'UserStatus/List',
		});
		
		const vmVerificationStatus = await apiRequest({
			operation: 'VmVerificationStatus/List',
		});
		
		const {Response: userTypesResponse} = userTypes.data;
		const {Response: userStatusResponse} = userStatus.data;
		const {Response: vmVerificationStatusResponse} = vmVerificationStatus.data;
		
		return {
			UserTypeName: userTypesResponse && userTypesResponse['UserType'],
			UserStatusName: userStatusResponse && userStatusResponse['UserStatus'],
			VmVerificationStatusName: vmVerificationStatusResponse && vmVerificationStatusResponse['VmVerificationStatus'],
		};
	};
	
	updateSelectedMerchant = async (id) => {
		let merchant = null;
		
		try {
			merchant = await updateSelectedMerchantDetails(id);
			if (merchant) {
				merchant.UserRights = this.userRights;
			}
			this.setState({
				rowData: merchant,
			});
		} catch (e) {
			notifyApp(e);
		}
		
		return merchant;
	};
	
	gridButtonHandler = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData,
			actionType,
		});
	};
	
	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};
	
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	};
	
	saveClientSettings = (settings) => {
		saveGridSettings(settings, CLIENTS_PAGE);
	};
	
	loadClientSettings = () => {
		const {accountFilterID} = this.state;
		return loadGridSettings(accountFilterID ? {ID: accountFilterID} : null, CLIENTS_PAGE);
	};
	
	parseDictionary = (dictionariesData, filterValue, label) => {
		let field = '';
		
		filterValue[2].forEach((valueId) => {
			if (!dictionariesData[filterValue[0]]) {
				return field;
			}
			
			const entityDictionary = dictionariesData[filterValue[0]].find((item) => {
				return item.ID === valueId;
			});
			
			const {Name: val} = entityDictionary;
			field += field ? `, ${val}` : val;
		});
		
		return `${label} Equals(${field})`;
	};
	
	editFilterPanelText = ({component, filterValue, text}) => {
		/*
		* filter ['', '', ''] -- one filter not dictionary
		* filter ['', '', []] -- one filter dictionary
		* filter [[], '', []] -- several column filters
		*/
		
		const {dictionariesData} = this.state;
		let resultString = '';
		const regExp = /\[(.*?)]/gm;
		const label = text.match(regExp);
		
		if (dictionariesData && Array.isArray(filterValue[2])) {
			
			if (filterValue[2].length === 0) {
				component.clearFilter();
			} else {
				if (Array.isArray(filterValue[0])) {
					// several column filters
					console.log('several');
					
					// every odd element is filter
					filterValue.forEach((filter, index) => {
						if (index % 2 === 0) {
							if (Array.isArray(filter[2]) && filter[2].length === 0) {
								let updatedFilters = filterValue.filter((item) => {
									return item[0] !== filter[0];
								});
								
								if (updatedFilters.length % 2 === 0) {
									if (Array.isArray(updatedFilters[0])) {
										updatedFilters = updatedFilters.slice(0, updatedFilters.length - 1);
									} else {
										updatedFilters = updatedFilters.slice(1);
									}
								}
								
								if (updatedFilters.length === 1) {
									updatedFilters = updatedFilters[0];
								}
								
								component.option('filterValue', updatedFilters);
							}
							
							console.log(filter[0]);
							console.log(text);
							
							// ['das', 'sdsa' ,'jggh']
							if (Array.isArray(filter[2])) {
								const filterStr = this.parseDictionary(dictionariesData, filter, label[index / 2]);
								resultString += resultString ? ` And ${filterStr}` : filterStr;
							} else {
								const labelText = label[index / 2];
								const operation = capitalizeFirstLetter(filter[1]);
								const resStr = `${labelText} ${operation} '${filter[2]}'`;
								resultString += resultString ? ` And ${resStr}` : resStr;
							}
						}
					});
					
					
				} else {
					// one filter dictionary
					resultString = this.parseDictionary(dictionariesData, filterValue, label[0]);
				}
			}
		}
		
		return resultString ? resultString : text;
	};
	
	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}
	
	toggleFilter = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	}
	
	clearFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.instance.clearFilter()
		}
	}
	
	goToCreate = () => {
		const {history} = this.props;
		history.push({
			pathname: '/clients/client/create',
			state: {},
		});
	}
	
	exportGrid = () => {
		const {isShowExportDatePopup} = this.state;
		this.setState({isShowExportDatePopup: !isShowExportDatePopup});
	}
	
	render() {
		const {
			merchants,
			showFilter,
			popupFields,
			rowData,
			actionType,
			isShowExportDatePopup,
		} = this.state;
		const {history} = this.props;
		const PopupFields = popupFields;
		
		return (
			<div className={'page-component-wrapper-custom'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					title={getTitle(actionType, rowData)}
					maxWidth={700}
				>
					{popupFields ? (
						<PopupFields
							rowData={rowData}
							actionType={actionType}
							gridButtonHandler={this.gridButtonHandler}
							closePopup={this.closePopup}
							updateMerchantsList={this.getMerchantList}
							updateSelectedMerchant={this.updateSelectedMerchant}
							rights={this.userRights}
						/>
					) : null}
				</AdminPopup>
				<div className={'grid-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						alignment={'center'}
						filterSyncEnabled={true}
						ref={this.gridRef}
						dataSource={merchants}
						hoverStateEnabled={true}
						showBorders={false}
						focusedRowEnabled={false}
						columnHidingEnabled={true}
						onEditorPreparing={this.onEditorPreparing}
						height={'100%'}
						onContentReady={({component, element}) => {
							getAppliedFilters(component, element);
						}}
						onRowDblClick={(row) => {
							if (this.userRights.includes('39')) { // #89873
								history.push({
									pathname: CLIENT_DETAIL_PATH,
									state: {
										clientId: row.data.ID,
										updateMerchantsList: this.getMerchantList,
										updateSelectedMerchant: this.updateSelectedMerchant,
									},
								});
							}
						}}
					>
						<Toolbar>
							<Item location={'before'}>
								<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
								</div>
							</Item>
							<Item location={'before'}>
								<Button icon={'filter'} onClick={this.toggleFilter}/>
							</Item>
							<Item location={'before'}>
								<Button icon={'clearsquare'} onClick={this.clearFilters}/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'add'}
									elementAttr={{class: 'round-toolbar-button'}}
									stylingMode={'outlined'}
									disabled={!(this.userRights.includes('33'))}
									text={'Создать клиента'}
									onClick={this.goToCreate}
								/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'xlsxfile'}
									onClick={this.exportGrid}
								/>
							</Item>
						</Toolbar>
						<RemoteOperations
							paging
							filtering
						/>
						<StateStoring
							enabled={true}
							type="custom"
							customLoad={this.loadClientSettings}
							customSave={this.saveClientSettings}
							savingTimeout={100}
						/>
						<Paging enabled defaultPageSize={50}/>
						<FilterRow visible={showFilter}/>
						<FilterPanel
							customizeText={this.editFilterPanelText}
							visible={true}
						/>
						<Scrolling
							mode={'infinite'}
							showScrollbar="onHover"
						/>
						<Column
							visible={false}
							dataField={'UserPhone'}
							caption={'Телефон'}
						/>
						<Column
							visible={false}
							dataField={'UserEmail'}
							caption={'Email'}
						/>
						<Column
							dataField={'ID'}
							caption={'ID клиента'}
							width={100}
						/>
						<Column
							dataField={'InsDate'}
							dataType={'datetime'}
							caption={'Дата регистрации'}
							format={'dd.MM.yy, HH:mm:ss'}
							width={130}
						/>
						<Column
							dataField={'Name'}
							caption={'Клиент'}
							width={200}
						/>
						<Column
							dataField={'UserTypeName'}
							caption={'Тип клиента'}
							filterOperations={this.filterOptions['UserTypeName'].filterOperations}
							calculateFilterExpression={this.filterOptions['UserTypeName'].calculateFilterExpression}
							width={130}
						/>
						<Column
							dataField={'Country'}
							caption={'Страна'}
							width={60}
						/>
						<Column
							dataField={'Host'}
							caption={'Источник'}
							width={180}
						/>
						<Column
							dataField={'UserStatusName'}
							caption={'Регистрация'}
							filterOperations={this.filterOptions['UserStatusName'].filterOperations}
							calculateFilterExpression={this.filterOptions['UserStatusName'].calculateFilterExpression}
							cellRender={this.feeColumn}
							width={150}
						/>
						<Column
							dataField={'MerchantCategoryName'}
							caption={'Категория'}
						/>
						<Column
							dataField={'VmVerificationStatusName'}
							caption={'Верификация'}
							filterOperations={this.filterOptions['VmVerificationStatusName'].filterOperations}
							calculateFilterExpression={this.filterOptions['VmVerificationStatusName'].calculateFilterExpression}
							width={150}
						/>
						<Column
							dataField={'VmVerificationLevelName'}
							caption={'Уровень'}
							filterOperations={this.filterOptions['VmVerificationLevelName'].filterOperations}
							calculateFilterExpression={this.filterOptions['VmVerificationLevelName'].calculateFilterExpression}
							width={150}
						/>
						<Column
							caption={'Блокировки'}
							width={100}
							calculateCellValue={combineValues}
						/>
						<Column
							dataField={'TotalBalance'}
							caption={'Баланс'}
						>
							<Format
								type="fixedPoint"
								precision={PRECISION}
							/>
						</Column>
						<Column
							dataField={'LastActivityDate'}
							caption={'Активность'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
							width={130}
						/>
						<Column
							caption={'Быстрый просмотр'}
							type={'buttons'}
							buttons={[
								{
									hint: 'Open',
									visible: this.userRights.includes('39'), // #89873
									text: 'Открыть',
									onClick: ({row}) => {
										history.push({
											pathname: CLIENT_DETAIL_PATH,
											state: {
												clientId: row.data.ID,
												updateMerchantsList: this.getMerchantList,
												updateSelectedMerchant: this.updateSelectedMerchant,
											},
										});
									},
								},
							]}
						/>
					</DataGrid>
				</div>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'ClientsExport'}
					getGridParams={getMerchantsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}

export default withRouter(ClientsPage);





