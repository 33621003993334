import React, {useEffect, useState} from 'react';
import {apiRequest} from 'services/async';
import {Button, NumberBox, TextArea, TextBox} from 'devextreme-react';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import Loader from 'components/loader/loader';
import './accept-decline-replay.scss';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {tryParseDoubleValue} from 'utils/functions';

const {APP_CODES, NOTIFY, TRANSACTION, SWIFT_PAYMENT_SYSTEM_TYPE_ID} = vars;

const I = {
	SYSTEM_COMMENT: 'Системный комментарий',
	ACCEPT_BUTTON: 'Подтвердить',
	DECLINE_BUTTON: 'Отменить платеж',
	REPLAY_BUTTON: 'Поставить платеж в очередь на проверку статуса',
	CANCEL_BUTTON: 'Отмена',
};

const operations = {
	accept: {
		operation: 'Payment/Confirm',
		success: 'Платеж подтвержден',
	},
	confirm: {
		operation: 'Payment/ConfirmWithPayGateCancel',
		success: 'Платеж отмечен выполненным',
	},
	decline: {
		operation: 'Payment/Cancel',
		success: 'Платеж отменен',
	},
	replay: {
		operation: 'Payment/RecheckStatus',
	},
};


const AcceptDeclineReplay = ({
															 rowData: {
																 Description,
																 FeeAmount,
																 ID,
																 PaymentSystemTypeID,
																 Amount,
																 AccountCurrencyCode,
																 ForeignAmount,
																 ForeignFeeAmount,
																 ForeignCurrencyCode,
															 },
															 popupActionType,
															 closePopup,
															 updatePaymentsList,
														 }) => {
	
	const [state, setState] = useState({
		remark: '',
		isShowLoader: false,
		feeAmount: FeeAmount ? parseFloat(FeeAmount) : 0,
		description: Description ? Description : '',
		payGatePaymentID: '',
	});

	const handleValueChange = ({event: {target: {value}}}) => {
		const corrected = tryParseDoubleValue(value, 2);
		setState({
			...state,
			feeAmount: corrected === '' ? 0 : parseFloat(corrected),
		});
	}

	const showLoaderMakeRequest = () => {
		const result = validationEngine.validateGroup('accept-group');

		if (result.isValid) {
			setState({
				...state,
				isShowLoader: true,
			});
		}
	}

	useEffect(() => {
		if (state.isShowLoader) {
			requestButton().catch((e) => {
				notifyApp(e);
			});
		}
	}, [state]);

	const hideLoader = () => {
		setState({
			...state,
			isShowLoader: false,
		});
	};

	const requestButton = async () => {
		const {remark, feeAmount, description, payGatePaymentID} = state;

		try {
			let params = {
				PaymentID: ID,
				Remark: remark,
			};

			if (PaymentSystemTypeID === SWIFT_PAYMENT_SYSTEM_TYPE_ID) {
				params = Object.assign(params, {
					FeeAmount: feeAmount,
					Description: description.trim(),
					PayGatePaymentID: payGatePaymentID.trim(),
				});
			}

			const request = await apiRequest({
				operation: operations[popupActionType].operation,
				data: {
					Params: params,
				},
			});

			if (request.data.ResponseCode === APP_CODES.SUCCESS) {
				closePopup();
				updatePaymentsList();

				if (popupActionType !== TRANSACTION.REPLAY) {
					notifyApp(operations[popupActionType].success, NOTIFY.SUCCESS);
				}
			} else {
				notifyApp(request.data.ResponseText);
			}
		} catch (error) {
			notifyApp(error);
		} finally {
			hideLoader();
		}
	}

	const changeRemarkValue = ({value}) => {
		setState({
			...state,
			remark: value,
		});
	};

	const {isShowLoader, feeAmount, description, payGatePaymentID} = state;

	return (
		<div className={'action-wrapper accept-decline-replay'}>
			<Loader isShowLoader={isShowLoader}/>
			<div className={'payment-details'}>
				<p>{`Сумма: ${Amount} ${AccountCurrencyCode}`}</p>
				<p>{`Комиссия: ${FeeAmount} ${AccountCurrencyCode}`}</p>
				<p>{`Сумма в валюте платежа: ${ForeignAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`Комиссия в валюте платежа: ${ForeignFeeAmount} ${ForeignCurrencyCode}`}</p>
			</div>
			{popupActionType === 'accept' && PaymentSystemTypeID === SWIFT_PAYMENT_SYSTEM_TYPE_ID ? (
					<div>
						<div className={'field-wrapper'}>
							<div className='dx-field-label'>Комиссия</div>
							<NumberBox
								min={0}
								onInput={handleValueChange}
								onPaste={handleValueChange}
								value={feeAmount}
							>
								<Validator validationGroup={'accept-group'}>
									<RequiredRule message={'Поле обязательное'}/>
								</Validator>
							</NumberBox>
						</div>
						<div className={'field-wrapper'}>
							<div className='dx-field-label'>Назначение платежа</div>
							<TextArea
								value={description}
								height={80}
								onValueChanged={({value}) => {
									setState({
										...state,
										description: value,
									});
								}}
							/>
						</div>
						<div className={'field-wrapper'}>
							<div className='dx-field-label'>ID Транзакции</div>
							<TextBox
								value={payGatePaymentID}
								onValueChanged={({value}) => {
									setState({
										...state,
										payGatePaymentID: value,
									});
								}
								}
							/>
						</div>
					</div>
				) :
				null
			}
			<div>
				<div className='dx-field-label'>{I.SYSTEM_COMMENT}</div>
				<TextArea
					height={80}
					onValueChanged={changeRemarkValue}
				>
					<Validator validationGroup={'accept-group'}/>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={showLoaderMakeRequest}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
}

export default AcceptDeclineReplay;