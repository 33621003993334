import React from 'react';
import AdminPopup from 'components/popup/admin-popup';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Item,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
	Toolbar,
} from 'devextreme-react/data-grid';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {getWallettoCardsList} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {createFilter} from 'utils/customFilters';
import {getAppliedFilters, valueToArray} from 'utils/functions';
import CardTransactionDetails from './card-transaction-details';
import {vars} from 'utils/variables';
import Button from 'devextreme-react/button';

const {
	TRANSACTION,
	WALLETTO_ACCOUNT_ID,
	STATE_STORING_KEYS: {CLIENTS: {CARDS_OPERATIONS_PAGE}},
	PRECISION,
} = vars;

class Cards extends React.Component {
	constructor(props) {
		super(props);
		this.gridRef = React.createRef();
		
		this.state = {
			cards: null,
			showFilter: false,
			ID: null,
			actionType: null,
			isShowExportDatePopup: false,
		};
		
		this.filterOptions = {};
	}
	
	componentDidMount() {
		this.getCardsList().catch((e) => {
			console.warn(e);
		});
	}
	
	getCardsList = async () => {
		const cards = getDataSource(getWallettoCardsList(WALLETTO_ACCOUNT_ID));
		this.setState({cards: cards});
	};
	
	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar',
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler,
				},
				location: 'before',
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					},
				},
				location: 'before',
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					},
				},
				location: 'after',
			},
		);
	}
	
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	};
	
	closePopup = () => {
		this.setState({
			ID: null,
		});
	};
	
	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}
	
	toggleFilter = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	}
	
	clearFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.instance.clearFilter()
		}
	}
	
	exportGrid = () => {
		const {isShowExportDatePopup} = this.state;
		this.setState({isShowExportDatePopup: !isShowExportDatePopup});
	}
	
	render() {
		const {cards, showFilter, ID, isShowExportDatePopup} = this.state;
		
		return (
			<div className={'page-component-wrapper-custom'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!ID}
					title={'Детальная информация'}
					maxWidth={700}
				>
					<CardTransactionDetails ID={ID}/>
				</AdminPopup>
				<div className={'grid-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						alignment={'center'}
						ref={this.gridRef}
						dataSource={cards}
						hoverStateEnabled={true}
						showBorders={false}
						focusedRowEnabled={false}
						columnHidingEnabled={true}
						onEditorPreparing={this.onEditorPreparing}
						height={'100%'}
						onContentReady={({component, element}) => {
							getAppliedFilters(component, element);
						}}
						onRowDblClick={(row) => {
							this.setState({ID: row.data.ID});
						}}
					>
						<Toolbar>
							<Item location={'before'}>
								<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
								</div>
							</Item>
							<Item location={'before'}>
								<Button icon={'filter'} onClick={this.toggleFilter}/>
							</Item>
							<Item location={'before'}>
								<Button icon={'clearsquare'} onClick={this.clearFilters}/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'xlsxfile'}
									onClick={this.exportGrid}
								/>
							</Item>
						</Toolbar>
						<RemoteOperations
							paging
							filtering
						/>
						<StateStoring
							enabled={true}
							type="localStorage"
							storageKey={CARDS_OPERATIONS_PAGE}
							savingTimeout={100}
						/>
						<Paging enabled defaultPageSize={50}/>
						<FilterRow visible={showFilter}/>
						<FilterPanel visible={true}/>
						<Scrolling
							mode={'infinite'}
							showScrollbar="onHover"
						/>
						<Column
							caption={'Дата'}
							dataField={'InsDate'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
						/>
						<Column
							caption={'Обновлено'}
							dataField={'LastUpdate'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
						/>
						<Column caption={'ID'} dataField={'ID'}/>
						<Column caption={'Карта'} dataField={'CardNumber'}/>
						<Column caption={'Сумма операции'} dataField={'OriginalAmount'}>
							<Format
								type={'fixedPoint'}
								precision={PRECISION}
							/>
						</Column>
						<Column caption={'Валюта операции'} dataField={'OriginalCurrencyCode'} width={140}/>
						<Column caption={'Курс'} dataField={'ConversionRate'} width={80}/>
						<Column caption={'Сумма'} dataField={'Amount'}>
							<Format
								type={'fixedPoint'}
								precision={PRECISION}
							/>
						</Column>
						<Column caption={'Валюта'} dataField={'AccountCurrencyCode'} width={75}/>
						<Column caption={'MCC'} dataField={'Mcc'}/>
						<Column caption={'RRN'} dataField={'Rrn'}/>
						<Column caption={'Код авторизации'} dataField={'ApprovalCode'}/>
						<Column caption={'Статус'} dataField={'PaymentStatusName'}/>
						<Column
							caption={'Детали'}
							type={'buttons'}
							buttons={[
								{
									hint: 'Открыть',
									text: 'Открыть',
									onClick: ({row}) => {
										this.setState({ID: row.data.ID});
									},
								},
							]}
						/>
					</DataGrid>
				</div>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'CardsOperationsExport'}
					getGridParams={getWallettoCardsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}

export default Cards;
